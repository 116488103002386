import React, { useEffect, useState } from "react";
import { API_URL } from "../../actions/settings";
import { UserAccount } from "../../interfaces/UserAccount";
import {
  IonButton,
  IonInputPasswordToggle,
  IonItem,
  IonLoading,
  IonRow,
  IonToggle,
} from "@ionic/react";
import CheckRole from "../extras/CheckRole";
import { Redirect } from "react-router";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import useForm from "../../utils/useForm";
import IDSearchSelectInput from "../extras/IDSearchSelectInput";
import PasswordChecklist from "react-password-checklist";
import { NotAuthorizedError, UnexpectedError } from "../../pages/Errors/Errors";
import NotificationPopUp from "../extras/NotificationPopUp";
import AE2Input from "../elements/AE2Input";

async function createAccount(data: UserAccount, token: string) {
  return fetch(`${API_URL}/user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.status === 201) {
        NotificationPopUp("success", "User Account Created");
        return res.json();
      } else if (res.status === 401) {
        throw new NotAuthorizedError(
          "You Are Not Authorized to Access That Page"
        );
      } else {
        throw new UnexpectedError();
      }
    })
    .catch((error) => {
      NotificationPopUp(
        "error",
        error.message,
        "Could Not Create User Account",
        1200
      );
      return null;
    });
}

async function getAllCustomers(token: any) {
  return fetch(`${API_URL}/customers`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

const startingForm = {
  username: "",
  password: "",
  email: "",
  first_name: "",
  last_name: "",
  role: 4,
  customer_id: "",
  reset_password: true,
};
const startingErrors = {
  username: "",
  password: "",
  email: "",
  first_name: "",
  last_name: "",
  role: "",
  customer_id: "",
  reset_password: "",
};

const validate = (name: string, val: any) => {
  switch (name) {
    case "username":
      if (val === null || val.length < 1) {
        return { username: "Username Required" };
      } else if (val.length < 3) {
        return { username: "Username too short" };
      } else {
        return { username: "" };
      }
    case "password":
      if (val === null || val.length < 1) {
        return { password: "Password Required" };
      } else {
        return { password: "" };
      }
    case "email":
      if (val === null || val.length < 1) {
        return { email: "Email cannot be empty" };
      }
      else if (!(val.indexOf('@') > -1)){
        return {email:'Not a valid email.'}
      } else {
        return { email: "" };
      }
    case "first_name":
      if (val === null || val.length < 1) {
        return { first_name: "First name is required" };
      } else {
        return { first_name: "" };
      }
    case "last_name":
      if (val === null || val.length < 1) {
        return { last_name: "Last name is required" };
      } else {
        return { last_name: "" };
      }

    default:
      break;
  }
};

const AE2AccountForm = () => {
  const user = useAppSelector(selectUser);
  const [customers, setCustomers] = useState([]);
  const { form, handleFormChange, errors, resetForm, validateForm } = useForm(
    startingForm,
    startingErrors,
    validate
  );
  const [loaded, setLoaded] = useState(false);
  const [verifyPassword, setVerifyPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  const handleSubmit = async () => {
    if (validateForm() && validPassword) {
      const token = localStorage.getItem("token") || "";
      const res = await createAccount(form, `Token ${token.slice(1, -1)}`);
      if (res) {
        resetForm();
        setVerifyPassword("");
      }
    }
  };

  useEffect(() => {
    const getCustomers = async (token: any) => {
      const res = await getAllCustomers(`Token ${token.slice(1, -1)}`);
      setCustomers(res.data);
      setLoaded(true);
    };

    const token = localStorage.getItem("token");
    getCustomers(token);
  }, [loaded]);

  return (
    <>
      {!loaded || user.first_name === "" ? (
        <IonLoading
          data-testid="ion-loading"
          cssClass="loading"
          isOpen={!loaded}
          duration={3000}
          message={"Loading..."}
          spinner="bubbles"
        />
      ) : !CheckRole(user, ["Admin", "Supervisor", "Employee"]) ? (
        <Redirect to="/403" />
      ) : (
        <form style={{ width: "100%" }}>
          <IonRow>
            <IonItem
              style={{
                backgroundColor: "white",
                color: "black",
                borderRadius: "10px",
                marginBottom: "10px",
              }}
            >
              <AE2Input
                label="Username:"
                aria-labelledby="username"
                value={form.username}
                placeholder="username"
                onIonInput={(e: any) =>
                  handleFormChange(e.detail.value!, "username")
                }
                errorText={errors.username}
              >
                {" "}
              </AE2Input>
            </IonItem>
          </IonRow>
          <IonRow className="password-field">
            <IonItem
              style={{
                backgroundColor: "white",
                color: "black",
                borderRadius: "10px",
                marginBottom: "10px",
              }}
            >
              <div>
                <AE2Input
                  type="password"
                  label="Password:"
                  aria-labelledby="password"
                  value={form.password}
                  placeholder="password"
                  onIonInput={(e: any) =>
                    handleFormChange(e.detail.value!, "password")
                  }
                  errorText={errors.password}
                >
                  <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
                </AE2Input>
              </div>
            </IonItem>
          </IonRow>
          <IonRow className="password-field">
            <IonItem
              style={{
                backgroundColor: "white",
                color: "black",
                borderRadius: "10px",
                marginBottom: "10px",
              }}
            >
              <div>
                <AE2Input
                  type="password"
                  label="Verify Password:"
                  aria-labelledby="password"
                  value={verifyPassword}
                  placeholder="password"
                  onIonInput={(e: any) => setVerifyPassword(e.detail.value!)}
                >
                  <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
                </AE2Input>
              </div>
            </IonItem>
            <PasswordChecklist
              rules={[
                "notEmpty",
                "minLength",
                "number",
                "lowercase",
                "capital",
                "match",
              ]}
              minLength={14}
              value={form.password}
              valueAgain={verifyPassword}
              onChange={(isValid) => {
                setValidPassword(isValid);
              }}
            />
          </IonRow>
          <IonRow>
            <IonItem
              style={{
                backgroundColor: "white",
                color: "black",
                borderRadius: "10px",
                marginBottom: "10px",
              }}
            >
              <AE2Input
                type="email"
                label="Email:"
                aria-labelledby="email"
                value={form.email}
                placeholder="email"
                onIonInput={(e: any) =>
                  handleFormChange(e.detail.value!, "email")
                }
                errorText={errors.email}
              >
                {" "}
              </AE2Input>
            </IonItem>
          </IonRow>
          <IonRow>
            <IonItem
              style={{
                backgroundColor: "white",
                color: "black",
                borderRadius: "10px",
                marginBottom: "10px",
              }}
            >
              <AE2Input
                label="First Name:"
                aria-labelledby="First Name"
                value={form.first_name}
                placeholder="First Name"
                onIonInput={(e: any) =>
                  handleFormChange(e.detail.value!, "first_name")
                }
                errorText={errors.first_name}
              >
                {" "}
              </AE2Input>
            </IonItem>
          </IonRow>
          <IonRow>
            <IonItem
              style={{
                backgroundColor: "white",
                color: "black",
                borderRadius: "10px",
                marginBottom: "10px",
              }}
            >
              <AE2Input
                label="Last Name"
                aria-labelledby="Last Name"
                value={form.last_name}
                placeholder="Last Name"
                onIonInput={(e: any) =>
                  handleFormChange(e.detail.value!, "last_name")
                }
                errorText={errors.last_name}
              >
                {" "}
              </AE2Input>
            </IonItem>
          </IonRow>
          {/* TODO: Role */}
          <IonRow>
            <IonItem
              style={{
                backgroundColor: "white",
                color: "black",
                borderRadius: "10px",
                marginBottom: "10px",
              }}
            >
              <IDSearchSelectInput
                dict={customers}
                dict_key="uuid"
                value="cust_name"
                label="Customer"
                errors={errors.customer_id}
                form={form}
                handleFormChange={handleFormChange}
                formName="customer_id"
                placeholder="Customer"
              />
            </IonItem>
          </IonRow>
          <IonRow>
            <IonToggle
              checked={form.reset_password}
              enableOnOffLabels={true}
              onIonChange={(e: any) =>{
                handleFormChange(e.detail.checked, "reset_password")
              }
              }
            >
              Require Password Reset
            </IonToggle>
          </IonRow>
          <IonRow class="ion-justify-content-center">
            <IonButton size="large" shape="round" onClick={handleSubmit}>
              Submit
            </IonButton>
          </IonRow>
        </form>
      )}
    </>
  );
};

export default AE2AccountForm;
