import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
} from "@ionic/react";
import React from "react";

interface Props {
  data: [Result];
}

// Result = [Name,Status,Detail]
type Result = [string, string, any];

const MonthlyUtilityResults: React.FC<Props> = ({ data }) => {
  return (
    <div>
      {data.map((row: Result, i: Number) => {
        return (
          <IonAccordionGroup key={`Utility-${i}`}>
            {row.map((item: any, idx: Number) => {
              return (
                <IonAccordion key={`${item[1]}-${idx}`}>
                  <IonItem slot="header">
                    <IonLabel
                      className={`${item[1].toLowerCase()} estar-result-title`}
                    >
                      <span>{item[1]}</span>: {item[0]}
                    </IonLabel>
                  </IonItem>
                  <div slot="content" style={{ color: "black" }}>
                    <pre>{JSON.stringify(item[2], null, 2)}</pre>
                  </div>
                </IonAccordion>
              );
            })}
          </IonAccordionGroup>
        );
      })}
    </div>
  );
};

export default MonthlyUtilityResults;
