import { IonList, IonItem, IonLabel, IonIcon } from "@ionic/react";
import "../AE2/Ae2Navs.css";

import {
  settingsOutline,
  settingsSharp,
  shieldOutline,
  shieldSharp,
  personCircleOutline,
  personCircleSharp,
  cogOutline,
  cogSharp,
  personAdd,
  personAddSharp,
} from "ionicons/icons";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import { isStaff } from "../extras/Functions";
import Logout from "../auth/Logout";
import AboutButton from "./AccountNavButtons/AboutButton";
import NewBuildingsButton from "./AccountNavButtons/NewBuildingsButton";

const buttons = [
  {
    title: "General",
    url: "/account?tab=general",
    iosIcon: settingsOutline,
    mdIcon: settingsSharp,
    hidden: false,
  },
  {
    title: "Security",
    url: "/account?tab=security",
    iosIcon: shieldOutline,
    mdIcon: shieldSharp,
    hidden: false,
  },
  {
    title: "Admin",
    url: "/account?tab=admin",
    iosIcon: personCircleOutline,
    mdIcon: personCircleSharp,
    hidden: true,
  },
  // {
  //   title:'Analytics',
  //   url:'/account?tab=analytics',
  //   iosIcon: analyticsOutline,
  //   mdIcon: analyticsSharp,
  //   hidden: true
  // },
  {
    title: "AE2 Software",
    url: "/account?tab=software",
    iosIcon: cogOutline,
    mdIcon: cogSharp,
    hidden: true,
  },
  // {
  //   title:'Energystar Data',
  //   url:'/account?tab=estar-data',
  //   iosIcon: gitPullRequestOutline,
  //   mdIcon: gitPullRequestSharp,
  //   hidden: true
  // },
  // {
  //   title:'Cost Avoidance Data',
  //   url:'/cost_avoidance_dash',
  //   iosIcon: analyticsOutline,
  //   mdIcon: analyticsOutline,
  //   hidden: true
  // },
  {
    title: "Create AE2 Account",
    url: "/account?tab=new_user",
    iosIcon: personAdd,
    mdIcon: personAddSharp,
    hidden: true,
  },
];

const AccountNavs: React.FC = () => {
  const user = useAppSelector(selectUser);

  return (
    <>
      <IonList id="labels-list" className="labels-list">
        <NewBuildingsButton/>
        {buttons.map((button, index) => (
          <div
            key={"list" + index}
            className={button.title + "-block"}
            hidden={button.hidden ? !isStaff(user) : false}
          >
            <IonItem button key={index} href={button.url}>
              <IonIcon slot="start" ios={button.iosIcon} md={button.mdIcon} />
              <IonLabel>{button.title}</IonLabel>
            </IonItem>
          </div>
        ))}

        <AboutButton />
      </IonList>
      <Logout />
    </>
  );
};

export default AccountNavs;
