import { IonCol, IonIcon, IonItem, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { star, starOutline } from "ionicons/icons"
import { useEffect, useState } from "react"
import AE2Input from "../../elements/AE2Input"


interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
totalGrossFloorArea : "",
totalGrossFloorArea_units : "",
totalGrossFloorArea_date : "",
surgeryCenterFloorArea : "",
surgeryCenterFloorArea_units : "",
surgeryCenterFloorArea_date : "",
numberOfSurgicalOperatingBeds : "",
numberOfSurgicalOperatingBeds_date : "",
weeklyOperatingHours : "",
weeklyOperatingHours_date : "",
numberOfWorkers : "",
numberOfWorkers_date : "",
numberOfMriMachines:"",
numberOfMriMachines_date:"",
percentCooled : "",
percentCooled_date : "",
percentHeated : "",
percentHeated_date : "",
numberOfComputers : "",
numberOfComputers_date : "",
}


const startErrors = {
    totalGrossFloorArea : "Field Required",
    totalGrossFloorArea_units : "Select Units",
    totalGrossFloorArea_date : "Date is required",
    surgeryCenterFloorArea : "",
    surgeryCenterFloorArea_units : "",
    surgeryCenterFloorArea_date : "",
    numberOfSurgicalOperatingBeds : "",
    numberOfSurgicalOperatingBeds_date : "",
    weeklyOperatingHours : "",
    weeklyOperatingHours_date : "",
    numberOfWorkers : "",
    numberOfWorkers_date : "",
    numberOfMriMachines:"",
    numberOfMriMachines_date:"",
    percentCooled : "",
    percentCooled_date : "",
    percentHeated : "",
    percentHeated_date : "",
    numberOfComputers : "",
    numberOfComputers_date : "",
}

const MedicalOffice: React.FC<Props> = ({setParentForm,formErrors}) => {
    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])
    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case('surgeryCenterFloorArea'):
                if(value !== ''){
                    form.surgeryCenterFloorArea_date === '' ? handleError('surgeryCenterFloorArea_date','Date Cannot be Empty') : handleError('surgeryCenterFloorArea_date',"")
                    form.surgeryCenterFloorArea_units === '' ? handleError('surgeryCenterFloorArea_units','Units Cannot be Empty') : handleError('surgeryCenterFloorArea_units',"")
                    value < 0 ? handleError('surgeryCenterFloorArea','Must be a Positive Number') : handleError('surgeryCenterFloorArea',"")
                }
                else{
                    handleError('surgeryCenterFloorArea','')
                    if(form.surgeryCenterFloorArea_date === '' && form.surgeryCenterFloorArea_units === ''){
                        handleError('surgeryCenterFloorArea_date','')
                        handleError('surgeryCenterFloorArea_units','')}
                    else if(form.surgeryCenterFloorArea_date === ''){
                        handleError('surgeryCenterFloorArea'," Field Cannot be empty")
                        handleError('surgeryCenterFloorArea_date'," Date Cannot be empty")
                    }
                    else if(form.surgeryCenterFloorArea_units === ''){
                        handleError('surgeryCenterFloorArea'," Field Cannot be empty")
                        handleError('surgeryCenterFloorArea_units'," Unit Cannot be empty")
                    }

                }
                break;
            case('surgeryCenterFloorArea_date'):
                if(value !== ''){
                    form.surgeryCenterFloorArea === '' ? handleError('surgeryCenterFloorArea','Field Cannot be Empty') : handleError('surgeryCenterFloorArea',"")
                    form.surgeryCenterFloorArea_units === '' ? handleError('surgeryCenterFloorArea_units','Units Cannot be Empty') : handleError('surgeryCenterFloorArea_units',"")
                    new Date(value).getTime() > getCurrentDate() ? handleError('surgeryCenterFloorArea_date','Invalid Date') : handleError('surgeryCenterFloorArea_date','')
                }
                else{
                    handleError('surgeryCenterFloorArea_date','')
                    if(form.surgeryCenterFloorArea === '' && form.surgeryCenterFloorArea_units === ''){
                        handleError('surgeryCenterFloorArea','')
                        handleError('surgeryCenterFloorArea_units','')}
                    else if(form.surgeryCenterFloorArea === ''){
                        handleError('surgeryCenterFloorArea'," Field Cannot be empty")
                        handleError('surgeryCenterFloorArea_units',"")
                    }
                    else if(form.surgeryCenterFloorArea_units === ''){
                        handleError('surgeryCenterFloorArea_date',"")
                        handleError('surgeryCenterFloorArea'," Unit Cannot be empty")
                    }

                }
                break;
            case('surgeryCenterFloorArea_units'):
                if(value !== ''){
                    form.surgeryCenterFloorArea === '' ? handleError('surgeryCenterFloorArea','Field Cannot be Empty') : handleError('surgeryCenterFloorArea',"")
                    form.surgeryCenterFloorArea_date === '' ? handleError('surgeryCenterFloorArea_date','Date Cannot be Empty') : handleError('surgeryCenterFloorArea_date',"")
                }
                else{
                    handleError('surgeryCenterFloorArea_units','')
                    if(form.surgeryCenterFloorArea === '' && form.surgeryCenterFloorArea_date === ''){
                        handleError('surgeryCenterFloorArea','')
                        handleError('surgeryCenterFloorArea_date','')}
                    else if(form.surgeryCenterFloorArea === ''){
                        handleError('surgeryCenterFloorArea'," Field Cannot be empty")
                        handleError('surgeryCenterFloorArea_date',"")
                    }
                    else if(form.surgeryCenterFloorArea_date === ''){
                        handleError('surgeryCenterFloorArea_date'," Field Cannot be empty")
                        handleError('surgeryCenterFloorArea',"")
                    }

                }
                break;
            case('numberOfSurgicalOperatingBeds'):
                if(value !==''){
                    form.numberOfSurgicalOperatingBeds_date === '' ? handleError('numberOfSurgicalOperatingBeds_date','Date cannot be empty') : handleError('numberOfSurgicalOperatingBeds_date','')
                    value < 0 ? handleError('numberOfSurgicalOperatingBeds','Must be a Positive number') : handleError('numberOfSurgicalOperatingBeds','')
                }
                else{
                    handleError('numberOfSurgicalOperatingBeds','')
                    handleError('numberOfSurgicalOperatingBeds_date','')
                }
                break;
            case('numberOfSurgicalOperatingBeds_date'):
                if(value !==''){
                    form.numberOfSurgicalOperatingBeds === '' ? handleError('numberOfSurgicalOperatingBeds','Field cannot be empty') : handleError('numberOfSurgicalOperatingBeds','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfSurgicalOperatingBeds_date','Invalid Date') : handleError('numberOfSurgicalOperatingBeds_date','')
                }
                else{
                    handleError('numberOfSurgicalOperatingBeds','')
                    handleError('numberOfSurgicalOperatingBeds_date','')
                }
                break;
            case('weeklyOperatingHours'):
                if(value !==''){
                    form.weeklyOperatingHours_date === '' ? handleError('weeklyOperatingHours_date','Date cannot be empty') : handleError('weeklyOperatingHours_date','')
                    value < 0 ? handleError('weeklyOperatingHours','Must be a Positive number') : handleError('weeklyOperatingHours','')
                }
                else{
                    handleError('weeklyOperatingHours','')
                    handleError('weeklyOperatingHours_date','')
                }
                break;
            case('weeklyOperatingHours_date'):
                if(value !==''){
                    form.weeklyOperatingHours === '' ? handleError('weeklyOperatingHours','Field cannot be empty') : handleError('weeklyOperatingHours','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('weeklyOperatingHours_date','Invalid Date') : handleError('weeklyOperatingHours_date','')
                }
                else{
                    handleError('weeklyOperatingHours','')
                    handleError('weeklyOperatingHours_date','')
                }
                break;
            case('numberOfWorkers'):
                if(value !==''){
                    form.numberOfWorkers_date === '' ? handleError('numberOfWorkers_date','Date cannot be empty') : handleError('numberOfWorkers_date','')
                    value < 0 ? handleError('numberOfWorkers','Must be a Positive number') : handleError('numberOfWorkers','')
                }
                else{
                    handleError('numberOfWorkers','')
                    handleError('numberOfWorkers_date','')
                }
                break;
            case('numberOfWorkers_date'):
                if(value !==''){
                    form.numberOfWorkers === '' ? handleError('numberOfWorkers','Field cannot be empty') : handleError('numberOfWorkers','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfWorkers_date','Invalid Date') : handleError('numberOfWorkers_date','')
                }
                else{
                    handleError('numberOfWorkers','')
                    handleError('numberOfWorkers_date','')
                }
                break;
            case('numberOfMriMachines'):
                if(value !==''){
                    form.numberOfMriMachines_date === '' ? handleError('numberOfMriMachines_date','Date cannot be empty') : handleError('numberOfMriMachines_date','')
                    value < 0 ? handleError('numberOfMriMachines','Must be a Positive number') : handleError('numberOfMriMachines','')
                }
                else{
                    handleError('numberOfMriMachines','')
                    handleError('numberOfMriMachines_date','')
                }
                break;
            case('numberOfMriMachines_date'):
                if(value !==''){
                    form.numberOfMriMachines === '' ? handleError('numberOfMriMachines','Field cannot be empty') : handleError('numberOfMriMachines','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfMriMachines_date','Invalid Date') : handleError('numberOfMriMachines_date','')
                }
                else{
                    handleError('numberOfMriMachines','')
                    handleError('numberOfMriMachines_date','')
                }
                break;
            case('percentCooled'):
                if(value !==''){
                    form.percentCooled_date === '' ? handleError('percentCooled_date','Date cannot be empty') : handleError('percentCooled_date','')

                }
                else{
                    handleError('percentCooled','')
                    handleError('percentCooled_date','')
                }
                break;
            case('percentCooled_date'):
                if(value !==''){
                    form.percentCooled === '' ? handleError('percentCooled','Field cannot be empty') : handleError('percentCooled','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('percentCooled_date','Invalid Date') : handleError('percentCooled_date','')
                }
                else{
                    handleError('percentCooled','')
                    handleError('percentCooled_date','')
                }
                break;
            case('percentHeated'):
                if(value !==''){
                    form.percentHeated_date === '' ? handleError('percentHeated_date','Date cannot be empty') : handleError('percentHeated_date','')

                }
                else{
                    handleError('percentHeated','')
                    handleError('percentHeated_date','')
                }
                break;
            case('percentHeated_date'):
                if(value !==''){
                    form.percentHeated === '' ? handleError('percentHeated','Field cannot be empty') : handleError('percentHeated','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('percentHeated_date','Invalid Date') : handleError('percentHeated_date','')
                }
                else{
                    handleError('percentHeated','')
                    handleError('percentHeated_date','')
                }
                break;
            case('numberOfComputers'):
                if(value !==''){
                    form.numberOfComputers_date === '' ? handleError('numberOfComputers_date','Date cannot be empty') : handleError('numberOfComputers_date','')
                    value < 0 ? handleError('numberOfComputers','Must be a Positive number') : handleError('numberOfComputers','')
                }
                else{
                    handleError('numberOfComputers','')
                    handleError('numberOfComputers_date','')
                }
                break;
            case('numberOfComputers_date'):
                if(value !==''){
                    form.numberOfComputers === '' ? handleError('numberOfComputers','Field cannot be empty') : handleError('numberOfComputers','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfComputers_date','Invalid Date') : handleError('numberOfComputers_date','')
                }
                else{
                    handleError('numberOfComputers','')
                    handleError('numberOfComputers_date','')
                }
                break;
            default:
                break;
        }
    }

    return(
        <div>
            <h1>Medical Office</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.totalGrossFloorArea}  value={form.totalGrossFloorArea} placeholder='GFA' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Total Gross Floor Area:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label='Current as of:' type="date" errorText={errors.totalGrossFloorArea_date} value={form.totalGrossFloorArea_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label='Surgery Center Floor Area' type="number" errorText={errors.surgeryCenterFloorArea}  value={form.surgeryCenterFloorArea} placeholder='Surgical Center Area' onIonInput={e => (handleFormChange(e.detail.value!,'surgeryCenterFloorArea'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.surgeryCenterFloorArea_units === '' ? "valid":'invalid'} value={form.surgeryCenterFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'surgeryCenterFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.surgeryCenterFloorArea_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label='Current as of:' type="date" errorText={errors.surgeryCenterFloorArea_date} value={form.surgeryCenterFloorArea_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'surgeryCenterFloorArea_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label='Number of Surgical Operating Beds' labelPlacement="stacked" type="number" errorText={errors.numberOfSurgicalOperatingBeds}  value={form.numberOfSurgicalOperatingBeds} placeholder='# SurgicalOperating Beds' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfSurgicalOperatingBeds'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label='Current as of:' type="date" errorText={errors.numberOfSurgicalOperatingBeds_date}  value={form.numberOfSurgicalOperatingBeds_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfSurgicalOperatingBeds_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.weeklyOperatingHours}  value={form.weeklyOperatingHours} placeholder='Weekly Operating Hours' onIonInput={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Weekly Operating Hours</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label='Current as of:' type="date" errorText={errors.weeklyOperatingHours_date}  value={form.weeklyOperatingHours_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.numberOfWorkers}  value={form.numberOfWorkers} placeholder='# Workers' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfWorkers'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Number of Workers</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label='Current as of:' type="date" errorText={errors.numberOfWorkers_date}  value={form.numberOfWorkers_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfWorkers_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label='Number of MRI Machines' type="number" errorText={errors.numberOfMriMachines}  value={form.numberOfMriMachines} placeholder='# MRI Machines' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfMriMachines'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label='Current as of:' type="date" errorText={errors.numberOfMriMachines_date}  value={form.numberOfMriMachines_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfMriMachines_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect className={errors.percentCooled === '' ? "valid":'invalid'}  value={form.percentCooled} interface='popover' placeholder='Percent Cooled' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Percent Cooled</div>
                        </IonSelect>
                        <div className="error-detail">{errors.percentCooled}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <AE2Input label='Current as of:' type="date" errorText={errors.percentCooled_date}  value={form.percentCooled_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'percentCooled_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect className={errors.percentHeated === '' ? "valid":'invalid'}  value={form.percentHeated} interface='popover' placeholder='Percent Heated' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Percent Heated</div>
                        </IonSelect>
                        <div className="error-detail">{errors.percentHeated}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <AE2Input label='Current as of:' type="date" errorText={errors.percentHeated_date}  value={form.percentHeated_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'percentHeated_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <AE2Input label="Number of Computers" type="number" errorText={errors.numberOfComputers}  value={form.numberOfComputers} placeholder='# Computers' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfComputers'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                        <AE2Input label='Current as of:' type="date" errorText={errors.numberOfComputers_date}  value={form.numberOfComputers_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfComputers_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>

        </div>
    )
}

export default MedicalOffice