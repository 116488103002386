import { IonButton, IonCol, IonItem, IonLabel, IonLoading, IonRow } from "@ionic/react"
import { useEffect, useState } from "react"
import { Redirect, useHistory, useParams } from "react-router"
import { API_URL } from "../../actions/settings"
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import CheckRole from "../extras/CheckRole"
import IDSearchSelectInput from "../extras/IDSearchSelectInput"
import NotificationPopUp from "../extras/NotificationPopUp"
import AE2Input from "../elements/AE2Input"

interface Params {
    id:string
}

async function getProviders(token:string) {
    return fetch(`${API_URL}/utility_provider`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        }

      })
        .then(data => data.json())
    }

async function getServiceTypes(token:string) {
    return fetch(`${API_URL}/utility_service_type`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        }

      })
        .then(data => data.json())
    }

async function addBuildingUtility(data:any, token:string) {
    return fetch(`${API_URL}/buildings/utility_service`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        },
        body: JSON.stringify(data)

      })
        .then(data => data.json())
    }

const AE2BuildingUtilityForm:React.FC = () =>{
    const { id }=useParams<Params>()
    const user = useAppSelector(selectUser)
    const [utilityProviders, setUtilityProviders] = useState([])
    const [utilityServiceType, setUtilityServiceType] = useState([])
    const [loaded, setLoaded] = useState(false)

    const startingForm = {
        name:"",
        building:id,
        utility_service_type:"",
        utility_provider:"",
        account_number:"",
        account_group_number:"",
        third_party_provider:"",
        third_party_account_number:"",
        podid:"",
        meter_number:""
    }

    const startingErrors:any = {
        name:"",
        utility_service_type:"",
        utility_provider:"",
        account_number:"",
        account_group_number:"",
        third_party_provider:"",
        third_party_account_number:"",
        podid:"",
        meter_number:""
    }

    const [form,setForm] = useState(startingForm)
    const [errors,setErrors] = useState(startingErrors)
    const history = useHistory()


    useEffect(() => {
        const token = localStorage.getItem('token')
        handleUtilityProvider(token)
        handleUtilityServiceTypes(token)
        setLoaded(true)
    },[])

    const handleUtilityProvider = async (token:string|null) => {
        const providers = await getProviders(`Token ${token!.slice(1,-1)}`)
        setUtilityProviders(providers.data)
    }

    const handleUtilityServiceTypes = async (token:string|null) => {
        const serviceTypes = await getServiceTypes(`Token ${token!.slice(1,-1)}`)
        setUtilityServiceType(serviceTypes.data)
    }

    const handleSubmit = async () => {
        const token:any = localStorage.getItem('token')
        const valid = validateAll(form) || false
        if(valid){
        const res = await addBuildingUtility(form,`Token ${token.slice(1,-1)}`)
        if (res.status === "success"){
            setForm(startingForm)
            history.push(`/?id=${id}&sec=buildings`)
            NotificationPopUp('success',"Building Utility Service Created")
        }

        else{
            NotificationPopUp('error',res.details||res.ERROR,res.error||"Error")
        }
        }
    }

    const handleFormChange = (value:any,name:string) => {
        setErrors({...errors, ...validate(name,value) })
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const validate = (name:string, val:any) => {
        // const regExp = /[a-zA-Z]/g;
        switch (name) {
            case 'name':
                if(val === null || val.length <=1){
                    return {name:'Name Must Be longer than 2 letters'}
                }
                else{
                    return {name:''}
                }
            case 'utility_service_type':
                if(val === null || val.length === ''){
                    return {utility_service_type:'Cannot Be None'}
                }
                if(!utilityServiceType.some((i:any)=> i.id === val)){
                    return {utility_service_type:'Select A Type From List'}
                }
                else{
                    return {utility_service_type:''}
                }
            case 'utility_provider':
                if(val === null || val.length === ''){
                    return {utility_provider:'Cannot Be None'}
                }
                if(!utilityProviders.some((i:any)=> i.id === val)){
                    return {utility_provider:'Select A Type From List'}
                }
                else{
                    return {utility_provider:''}
                }
            case 'account_number':
                if(val === null || val.length === 0 ){
                    return {account_number:'Account number cannot be empty'}

                }
                else{
                    return {account_number:''}
                }
            // case 'account_group_number':
            //     if(val === null || val.length === 0 ){
            //         return {account_group_number:'Account group number cannot be empty'}

            //     }
            //     else{
            //         return {account_group_number:''}
            //     }
            case 'third_party_provider':
                if(val !== '' && !utilityProviders.some((i:any)=> i.id === val)){
                    return {third_party_provider:'Select A Type From List'}
                }
                else{
                    return {third_party_provider:''}
                }
            default:
                break;
        }
    }

    const validateAll = (form:any) => {
        let validation = startingErrors
        let valid = true

        for (let item in form){

            validation = {...validation, ...validate(item,form[item])}

        }

        setErrors({...errors,...validation})

        for(let res in validation){

            if (validation[res] !== ''){
                valid = false;
            }
        }

        return valid
        }

    if (!loaded || user.first_name===""){
        return(
            <IonLoading
        cssClass='my-custom-class'
        isOpen={!(user.first_name==="")}
        message={'Loading...'}
        spinner='bubbles'
      />
        )
    }

    if(!CheckRole(user,['Admin','Supervisor'])){
        return <Redirect to="/403"/>
    }

    return(
        <form>
            <IonRow className="form-title-row"><h2>Utility Service Form</h2><IonButton className="cancel-btn" size="small"  color="danger" href={`/?id=${id}&sec=buildings`}> Cancel </IonButton></IonRow>
            <IonRow>
                <IonCol>
                <IonItem lines="none">
                    <AE2Input label='Name' labelPlacement='floating' errorText={errors.name} aria-label='Name' value={form.name} placeholder="Name" onIonInput={(e:any) => (handleFormChange(e.detail.value!,'name'))}> </AE2Input>
                </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>
                        <IDSearchSelectInput label="Utility Provider" dict={utilityProviders} dict_key="id" value="name" handleFormChange={handleFormChange} errors={errors.utility_provider} form={form} formName={'utility_provider'} placeholder='Utility Provider'/>
                    </IonItem>
                </IonCol>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>

                        <IDSearchSelectInput dict={utilityServiceType} label="Utility Service Type" dict_key="id" value="name" handleFormChange={handleFormChange} errors={errors.utility_service_type} form={form} formName={'utility_service_type'} placeholder='Utility Service Type'/>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>
                        <AE2Input label='Utility Service Account Number' labelPlacement='floating' errorText={errors.account_number}  aria-label='Utility Service Account Number' value={form.account_number} placeholder="Account Number" onIonInput={(e:any) => (handleFormChange(e.detail.value!,'account_number'))}> </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>
                        <AE2Input label='Utility Service Account Group Number' labelPlacement='floating' errorText={errors.account_group_number} aria-label='Utility Service Account Group Number' value={form.account_group_number} placeholder="Account Group Number" onIonInput={(e:any) => (handleFormChange(e.detail.value!,'account_group_number'))}> </AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>
                        <IDSearchSelectInput dict={utilityProviders} label="Third Party Utility Provider" aria-label='Third Party Utility Provider' dict_key="id" value="name" handleFormChange={handleFormChange} errors={errors.third_party_provider} form={form} formName={'third_party_provider'} placeholder='Third Party Utility Provider'/>
                    </IonItem>
                </IonCol>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>
                        <AE2Input label='Third Party Account Number' labelPlacement='floating' errorText={errors.third_party_account_number} aria-label='Third Party Account Number' value={form.third_party_account_number} placeholder="Third Party Account Number" onIonInput={(e:any) => (handleFormChange(e.detail.value!,'third_party_account_number'))}> </AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>
                        <AE2Input label='PODID' labelPlacement='floating'  errorText={errors.podid}  aria-label="PODID" value={form.podid} placeholder="PODID" onIonInput={(e:any) => (handleFormChange(e.detail.value!,'podid'))}> </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeMd="6">
                    <IonItem lines='none'>
                        <AE2Input label='Meter Number' labelPlacement='floating' errorText={errors.meter_number} aria-label="Meter Number" value={form.meter_number} placeholder="Meter Number" onIonInput={(e:any) => (handleFormChange(e.detail.value!,'meter_number'))}> </AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>

            <div className="button-container">
            <IonItem className='form-submit-button' button color='primary' onClick={handleSubmit}>
                <IonLabel>
                  Submit
                </IonLabel>
            </IonItem>
            </div>

        </form>
    )
}

export default AE2BuildingUtilityForm