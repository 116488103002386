import { IonButton, IonCheckbox, IonCol, IonInput, IonItem, IonLoading, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { useEffect, useState } from "react"
import { Redirect } from "react-router"
import { API_URL } from "../../actions/settings"
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import CheckRole from "../extras/CheckRole"

interface Props{
    index:number,
    onChange:any,
    handleDelete:any,
    values:any,
    valid:any,
    setValid:any
}

interface startingForm{
    type:any
    name:any
    unitofmeasure:any
    metered:any
    firstbilldate:any
    inuse:any
    inactivedate:any
}
const startingErrors = {
    type:"",
    name:"",
    unitofmeasure:"",
    metered:"",
    firstbilldate:"",
    inuse:"",
    inactivedate:""
}




async function getMeterTypes(token:any) {

    return fetch(`${API_URL}/energystar/meters/types`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }



const SingleMeterForm:React.FC<Props> = ({index,onChange, handleDelete, values, valid, setValid}) =>{
    const [form, setForm] = useState<startingForm>(values)
    const [errors, setErrors] = useState(startingErrors)
    const [types, setTypes] = useState([])
    const user = useAppSelector(selectUser)

    const handleFormChange = (input:string,value:any) => {
        setErrors({...errors, ...validate(input,value) })
        setForm((prevState) => {
            return {
                ...prevState,
                [input]:value
            }
        } )
        onChange(index,{...form, [input]:value})

    }
    const getCurrentDate = () => {
        return new Date().getTime();
      };

    const validate = (input:string, value:any) => {
        switch (input){
            case "name":
                if (value === '' || value.length < 3){
                    valid[index].name=false
                    setValid([...valid] )
                    return {'name':"Name cannot be less than 3 characters"}
                }
                else {
                    valid[index].name=true
                    setValid([...valid])
                    return {'name':''}
                }
            case "type":
                if (value === ''){
                    valid[index].type=false
                    setValid([...valid] )
                    return {'type':"Please Select A Type"}
                }
                else {
                    valid[index].type=true
                    setValid([...valid] )
                    return {'type':''}
                }
            case "unitofmeasure":
                if (value === ''){
                    valid[index].unitofmeasure=false
                    setValid([...valid] )
                    return {'unitofmeasure':"Please Select A Unit of Measure"}
                }
                else if ([11,12,13].includes(form.type) && value !== 'kWh (thousand Watt-hours)'){
                    valid[index].unitofmeasure=false
                    setValid([...valid] )
                    return {'unitofmeasure':"Not a Valid Unit for Meter Type"}
                }
                else if ([19,21].includes(form.type) && value !== 'therms'){
                    valid[index].unitofmeasure=false
                    setValid([...valid] )
                    return {'unitofmeasure':"Not a Valid Unit for Meter Type"}
                }
                else if ([27,28,29,30,31,32,33].includes(form.type) && !['Gallons (US)','KGal (thousand gallons) (US)','MGal (million gallons) (US)','Million Gallons per Day'].includes(value)){
                    valid[index].unitofmeasure=false
                    setValid([...valid] )
                    return {'unitofmeasure':"Not a Valid Unit for Meter Type"}
                }
                else {
                    valid[index].unitofmeasure=true
                    setValid([...valid] )
                    return {'unitofmeasure':''}
                }
            case "firstbilldate":
                if (value === ''){
                    valid[index].firstbilldate=false
                    setValid([...valid] )
                    return {'firstbilldate':"Please Select A Date"}
                }
                else if(new Date(value).getTime() > getCurrentDate()){
                    valid[index].firstbilldate=false
                    setValid([...valid] )
                    return {'firstbilldate':"Not A Valid Date"}
                }
                else {
                    valid[index].firstbilldate=true
                    setValid([...valid] )
                    return {'firstbilldate':''}
                }
            case "inuse":
                if (value === false && (form.inactivedate === '' || form.inactivedate === null)){
                    valid[index].inactivedate=false
                    setValid([...valid] )
                    return {'inactivedate':"Please Select A Date"}
                }
                else {
                    valid[index].inactivedate=true
                    setValid([...valid] )
                    return {'inactivedate':''}
                }
            case "inactivedate":
                if(form.inuse === false && new Date(value).getTime() > getCurrentDate()){
                    valid[index].inactivedate=false
                    setValid([...valid] )
                    return {'inactivedate':"Not a Valid Date"}
                }
                else if (form.inuse === false && (value !== '' || value !== null)){
                    valid[index].inactivedate=true
                    setValid([...valid] )
                    return {'inactivedate':""}
                }
                break;

            default:
                break;
        }
    }


    const handleTypes = async () =>{
        const token = localStorage.getItem('token') || ""
        const res = await getMeterTypes(`Token ${token.slice(1,-1)}`)
        setTypes(res.types)
    }


    useEffect (()=>{
        handleTypes()
    },[values])

    if (user.first_name===""){
        return(
            <IonLoading
        cssClass='my-custom-class'
        isOpen={user.first_name===""}
        message={'Loading...'}
        spinner='bubbles'
      />
        )
    }

    if(!CheckRole(user,['Admin','Supervisor'])){
        return <Redirect to="/403"/>
    }

    return(
        <form >
            <IonRow>

                <IonCol>
                    <IonItem lines="none">
                        <IonInput label='Name' labelPlacement='stacked' className={errors.name === '' ? "valid":'invalid'} aria-labelledby="Name" type="text" required value={form.name} placeholder="Name" onIonChange={e => (handleFormChange( 'name',e.detail.value!))}/>
                        <div className="error-detail">{errors.name}</div>
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem lines="none">
                        <IonSelect label='Type' labelPlacement='stacked' className={errors.type === '' ? "valid":'invalid'} aria-label='Type' interface="popover" interfaceOptions={{cssClass: 'fit-popover'}} value={form.type} placeholder="Meter Type" onIonChange={e => (handleFormChange( 'type',e.detail.value!))}>
                            {types.map((type)=>{
                                return(
                                    <IonSelectOption key={type['id']} value={type['id']}>{type['name']}</IonSelectOption>
                                )
                            })}
                        </IonSelect>
                        <div className="error-detail">{errors.type}</div>
                    </IonItem>
                </IonCol>
                { !handleDelete ? '' : <IonCol size="1">
                    <IonButton type="button" color="danger" onClick={() => (handleDelete(index))}>Delete</IonButton>
                </IonCol>}

            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem lines="none">
                        <IonInput label='First Bill Date' labelPlacement='stacked' className={errors.firstbilldate === '' ? "valid":'invalid'} aria-labelledby="First Bill Date" type="date" required value={form.firstbilldate} onIonChange={e => (handleFormChange( 'firstbilldate',e.detail.value!))}/>
                        <div className="error-detail">{errors.firstbilldate}</div>
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem lines="none">
                        <IonSelect label='Unit of Measure' labelPlacement='stacked' className={errors.unitofmeasure === '' ? "valid":'invalid'} interface='popover' interfaceOptions={{cssClass: 'fit-popover'}} aria-label="Unit of Measure" value={form.unitofmeasure} placeholder="Units" onIonChange={e => (handleFormChange( 'unitofmeasure',e.detail.value!))}>
                            <IonSelectOption>kWh (thousand Watt-hours)</IonSelectOption>
                            <IonSelectOption>therms</IonSelectOption>
                            <IonSelectOption>Gallons (US)</IonSelectOption>
                            <IonSelectOption>KGal (thousand gallons) (US)</IonSelectOption>
                            <IonSelectOption>MGal (million gallons) (US)</IonSelectOption>
                            <IonSelectOption>Million Gallons per Day</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.unitofmeasure}</div>
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem lines="none">
                        <IonCheckbox className={errors.metered === '' ? "valid":'invalid'} aria-label="Metered" checked={form.metered} mode='ios' slot="start" onIonChange={e => (handleFormChange('metered',e.detail.checked))} >
                        Metered
                        </IonCheckbox>
                        <div className="error-detail">{errors.metered}</div>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem lines="none">
                    <IonCheckbox className={errors.inuse === '' ? "valid":'invalid'} aria-label='In Use' checked={form.inuse} mode='ios' slot="start" onIonChange={e => (handleFormChange('inuse',e.detail.checked))} >
                    In Use
                    </IonCheckbox>
                    <div className="error-detail">{errors.inuse}</div>
                    </IonItem>
                </IonCol>
                { !form.inuse ? <IonCol>
                    <IonInput  label='Inactive Date' labelPlacement='stacked' type="date" className={errors.inactivedate === '' ? "valid":'invalid'} aria-labelledby="Inactive Date" required={!form.inuse? true:false} value={form.inactivedate} onIonChange={e => (handleFormChange( 'inactivedate',e.detail.value!))}/>
                    <div className="error-detail">{errors.inactivedate}</div>
                </IonCol>:""}
            </IonRow>


        </form>
    )
}

export default SingleMeterForm