import React, { useEffect, useState } from "react";
import { API_URL } from "../../actions/settings";
import LoadingSpinner from "../extras/LoadingSpinner";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


interface Props {
  id: string;
}

interface Data {
  date: string;
  score: string | number;
}


async function getEnergyStarScoreData(token: string, id: string) {
  return fetch(`${API_URL}/graphs/monthly_energystar_score/${id}/12`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return false;
    }});
}

const CustomTooltip = ({ active, payload, label }:any)=>{
    return <div style={{background:'white',border:'black solid 2px', padding:'5px'}}>
          {payload.map((entry:any, index:any) => (<div key={index}>
            <div style={{color:'black'}}>Date: {entry.payload.date}</div>
            <div style={{color:'black'}} >
              Score: {entry.value}
            </div>``
            </div>
          ))}
    </div>
  }

const CustomTick = ({x,y,payload}:any)=>{
    return <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor="end" fill="white" transform="rotate(-35)">
      {payload.value}
    </text>
  </g>
}

const EnergyStarScoreLineGraph: React.FC<Props> = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<null | Data[]>(null);

  useEffect(() => {
    const handleData = async () => {
      const token = `Token ${(localStorage.getItem("token") || "").slice(1,-1)}`;
      const res = await getEnergyStarScoreData(token, id);
      if(res) {setData(res.data)} else setData(null)
      setLoading(false);
    };

    handleData();
  }, [id]);


  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : !data ? (
        <p style={{ width: "100%", textAlign: "center", fontSize: "larger" }}>
          No Score Data
        </p>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
        <LineChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 30,
          }}
        >
          <CartesianGrid strokeDasharray="10 10" />
          <XAxis tick={CustomTick}axisLine={false} dataKey="date" />
          <YAxis  tick={{fill:'white'}} axisLine={false}/>
          <Tooltip content={CustomTooltip}/>
          {/* <Legend /> */}
          <Line type="monotone" dataKey="score" strokeWidth={3} stroke="#01e8ff" />
        </LineChart>
       </ResponsiveContainer>
      )}
    </div>
  );
};

export default EnergyStarScoreLineGraph;
