import {
    IonAccordion,
    IonAccordionGroup,
    IonItem,
    IonLabel,
  } from "@ionic/react";
import React from 'react'

interface Props {
    data: any;
}

const BaselineandTargetResults:React.FC<Props> = ({data}) => {
  return <div>
    <IonAccordionGroup>
        {data.map((row:any, i: Number) => {
          return (
            <IonAccordion key={`cost-avoidance-${i}`}>
              <IonItem slot="header">
                <IonLabel>
                  {row.status === "Failed" ? (
                    <span style={{ color: "red" }}> Error </span>
                  ) : (
                    <span style={{ color: "green" }}> {row.status} </span>
                  )}{" "}
                  : {row.property}
                </IonLabel>
              </IonItem>
              <div slot="content" style={{ color: "black" }}>
                {row.status === "Failed" ? (
                  <p style={{ color: "red" }}>{row.data}</p>
                ) : (
                  <div style={{ color: "black" }}>
                    <ul>
                        <li>Baseline End Date: {row.data.month}/{row.data.year}</li>
                        <li>Target: {row.data.metric} -- {row.data.target}</li>
                    </ul>
                  </div>
                )}
              </div>
            </IonAccordion>
          );
        })}
      </IonAccordionGroup>
  </div>
}

export default BaselineandTargetResults