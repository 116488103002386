import { IonCol, IonItem, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { useEffect, useState } from "react"
import AE2Input from "../../elements/AE2Input"

interface Props{
    title:string
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
totalGrossFloorArea : "",
totalGrossFloorArea_units : "",
totalGrossFloorArea_date : "",
weeklyOperatingHours : "",
weeklyOperatingHours_date : "",
numberOfComputers : "",
numberOfComputers_date : "",
numberOfWorkers : "",
numberOfWorkers_date : "",
percentHeated : "",
percentHeated_date : "",
percentCooled : "",
percentCooled_date : ""

}
const startErrors = {
totalGrossFloorArea : "GFA cannot be empty",
totalGrossFloorArea_units : "Units cannot be empty",
totalGrossFloorArea_date : "Date cannot be empty",
weeklyOperatingHours : "",
weeklyOperatingHours_date : "",
numberOfComputers : "",
numberOfComputers_date : "",
numberOfWorkers : "",
numberOfWorkers_date : "",
percentHeated : "",
percentHeated_date : "",
percentCooled : "",
percentCooled_date : ""
}

const Other: React.FC<Props> = ({title,setParentForm,formErrors}) => {

    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case ('weeklyOperatingHours'):
                if (value < 0){
                    handleError('weeklyOperatingHours','Hours must be a positive number')
                }
                else if (value > 168){
                    handleError('weeklyOperatingHours','Hours mush be less than 168')
                }
                else {
                    handleError('weeklyOperatingHours','')
                }
                if( value !== '' && form.weeklyOperatingHours_date === ''){
                    handleError('weeklyOperatingHours_date','Date cannot be empty')
                }
                if( value === '' && form.weeklyOperatingHours_date === ''){
                    handleError('weeklyOperatingHours_date','')
                }
                break;
            case ('weeklyOperatingHours_date'):
                if (form.weeklyOperatingHours !== ''){
                    if (value === ''){
                        handleError('weeklyOperatingHours_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('weeklyOperatingHours_date','Invalid Date')
                    }
                    else {
                        handleError('weeklyOperatingHours_date','')
                    }
                }
                break;
            case ('numberOfComputers'):
                if (value < 0){
                    handleError('numberOfComputers','Number of computers must be a positive number')
                }
                else {
                    handleError('numberOfComputers','')
                }
                if( value !== '' && form.numberOfComputers_date === ''){
                    handleError('numberOfComputers_date','Date cannot be empty')
                }
                if( value === '' && form.numberOfComputers_date === ''){
                    handleError('numberOfComputers_date','')
                }
                break;
            case ('numberOfComputers_date'):
                if (form.numberOfComputers !== ''){
                    if (value === ''){
                        handleError('numberOfComputers_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfComputers_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfComputers_date','')
                    }
                }
                break;
            case ('numberOfWorkers'):
                if (value < 0){
                    handleError('numberOfWorkers','Number of computers must be a positive number')
                }
                else {
                    handleError('numberOfWorkers','')
                }
                if( value !== '' && form.numberOfWorkers_date === ''){
                    handleError('numberOfWorkers_date','Date cannot be empty')
                }
                if( value === '' && form.numberOfWorkers_date === ''){
                    handleError('numberOfWorkers_date','')
                }
                break;
            case ('numberOfWorkers_date'):
                if (form.numberOfWorkers !== ''){
                    if (value === ''){
                        handleError('numberOfWorkers_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfWorkers_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfWorkers_date','')
                    }
                }
                break;
                case ('percentCooled'):
                    if (value < 0){
                        handleError('percentCooled','Number of computers must be a positive number')
                    }
                    else {
                        handleError('percentCooled','')
                    }
                    if( value !== '' && form.numberOfWorkers_date === ''){
                        handleError('percentCooled_date','Date cannot be empty')
                    }
                    if( value === '' && form.numberOfWorkers_date === ''){
                        handleError('percentCooled_date','')
                    }
                    break;
                case ('percentCooled_date'):
                    if (form.numberOfWorkers !== ''){
                        if (value === ''){
                            handleError('percentCooled_date','Date cannot be empty')
                        }
                        else if (new Date(value).getTime() > getCurrentDate()){
                            handleError('percentCooled_date','Invalid Date')
                        }
                        else {
                            handleError('percentCooled_date','')
                        }
                    }
                    break;
                    case ('percentHeated'):
                        if (value < 0){
                            handleError('percentHeated','Number of computers must be a positive number')
                        }
                        else {
                            handleError('percentHeated','')
                        }
                        if( value !== '' && form.numberOfWorkers_date === ''){
                            handleError('percentHeated_date','Date cannot be empty')
                        }
                        if( value === '' && form.numberOfWorkers_date === ''){
                            handleError('percentHeated_date','')
                        }
                        break;
                    case ('percentHeated_date'):
                        if (form.numberOfWorkers !== ''){
                            if (value === ''){
                                handleError('percentHeated_date','Date cannot be empty')
                            }
                            else if (new Date(value).getTime() > getCurrentDate()){
                                handleError('percentHeated_date','Invalid Date')
                            }
                            else {
                                handleError('percentHeated_date','')
                            }
                        }
                        break;
            default:
                break;
        }
    }

    return (
        <div>
            <h1>{title} Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Total Gross Floor Area:" type="number" errorText={errors.totalGrossFloorArea}  value={form.totalGrossFloorArea} placeholder='GFA' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.totalGrossFloorArea_date} value={form.totalGrossFloorArea_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Weekly Operating Hours" type="number" errorText={errors.weeklyOperatingHours}  value={form.weeklyOperatingHours} placeholder='Hours' onIonInput={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.weeklyOperatingHours_date}  value={form.weeklyOperatingHours_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Number of Computers" type="number" errorText={errors.numberOfComputers}  value={form.numberOfComputers} placeholder='Computers' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfComputers'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfComputers_date}  value={form.numberOfComputers_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfComputers_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Number of Workers" type="number" errorText={errors.numberOfWorkers}  value={form.numberOfWorkers} placeholder='Computers' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfWorkers'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfWorkers_date}  value={form.numberOfWorkers_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfWorkers_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Percent Cooled" className={errors.percentCooled === '' ? "valid":'invalid'}  value={form.percentCooled} interface='popover' placeholder='Percent Cooled' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentCooled}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.percentCooled_date}  value={form.percentCooled_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'percentCooled_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Percent Heated" className={errors.percentHeated === '' ? "valid":'invalid'}  value={form.percentHeated} interface='popover' placeholder='Percent Heated' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentHeated}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.percentHeated_date}  value={form.percentHeated_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'percentHeated_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default Other