import { IonCol, IonIcon, IonItem, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { star, starOutline } from "ionicons/icons"
import { useEffect, useState } from "react"
import AE2Input from "../../elements/AE2Input"

interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
    totalGrossFloorArea : "",
    totalGrossFloorArea_units : "",
    totalGrossFloorArea_date : "",
    weeklyOperatingHours : "",
    weeklyOperatingHours_date : "",
    seatingCapacity : "",
    seatingCapacity_date : "",
    numberOfWeekdaysOpen : "",
    numberOfWeekdaysOpen_date : "",
    grossFloorAreaUsedForFoodPreparation : "",
    grossFloorAreaUsedForFoodPreparation_units : "",
    grossFloorAreaUsedForFoodPreparation_date : "",
    cookingFacilities : "",
    cookingFacilities_date : "",
    numberOfComputers : "",
    numberOfComputers_date : "",
    percentCooled : "",
    percentCooled_date : "",
    percentHeated : "",
    percentHeated_date : "",
    numberOfCommercialRefrigerationUnits : "",
    numberOfCommercialRefrigerationUnits_date : "",
}

const startErrors = {
    totalGrossFloorArea : "Field Required",
    totalGrossFloorArea_units : "Select Units",
    totalGrossFloorArea_date : "Date is required",
    weeklyOperatingHours : "",
    weeklyOperatingHours_date : "",
    seatingCapacity : "",
    seatingCapacity_date : "",
    numberOfWeekdaysOpen : "",
    numberOfWeekdaysOpen_date : "",
    grossFloorAreaUsedForFoodPreparation : "",
    grossFloorAreaUsedForFoodPreparation_units : "",
    grossFloorAreaUsedForFoodPreparation_date : "",
    cookingFacilities : "",
    cookingFacilities_date : "",
    numberOfComputers : "",
    numberOfComputers_date : "",
    percentCooled : "",
    percentCooled_date : "",
    percentHeated : "",
    percentHeated_date : "",
    numberOfCommercialRefrigerationUnits : "",
    numberOfCommercialRefrigerationUnits_date : "",
}



const Worship: React.FC<Props> = ({setParentForm,formErrors}) => {
    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case('seatingCapacity'):
                if(value !==''){
                    form.seatingCapacity_date === '' ? handleError('seatingCapacity_date','Date cannot be empty') : handleError('seatingCapacity_date','')
                    value < 0 ? handleError('seatingCapacity','Must be a Positive number') : handleError('seatingCapacity','')
                }
                else{
                    handleError('seatingCapacity','')
                    handleError('seatingCapacity_date','')
                }
                break;
            case('seatingCapacity_date'):
                if(value !==''){
                    form.seatingCapacity === '' ? handleError('seatingCapacity','Field cannot be empty') : handleError('seatingCapacity','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('seatingCapacity_date','Invalid Date') : handleError('seatingCapacity_date','')
                }
                else{
                    handleError('seatingCapacity','')
                    handleError('seatingCapacity_date','')
                }
                break;
            case('weeklyOperatingHours'):
                if(value !==''){
                    form.weeklyOperatingHours_date === '' ? handleError('weeklyOperatingHours_date','Date cannot be empty') : handleError('weeklyOperatingHours_date','')
                    value < 0 ? handleError('weeklyOperatingHours','Must be a Positive number') : handleError('weeklyOperatingHours','')
                }
                else{
                    handleError('weeklyOperatingHours','')
                    handleError('weeklyOperatingHours_date','')
                }
                break;
            case('weeklyOperatingHours_date'):
                if(value !==''){
                    form.weeklyOperatingHours === '' ? handleError('weeklyOperatingHours','Field cannot be empty') : handleError('weeklyOperatingHours','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('weeklyOperatingHours_date','Invalid Date') : handleError('weeklyOperatingHours_date','')
                }
                else{
                    handleError('weeklyOperatingHours','')
                    handleError('weeklyOperatingHours_date','')
                }
                break;
            case('numberOfWeekdaysOpen'):
                if(value !==''){
                    form.numberOfWeekdaysOpen_date === '' ? handleError('numberOfWeekdaysOpen_date','Date cannot be empty') : handleError('numberOfWeekdaysOpen_date','')
                    value < 0 ? handleError('numberOfWeekdaysOpen','Must be a Positive number') :value > 5 ? handleError('weeklyOperatingHours','Max Value is 15') : handleError('numberOfWeekdaysOpen','')
                }
                else{
                    handleError('numberOfWeekdaysOpen','')
                    handleError('numberOfWeekdaysOpen_date','')
                }
                break;
            case('numberOfWeekdaysOpen_date'):
                if(value !==''){
                    form.numberOfWeekdaysOpen === '' ? handleError('numberOfWeekdaysOpen','Field cannot be empty') : handleError('numberOfWeekdaysOpen','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfWeekdaysOpen_date','Invalid Date') : handleError('numberOfWeekdaysOpen_date','')
                }
                else{
                    handleError('numberOfWeekdaysOpen','')
                    handleError('numberOfWeekdaysOpen_date','')
                }
                break;
            case('numberOfCommercialRefrigerationUnits'):
                if(value !==''){
                    form.numberOfCommercialRefrigerationUnits_date === '' ? handleError('numberOfCommercialRefrigerationUnits_date','Date cannot be empty') : handleError('numberOfCommercialRefrigerationUnits_date','')
                    value < 0 ? handleError('numberOfCommercialRefrigerationUnits','Must be a Positive number') : handleError('numberOfCommercialRefrigerationUnits','')
                }
                else{
                    handleError('numberOfCommercialRefrigerationUnits','')
                    handleError('numberOfCommercialRefrigerationUnits_date','')
                }
                break;
            case('numberOfCommercialRefrigerationUnits_date'):
                if(value !==''){
                    form.numberOfCommercialRefrigerationUnits === '' ? handleError('numberOfCommercialRefrigerationUnits','Field cannot be empty') : handleError('numberOfCommercialRefrigerationUnits','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfCommercialRefrigerationUnits_date','Invalid Date') : handleError('numberOfCommercialRefrigerationUnits_date','')
                }
                else{
                    handleError('numberOfCommercialRefrigerationUnits','')
                    handleError('numberOfCommercialRefrigerationUnits_date','')
                }
                break;
            case('numberOfComputers'):
                if(value !==''){
                    form.numberOfComputers_date === '' ? handleError('numberOfComputers_date','Date cannot be empty') : handleError('numberOfComputers_date','')
                    value < 0 ? handleError('numberOfComputers','Must be a Positive number') : handleError('numberOfComputers','')
                }
                else{
                    handleError('numberOfComputers','')
                    handleError('numberOfComputers_date','')
                }
                break;
            case('numberOfComputers_date'):
                if(value !==''){
                    form.numberOfComputers === '' ? handleError('numberOfComputers','Field cannot be empty') : handleError('numberOfComputers','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfComputers_date','Invalid Date') : handleError('numberOfComputers_date','')
                }
                else{
                    handleError('numberOfComputers','')
                    handleError('numberOfComputers_date','')
                }
                break;
            case ('percentCooled'):
                if( value !== '' && form.percentCooled_date === ''){
                    handleError('percentCooled_date','Date cannot be empty')
                }
                else if( value === '' && form.percentCooled_date === ''){
                    handleError('percentCooled_date','')
                }
                else {
                    handleError('percentCooled','')
                }
                break;
            case ('percentCooled_date'):
                if (form.percentCooled !== ''){
                    if (value === ''){
                        handleError('percentCooled_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentCooled_date','Invalid Date')
                    }
                else {
                        handleError('percentCooled_date','')
                    }
                break;
            case ('percentHeated'):
                if( value !== '' && form.percentHeated_date === ''){
                    handleError('percentHeated_date','Date cannot be empty')
                }
                else if( value === '' && form.percentHeated_date === ''){
                    handleError('percentHeated_date','')
                }
                else {
                    handleError('percentHeated','')
                }
                break;
            case ('percentHeated_date'):
                if (form.percentHeated !== ''){
                    if (value === ''){
                        handleError('percentHeated_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentHeated_date','Invalid Date')
                    }
                else {
                        handleError('percentHeated_date','')
                    }
                break;
            case('grossFloorAreaUsedForFoodPreparation'):
                if(value !== ''){
                    form.grossFloorAreaUsedForFoodPreparation_date === '' ? handleError('grossFloorAreaUsedForFoodPreparation_date','Date Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_date',"")
                    form.grossFloorAreaUsedForFoodPreparation_units === '' ? handleError('grossFloorAreaUsedForFoodPreparation_units','Units Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_units',"")
                    value < 0 ? handleError('grossFloorAreaUsedForFoodPreparation','Must be a Positive Number') : handleError('grossFloorAreaUsedForFoodPreparation',"")
                }
                else{
                    handleError('grossFloorAreaUsedForFoodPreparation','')
                    if(form.grossFloorAreaUsedForFoodPreparation_date === '' && form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation_date','')
                        handleError('grossFloorAreaUsedForFoodPreparation_units','')}
                    else if(form.grossFloorAreaUsedForFoodPreparation_date === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_date'," Date Cannot be empty")
                    }
                    else if(form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_units'," Unit Cannot be empty")
                    }

                }
                break;
            case('grossFloorAreaUsedForFoodPreparation_date'):
                if(value !== ''){
                    form.grossFloorAreaUsedForFoodPreparation === '' ? handleError('grossFloorAreaUsedForFoodPreparation','Field Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation',"")
                    form.grossFloorAreaUsedForFoodPreparation_units === '' ? handleError('grossFloorAreaUsedForFoodPreparation_units','Units Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_units',"")
                    new Date(value).getTime() > getCurrentDate() ? handleError('grossFloorAreaUsedForFoodPreparation_date','Invalid Date') : handleError('grossFloorAreaUsedForFoodPreparation_date','')
                }
                else{
                    handleError('grossFloorAreaUsedForFoodPreparation_date','')
                    if(form.grossFloorAreaUsedForFoodPreparation === '' && form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation','')
                        handleError('grossFloorAreaUsedForFoodPreparation_units','')}
                    else if(form.grossFloorAreaUsedForFoodPreparation === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_date'," Date Cannot be empty")
                    }
                    else if(form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation_date'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation'," Unit Cannot be empty")
                    }
                }
                break;
            case('grossFloorAreaUsedForFoodPreparation_units'):
                if(value !== ''){
                    form.grossFloorAreaUsedForFoodPreparation === '' ? handleError('grossFloorAreaUsedForFoodPreparation','Field Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation',"")
                    form.grossFloorAreaUsedForFoodPreparation_date === '' ? handleError('grossFloorAreaUsedForFoodPreparation_date','Date Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_date',"")
                }
                else{
                    handleError('grossFloorAreaUsedForFoodPreparation_units','')
                    if(form.grossFloorAreaUsedForFoodPreparation === '' && form.grossFloorAreaUsedForFoodPreparation_date === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation','')
                        handleError('grossFloorAreaUsedForFoodPreparation_date','')}
                    else if(form.grossFloorAreaUsedForFoodPreparation === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_units'," Date Cannot be empty")
                    }
                    else if(form.grossFloorAreaUsedForFoodPreparation === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation_date'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation'," Unit Cannot be empty")
                    }

                }
                break;
            case('cookingFacilities'):
                if(value !==''){
                    form.cookingFacilities_date === '' ? handleError('cookingFacilities_date','Date cannot be empty') : handleError('cookingFacilities_date','')

                }
                else{
                    handleError('cookingFacilities','')
                    handleError('cookingFacilities_date','')
                }
                break;
            case('cookingFacilities_date'):
                if(value !==''){
                    form.cookingFacilities === '' ? handleError('cookingFacilities','Field cannot be empty') : handleError('cookingFacilities','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('cookingFacilities_date','Invalid Date') : handleError('cookingFacilities_date','')
                }
                else{
                    handleError('cookingFacilities','')
                    handleError('cookingFacilities_date','')
                }
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <h1>Worship Facility Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.totalGrossFloorArea}  value={form.totalGrossFloorArea} placeholder='GFA' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Total Gross Floor Area:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.totalGrossFloorArea_date} value={form.totalGrossFloorArea_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Number of Commercial Refrigeration/Freezer Units" labelPlacement="stacked" type="number" errorText={errors.numberOfCommercialRefrigerationUnits}  value={form.numberOfCommercialRefrigerationUnits} placeholder='Commercial Refrigeration Units' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfCommercialRefrigerationUnits'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfCommercialRefrigerationUnits_date}  value={form.numberOfCommercialRefrigerationUnits_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfCommercialRefrigerationUnits_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect className={errors.percentCooled === '' ? "valid":'invalid'}  value={form.percentCooled} interface='popover' placeholder='Percent Cooled' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Percent Cooled</div>
                        </IonSelect>
                        <div className="error-detail">{errors.percentCooled}</div>
                        </ div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.percentCooled_date}  value={form.percentCooled_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'percentCooled_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect className={errors.percentHeated === '' ? "valid":'invalid'}  value={form.percentHeated} interface='popover' placeholder='Percent Heated' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Percent Heated</div>
                        </IonSelect>
                        <div className="error-detail">{errors.percentHeated}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.percentHeated_date}  value={form.percentHeated_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'percentHeated_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Number of Computers" type="number" errorText={errors.numberOfComputers}  value={form.numberOfComputers} placeholder='# Computers' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfComputers'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfComputers_date}  value={form.numberOfComputers_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfComputers_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Cooking Facilities?" className={errors.cookingFacilities === '' ? "valid":'invalid'}  value={form.cookingFacilities} interface='popover' placeholder='Cooking Facilities' onIonChange={e => (handleFormChange(e.detail.value!,'cookingFacilities'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.cookingFacilities}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.cookingFacilities_date}  value={form.cookingFacilities_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'cookingFacilities_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.weeklyOperatingHours}  value={form.weeklyOperatingHours} placeholder='Weekly Operating Hours' onIonInput={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Weekly Operating Hours</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.weeklyOperatingHours_date}  value={form.weeklyOperatingHours_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.seatingCapacity}  value={form.seatingCapacity} placeholder='Seating Capacity' onIonInput={e => (handleFormChange(e.detail.value!,'seatingCapacity'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Seating Capacity</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.seatingCapacity_date}  value={form.seatingCapacity_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'seatingCapacity_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Number of Weekdays Open" type="number" errorText={errors.numberOfWeekdaysOpen}  value={form.numberOfWeekdaysOpen} placeholder='Number Of Weekdays Open' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfWeekdaysOpen'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfWeekdaysOpen_date}  value={form.numberOfWeekdaysOpen_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfWeekdaysOpen_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input labelPlacement="stacked" type="number" errorText={errors.grossFloorAreaUsedForFoodPreparation}  value={form.grossFloorAreaUsedForFoodPreparation} placeholder='GFA for Food Preparation' onIonInput={e => (handleFormChange(e.detail.value!,'grossFloorAreaUsedForFoodPreparation'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Gross Floor Area Used For Food Preparation</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.grossFloorAreaUsedForFoodPreparation_units === '' ? "valid":'invalid'} value={form.grossFloorAreaUsedForFoodPreparation_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'grossFloorAreaUsedForFoodPreparation_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.grossFloorAreaUsedForFoodPreparation_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.grossFloorAreaUsedForFoodPreparation_date} value={form.grossFloorAreaUsedForFoodPreparation_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'grossFloorAreaUsedForFoodPreparation_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonItem><IonIcon slot="start" color="white" ios={starOutline} md={star} />Required for Energy Star Score</IonItem>
        </div>
    )
}

export default Worship
