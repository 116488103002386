import { IonButton, IonCol, IonGrid, IonItem, IonLoading, IonRow, IonSelect, IonSelectOption } from "@ionic/react";
import { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router";
import { API_URL } from "../../actions/settings"
import BankBranch from "./property_use_forms/BankBranch";
import Barracks from "./property_use_forms/Barracks";
import CollegeUniversity from "./property_use_forms/CollegeUniversity";
import Courthouse from "./property_use_forms/Courthouse";
import DataCenter from "./property_use_forms/DataCenter";
import DistributionCenter from "./property_use_forms/DistributionCenter";
import DrinkingWaterDistribution from "./property_use_forms/DrinkingWaterDistribution";
import FinancialOffice from "./property_use_forms/FinancialOffice";
import FoodSales from "./property_use_forms/FoodSales";
import Hospital from "./property_use_forms/Hospital";
import Hotel from "./property_use_forms/Hotel";
import IceRink from "./property_use_forms/IceRink";
import IndoorArena from "./property_use_forms/IndoorArena";
import K12School from "./property_use_forms/K12School";
import Library from "./property_use_forms/Library"
import MedicalOffice from "./property_use_forms/MedicalOffice";
import MultiFamily from "./property_use_forms/MultiFamily";
import NonRefWarehouse from "./property_use_forms/NonRefWarehouse";
import Office from "./property_use_forms/Office";
import Other from "./property_use_forms/Other";
import OtherStadium from "./property_use_forms/OtherStadium";
import Parking from "./property_use_forms/Parking";
import RefWarehouse from "./property_use_forms/RefWarehouse";
import ResidenceHall from "./property_use_forms/ResidenceHall";
import ResidentialCare from "./property_use_forms/ResidentialCare";
import Retail from "./property_use_forms/Retail";
import StoreGasStation from "./property_use_forms/StoreGasStation";
import StoreNoGasStation from "./property_use_forms/StoreNoGasStation";
import SelfStorage from "./property_use_forms/SelfStorage"
import SeniorLiving from "./property_use_forms/SeniorLiving";
import SingleFamilyHome from "./property_use_forms/SingleFamilyHome";
import StadiumClosed from "./property_use_forms/StadiumClosed";
import StadiumOpened from "./property_use_forms/StadiumOpened";
import Supermarket from "./property_use_forms/Supermarket";
import SwimmingPool from "./property_use_forms/SwimmingPool";
import WasteWaterTreatment from "./property_use_forms/WasteWaterTreatment";
import Wholesale from "./property_use_forms/Wholesale";
import Worship from "./property_use_forms/Worship";
import CheckRole from "../extras/CheckRole";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import NotificationPopUp from "../extras/NotificationPopUp";
import AE2Input from "../elements/AE2Input";


interface Params {
    propertyId:string
}

const startingForm = {
    name: '',
    current:'',
    type: '',
    form:{}
}


const startErrors = {
    name: '',
    current:'',
    type: '',
}

async function getProperty(token:any,id:any) {

    return fetch(`${API_URL}/energystar/properties/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

async function getTypes(token:any) {

    return fetch(`${API_URL}/energystar/properties/types`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

async function submitForm(token:any,data:any, id:any) {

    return fetch(`${API_URL}/energystar/property/${id}/property_use`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(data)

    })
      .then(data => data.json())
   }


const PropertyUseForm: React.FC = () => {
    const { propertyId } = useParams<Params>();
    const [property, setProperty] = useState<any>(null)
    const [loaded, setLoaded] = useState(false)
    const [form, setForm] = useState(startingForm)
    const [types, setTypes] = useState([])
    const [errors, setErrors] = useState<any>(startErrors)
    const user = useAppSelector(selectUser)

    const history = useHistory()

    const handleProperty = async (id:any) => {
        const token = localStorage.getItem('token') || ""
        const res = await getProperty(`Token ${token.slice(1,-1)}`,id)
        setProperty(res.property[0])
        setLoaded(true)

    }


    const handleTypes = async () => {
        const token = localStorage.getItem('token') || ""
        const res = await getTypes(`Token ${token.slice(1,-1)}`)
        setTypes(res.data)


    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };

    useEffect(()=>{
        handleProperty(propertyId)
        handleTypes()
    }, [propertyId])

    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const validateAll = () =>{

        if(form.name === '' || form.current === '' || form.type === ''){
            return false
        }
        for (const error in errors){
            if (errors[error] !== ''){
                return false
            }
        }
        return true
    }

    const handleSubmit = async () =>{
        const valid = validateAll()
        if (valid){

            const token = localStorage.getItem('token') || ""
            const res = await submitForm(`Token ${token.slice(1,-1)}`,form,propertyId)
            if (res.status === "success"){
                setForm(startingForm)
                NotificationPopUp('success','Property Use Created')
            history.push(`/energystar/properties/${res.details}`)

            }
            else{
                NotificationPopUp('error',res.detail||res.ERROR,res.error||"Error")
            }
        }
        else{
            NotificationPopUp('error','Form is not complete','Incomplete Form')
        }
    }


    const loadForm = () =>{
        switch (form.type){
            case "adultEducation":
                return <Other title='Adult Education' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "bankBranch":
                return <BankBranch setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "ambulatorySurgicalCenter":
                return <Other title='Ambulatory Surgical Center' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "aquarium":
                return <Other title='Aquarium' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "automobileDealership":
                return <Other title='Automobile Dealership' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "barNightclub":
                return <Other title='Bar Nightclub' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "barracks":
                return <Barracks setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "bowlingAlley":
                return <Other title='Bowling Alley' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "casino":
                return <Other title='Casino' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "collegeUniversity":
                return <CollegeUniversity setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "convenienceStoreWithGasStation":
                return <StoreGasStation setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "convenienceStoreWithoutGasStation":
                return <StoreNoGasStation setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "conventionCenter":
                return <Other title='Convention Center' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "courthouse":
                return <Courthouse setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "dataCenter":
                return <DataCenter setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "distributionCenter":
                return <DistributionCenter setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "drinkingWaterTreatmentAndDistribution":
                return <DrinkingWaterDistribution setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "enclosedMall":
                return <Other title='Enclosed Mall' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "energyPowerStation":
                return <Other title='Energy Power Station' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "fastFoodRestaurant":
                return <Other title='Fast Food Restaurant' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "financialOffice":
                    return <FinancialOffice setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "fireStation":
                    return <Other title='Fire Station' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "fitnessCenterHealthClubGym":
                    return <Other title='Fitness Center / Health Club / Gym' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "foodService":
                    return <Other title='Food Service' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "foodSales":
                return <FoodSales setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "hospital":
                return <Hospital setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "hotel":
                return <Hotel setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "iceCurlingRink":
                return <IceRink setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "indoorArena":
                return <IndoorArena setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "k12School":
                return <K12School setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "laboratory":
                return <Other title='Laboratory' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "library":
                return <Library title='Library' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "lifestyleCenter":
                return <Other title='Lifestyle Center' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "mailingCenterPostOffice":
                return <Other title='Mailing Center/Post Office' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "manufacturingIndustrialPlant":
                return <Other title='Manufacturing Industrial Plant' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "movieTheater":
                return <Other title='Movie Theater' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "museum":
                return <Other title='Museum' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "medicalOffice":
                return <MedicalOffice setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "multifamilyHousing":
                return <MultiFamily setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "nonRefrigeratedWarehouse":
                return <NonRefWarehouse setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "office":
                return <Office setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "other":
                return <Other title='Other' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "otherEducation":
                return <Other title='Other - Education' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "otherEntertainmentPublicAssembly":
                return <Other title='Other - Entertainment Public Assembly' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "otherLodgingResidential":
                return <Other title='Other - Lodging Residential' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "otherMall":
                return <Other title='Other - Mall' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "otherPublicServices":
                return <Other title='Other - Public Services' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "otherRecreation":
                return <Other title='Other - Recreation' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "otherRestaurantBar":
                return <Other title='Other - Restaurant Bar' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "otherServices":
                return <Other title='Other - Services' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "otherSpecialityHospital":
                return <Other title='Other - Speciality Hospital' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "otherTechnologyScience":
                return <Other title='Other - Technology/Science' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "otherUtility":
                return <Other title='Other - Utility' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "outpatientRehabilitationPhysicalTherapy":
                return <Other title='Other - Outpatient Rehabilitation/Physical Therapy' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "otherStadium":
                return <OtherStadium setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "parking":
                return <Parking setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "performingArts":
                return <Other title='Performing Arts' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "personalServices":
                return <Other title='Personal Services' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "policeStation":
                return <Other title='Police Station' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "preschoolDaycare":
                return <Other title='PreSchool/Daycare' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "prison":
                return <Other title='Prison' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "raceTrack":
                return <Other title='Race Track' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "refrigeratedWarehouse":
                return <RefWarehouse setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "repairServices":
                return <Other title='Repair Services' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "restaurant":
                return <Other title='Restaurant' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "residenceHallDormitory":
                return <ResidenceHall setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "residentialCareFacility":
                return <ResidentialCare setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "retail":
                return <Retail setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "rollerRink":
                return <Other title='Roller Rink' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "selfStorageFacility":
                return <SelfStorage setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "seniorLivingCommunity":
                return <SeniorLiving setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "singleFamilyHome":
                return <SingleFamilyHome setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "socialMeetingHall":
                return <Other title='Social Meeting Hall' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "stadiumClosed":
                return <StadiumClosed setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "stadiumOpen":
                return <StadiumOpened setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "stripMall":
                return <Other title='Strip Mall' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "transportationTerminalStation":
                return <Other title='Transportation Terminal Station' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "urgentCareClinicOtherOutpatient":
                return <Other title='Urgent Care/Clinic/Other Outpatient' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "veterinaryOffice":
                return <Other title='Veterinary Office' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "vocationalSchool":
                return <Other title='Vocational School' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "zoo":
                return <Other title='Zoo' setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "supermarket":
                return <Supermarket setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "swimmingPool":
                return <SwimmingPool setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "wastewaterTreatmentPlant":
                return <WasteWaterTreatment setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "wholesaleClubSupercenter":
                return <Wholesale setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>
            case "worshipFacility":
                return <Worship setParentForm={handleFormChange} formErrors = {[errors,setErrors]}/>

            default:
                return (<IonRow className="light">Select A Property Type</IonRow>)
        }
    }



    const validate = (name:string,value:string) =>{
        switch(name){
            case ('name'):
                if (value === null || value.length < 5){
                    handleError('name','Name must be greater than 5 characters')
                }
                else {
                    handleError('name','')
                }
                break;
            case ('current'):
                if (value === null){
                    handleError('current','Current Date Cannot be Empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('current','Invalid Date')
                }
                else {
                    handleError('current','')
                }
                break;
            case ('type'):
                if (value === null){
                    handleError('type','Please Select a Type')
                }
                else {
                    handleError('type','')
                }
                break;
            default:
                break;
        }
    }

    if (loaded && !property.in_energy_star){
        history.push(`/energystar/properties/${propertyId}`)
    }

    if (!loaded || user.first_name===""){
        return(
            <IonLoading
        cssClass='my-custom-class'
        isOpen={!loaded}
        message={'Loading...'}
        spinner='bubbles'
      />
        )
    }

    if(!CheckRole(user,['Admin','Supervisor'])){
        return <Redirect to="/403"/>
    }


    return (
            <form>
                <IonRow className="form-title-row" ><h2>Add Property Use Data</h2> <IonButton className="cancel-btn" size="small"  color="danger" href={`/energystar/properties/${propertyId}`}> Cancel </IonButton></IonRow>
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" sizeLg="4">
                            <IonItem lines="none">
                                <AE2Input label='Name' labelPlacement='floating' type="text" errorText={errors.name} value={form.name} placeholder='Name' onIonInput={e => (handleFormChange(e.detail.value!,'name'))}></AE2Input>
                            </IonItem>
                        </IonCol>
                        <IonCol size="12" sizeLg="3">
                            <IonItem lines="none">
                                <AE2Input label='Current As of:' labelPlacement='floating' type="date" errorText={errors.current} value={form.current} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'current'))}></AE2Input>
                            </IonItem>
                        </IonCol>
                        <IonCol size="12" sizeLg="4">

                            <IonItem lines="none">
                            <div style={{width:'100%'}}>
                                <IonSelect label='Property Type' labelPlacement='floating' interface='popover' value={form.type} placeholder="Property Type" onIonChange={e => (handleFormChange(e.detail.value!,'type'))}>
                                    {types.map((type:any) => {
                                    return (
                                        <IonSelectOption key={type.id} value={type.name}>{type.fullname}</IonSelectOption>
                                    )
                            })}
                                </IonSelect>
                                <div className="error-detail">{errors.type}</div>
                                </div>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <hr/>
                    {loadForm()}
                    <IonRow>
                        <IonButton type='button' onClick={handleSubmit}>Submit</IonButton>
                    </IonRow>
                </IonGrid>

            </form>
    )
}

export default PropertyUseForm