import { IonButton, IonCol, IonContent, IonRow, IonTitle } from "@ionic/react"
import Ae2Header from "../../components/AE2/Ae2Header"
import CustomersTable from "../../components/AE2/CustomersTable"
import CheckRole from "../../components/extras/CheckRole"
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import './Customers.css'
import { Redirect } from "react-router"


const Customers:React.FC = () =>{
    const user = useAppSelector(selectUser)

    // role 4 - Customer
    if(CheckRole(user,[4])){
        return <Redirect to="/403"/>
    }

    return (
        <IonContent className="main-background">
            <Ae2Header/>
            <IonRow  className="ion-justify-content-center">
                <IonCol size="12" className="text-center">
                    <IonTitle className="main-heading">AE2 Customers</IonTitle>
                    <IonRow hidden={!CheckRole(user,['Supervisor','Admin','Employee'])} className="ion-justify-content-center"><IonButton color='secondary' href='/customer'>Create a Customer</IonButton></IonRow>
                </IonCol>
                <IonCol size="12" sizeLg="8">
                    <CustomersTable/>
                </IonCol>
            </IonRow>
        </IonContent>
    )
}

export default Customers