import { IonContent, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import { API_URL } from "../../actions/settings";
import AE2BuildingInfo from "../../components/AE2/AE2BuildingInfo";
import AE2CampusInfo from "../../components/AE2/AE2CampusInfo";
import AE2CustomerInfo from "../../components/AE2/AE2CustomerInfo";
import Ae2Header from "../../components/AE2/Ae2Header";
import AE2Home from "../../components/AE2/AE2Home";

import "./Homepage.css";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import CustomerHomepage from "../Customers/CustomerHomepage";
import { Redirect } from "react-router";
import NotificationPopUp from "../../components/extras/NotificationPopUp";
import { DoesNoTExistsError, NotAuthorizedError, UnexpectedError } from "../Errors/Errors";

async function getItemApi(token: any, id: any, section: String) {
  return fetch(`${API_URL}/${section}/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((response) =>{
    if(response.ok)
      return response.json()
    else if(response.status===404){
      throw new DoesNoTExistsError('Page Does Not Exists')
    }
    else if(response.status===401){
      throw new NotAuthorizedError('You Are Not Authorized to Access That Page')
    }
    else{
      throw new UnexpectedError()
    }
  })
  .catch(error => NotificationPopUp('error',error.message));
}

const getItemData = async (
  token: any,
  id: any,
  section: string,
  setItem: any
) => {
  const res = await getItemApi(`Token ${token.slice(1, -1)}`, id, section);
  if(res){
    setItem(res.data)
  }else{
    setItem('error')
  }
};

const Homepage: React.FC = () => {
  let params = new URLSearchParams(window.location.search);
  const user = useAppSelector(selectUser);
  const id = params.get("id");
  const section = params.get("sec");
  // const history = useHistory();

  const [item, setItem] = useState<any>("");
  const [home, setHome] = useState(true);

  useEffect(() => {
    if (id && section) {
      const token = localStorage.getItem("token");
      getItemData(token, id, section, setItem);
      setHome(false);
    }
    else{
      setHome(true)
    }
  }, [id, section]);

  if(item==='error'){
    return <Redirect to="/home"/>
  }

  if(user.username !== '' && user.reset_password){
    return <Redirect to='/reset_password'/>
  }

  return (
    <IonPage className="ae2-home">
      <Ae2Header />

      <IonContent className="ae2-content">
        {section === "customers" ? <AE2CustomerInfo item={item} /> : ""}
        {section === "campuses" ? <AE2CampusInfo item={item} /> : ""}
        {section === "buildings" ? <AE2BuildingInfo item={item} /> : ""}
        {home && user.username !== '' ? (
          Number(user.role.id) !== 4 ? (
            <AE2Home />

          ) : (
            <CustomerHomepage />
          )
        ) : (
          ""
        )}
      </IonContent>
    </IonPage>
  );
};

export default Homepage;
