import React, { useEffect, useState } from "react";
import { Building } from "../../interfaces/Building";
import {
  IonCol,
  IonCheckbox,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonButton,
} from "@ionic/react";
import EnergyStarSelectBuildings from "./EnergyStarSelectBuildings";
import { API_URL } from "../../actions/settings";
import EnergyStarTaskProgressModal from "./EnergyStarTaskProgressModal";

async function getData(type: string, data: any, token: string) {
  return fetch(`${API_URL}/energystar/properties/${type}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

const type_definitions: any = {
  baseline_and_target:
    "Request the baseline date, score, data and target",
  get_monthly_use:
    "Request the electricity use and gas use from Energy Star for each selected month. ",
  properties_data:
    "Request the Energy Star Score, energy cost, electricity use, electricity cost, gas use, gas cost, target eui, water cost, and ghg emissions. Values are total for the last 12 months based on the ending month/year.",
  consumption_data:
    "Request all the consumption data for a building(s) that is available in Energy Star.",
  get_certification:
    "Request all certification dates and scores for a building(s) in Energy Star.",
  degreedayscorrelations:
    "Calculate R^2 values used for cost avoidance calculations",
  cumulative_savings:
    "Calculate the cost avoidance for a building(s). R^2, baseline date, and campus optimizer date are required for these calculations.",
};

interface Props {
  tab: string;
  buildings: Building[] | null;
}

const EnergyStarDataRequestForm: React.FC<Props> = ({ tab, buildings }) => {
  const [requestType, setRequestType] = useState("none");
  const [selectedMonths, setSelectedMonths] = useState<any>([]);
  const [selectedYears, setSelectedYears] = useState<any>([]);
  const [selectedBuildings, setSelectedBuildings] = useState<any>([]);
  const monthYearRequired = ["get_monthly_use", "properties_data"];
  const [task, setTask] = useState("");
  const [opened, setOpened] = useState(false);
  const [resetResults, setResetResults] = useState(false);
  const today = new Date();
  const years = [];
  for (
    let year = today.getFullYear();
    year >= today.getFullYear() - 15;
    year--
  ) {
    years.push(year);
  }

  const handleSelectMonth = (i: number) => {
    setSelectedMonths((prev: any) => {
      const existingIndex = prev.indexOf(i);
      if (existingIndex === -1) {
        return [...prev, i];
      } else {
        return prev.filter((j: any) => j !== i);
      }
    });
  };

  const handleSelectYear = (i: number) => {
    setSelectedYears((prev: any) => {
      const existingIndex = prev.indexOf(i);
      if (existingIndex === -1) {
        return [...prev, i];
      } else {
        return prev.filter((j: any) => j !== i);
      }
    });
  };

  const handleSubmit = async () => {
    setResetResults(true);
    const tkn: any = localStorage.getItem("token");
    const token = `Token ${tkn.slice(1, -1)}`;
    const data: any = {
      buildings: selectedBuildings,
    };
    if (monthYearRequired.includes(requestType)) {
      data["months"] = selectedMonths;
      data["years"] = selectedYears;
    }

    const res = await getData(requestType, data, token);
    if ("task" in res) {
      setTask(res.task);
      setOpened(true);
    }
    setResetResults(false);
  };

  useEffect(() => {
    setRequestType("none");
  }, [tab]);

  return (
    <div>
      <div>
        <div style={{ borderBottom: "1px solid white" }}>
          <h3>Select Request Type:</h3>
          <IonSelect
            value={requestType}
            fill="outline"
            interface="popover"
            onIonChange={(e) => setRequestType(e.detail.value)}
          >
            <IonSelectOption value="none"> None</IonSelectOption>
            {tab === "data" ? (
              <>
                <IonSelectOption value="baseline_and_target">
                  {" "}
                  Baseline and Target{" "}
                </IonSelectOption>
                <IonSelectOption value="get_monthly_use">
                  {" "}
                  Monthly Utility Data{" "}
                </IonSelectOption>
                <IonSelectOption value="properties_data">
                  {" "}
                  Energy Star Yearly Data{" "}
                </IonSelectOption>
                <IonSelectOption value="consumption_data">
                  {" "}
                  Consumption Data{" "}
                </IonSelectOption>
                <IonSelectOption value="get_certification">
                  {" "}
                  Energy Star Certifications{" "}
                </IonSelectOption>
              </>
            ) : null}
            {tab === "calculations" ? (
              <>
                <IonSelectOption value="degreedayscorrelations">
                  {" "}
                  Correlations (r-squared)
                </IonSelectOption>
                <IonSelectOption value="cumulative_savings">
                  {" "}
                  Cost Avoidance
                </IonSelectOption>
              </>
            ) : null}
          </IonSelect>
          <p style={{ textDecoration: "underline", fontWeight: "bolder" }}>
            {type_definitions[requestType]}
          </p>
        </div>
        {monthYearRequired.includes(requestType) ? (
          <>
            <div
              style={{ borderBottom: "1px solid white", paddingBottom: "10px" }}
            >
              <h3>Months</h3>
              <p> Select the months you want to request data for.</p>
              <IonRow>
                <IonCol>
                  <IonCheckbox
                    mode="ios"
                    checked={selectedMonths.includes(1)}
                    onIonChange={() => handleSelectMonth(1)}
                    labelPlacement="end"
                  >
                    January
                  </IonCheckbox>
                </IonCol>
                <IonCol>
                  <IonCheckbox
                    mode="ios"
                    checked={selectedMonths.includes(2)}
                    onIonChange={() => handleSelectMonth(2)}
                    labelPlacement="end"
                  >
                    February
                  </IonCheckbox>
                </IonCol>
                <IonCol>
                  <IonCheckbox
                    mode="ios"
                    checked={selectedMonths.includes(3)}
                    onIonChange={() => handleSelectMonth(3)}
                    labelPlacement="end"
                  >
                    March
                  </IonCheckbox>
                </IonCol>
                <IonCol>
                  <IonCheckbox
                    mode="ios"
                    checked={selectedMonths.includes(4)}
                    onIonChange={() => handleSelectMonth(4)}
                    labelPlacement="end"
                  >
                    April
                  </IonCheckbox>
                </IonCol>
                <IonCol>
                  <IonCheckbox
                    mode="ios"
                    checked={selectedMonths.includes(5)}
                    onIonChange={() => handleSelectMonth(5)}
                    labelPlacement="end"
                  >
                    May
                  </IonCheckbox>
                </IonCol>
                <IonCol>
                  <IonCheckbox
                    mode="ios"
                    checked={selectedMonths.includes(6)}
                    onIonChange={() => handleSelectMonth(6)}
                    labelPlacement="end"
                  >
                    June
                  </IonCheckbox>
                </IonCol>
                <IonCol>
                  <IonCheckbox
                    mode="ios"
                    checked={selectedMonths.includes(7)}
                    onIonChange={() => handleSelectMonth(7)}
                    labelPlacement="end"
                  >
                    July
                  </IonCheckbox>
                </IonCol>
                <IonCol>
                  <IonCheckbox
                    mode="ios"
                    checked={selectedMonths.includes(8)}
                    onIonChange={() => handleSelectMonth(8)}
                    labelPlacement="end"
                  >
                    August
                  </IonCheckbox>
                </IonCol>
                <IonCol>
                  <IonCheckbox
                    mode="ios"
                    checked={selectedMonths.includes(9)}
                    onIonChange={() => handleSelectMonth(9)}
                    labelPlacement="end"
                  >
                    September
                  </IonCheckbox>
                </IonCol>
                <IonCol>
                  <IonCheckbox
                    mode="ios"
                    checked={selectedMonths.includes(10)}
                    onIonChange={() => handleSelectMonth(10)}
                    labelPlacement="end"
                  >
                    October
                  </IonCheckbox>
                </IonCol>
                <IonCol>
                  <IonCheckbox
                    mode="ios"
                    checked={selectedMonths.includes(11)}
                    onIonChange={() => handleSelectMonth(11)}
                    labelPlacement="end"
                  >
                    November
                  </IonCheckbox>
                </IonCol>
                <IonCol>
                  <IonCheckbox
                    mode="ios"
                    checked={selectedMonths.includes(12)}
                    onIonChange={() => handleSelectMonth(12)}
                    labelPlacement="end"
                  >
                    December
                  </IonCheckbox>
                </IonCol>
              </IonRow>
            </div>
            <div
              style={{ borderBottom: "1px solid white", paddingBottom: "10px" }}
            >
              <h3>Years</h3>
              <p> Select the years you want to request data for.</p>
              {years.map((year) => {
                return (
                  <IonCol key={year}>
                    <IonCheckbox
                      mode="ios"
                      checked={selectedYears.includes(year)}
                      onIonChange={() => handleSelectYear(year)}
                      labelPlacement="end"
                    >
                      {" "}
                      {year}
                    </IonCheckbox>
                  </IonCol>
                );
              })}
            </div>
          </>
        ) : (
          ""
        )}
        <div>
          <h3>Buildings</h3>
          <EnergyStarSelectBuildings
            buildings={buildings}
            selectedBuildings={selectedBuildings}
            setSelectedBuildings={setSelectedBuildings}
          />
        </div>
        <IonRow className="ion-justify-content-center">
          <IonButton size="large" onClick={() => handleSubmit()}>
            Submit
          </IonButton>
        </IonRow>
      </div>
      <EnergyStarTaskProgressModal
        task_id={task}
        opened={opened}
        setOpened={setOpened}
        requestType={requestType}
        reset={resetResults}
      />
    </div>
  );
};

export default EnergyStarDataRequestForm;
