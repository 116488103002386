import {
    IonAccordion,
    IonAccordionGroup,
    IonItem,
    IonLabel,
  } from "@ionic/react";
import React from 'react'

interface Props {
    data: any;
}

const CertificationResults:React.FC<Props> = ({data}) => {
  return <div>
    <IonAccordionGroup>
        {data.map((row:any, i: Number) => {
          return (
            <IonAccordion key={`cost-avoidance-${i}`}>
              <IonItem slot="header">
                <IonLabel>
                  {row.status === "Failed" ? (
                    <span style={{ color: "red" }}> Error </span>
                  ) : (
                    <span style={{ color: "green" }}> {row.status} </span>
                  )}{" "}
                  : {row.building}
                </IonLabel>
              </IonItem>
              <div slot="content" style={{ color: "black" }}>
                {row.status === "Failed" ? (
                  <p style={{ color: "red" }}>{row.data}</p>
                ) : (
                  <div style={{ color: "black" }}>
                    <ul>
                        <li>Last Approval Date: {row.data.lastApproval}</li>
                        <li>Next Eligible Date: {row.data.nextEligible}</li>
                        {row.data.scores.map((s:any)=>
                            <li key={s.year}>{s.year}({s.score})</li>
                        )}
                    </ul>
                  </div>
                )}
              </div>
            </IonAccordion>
          );
        })}
      </IonAccordionGroup>
  </div>
}

export default CertificationResults