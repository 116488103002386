import { IonCol, IonItem, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { useEffect, useState } from "react"
import AE2Input from "../../elements/AE2Input"

interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
poolSize : "",
poolSize_date : "",
poolLocation : "",
poolLocation_date : "",
monthsInUse : "",
monthsInUse_date : "",

}
const startErrors = {
poolSize : "",
poolSize_date : "",
poolLocation : "",
poolLocation_date : "",
monthsInUse : "",
monthsInUse_date : "",
}


const SwimmingPool: React.FC<Props> = ({setParentForm,formErrors}) => {

    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('poolSize'):
                if (value === ''){
                    form.poolSize_date === "" ? handleError('poolSize_date','Date cannot be empty') : handleError('poolSize_date','')
                    handleError('poolSize','Select  pool size')
                }
                else {
                    handleError('poolSize','')
                }
                break;
            case ('poolSize_date'):
                if (value === ''){
                    handleError('poolSize_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('poolSize_date','Invalid Date')
                }
                else {
                    handleError('poolSize_date','')
                }
                break;
            case('monthsInUse'):
                if(value !==''){
                    form.monthsInUse_date === '' ? handleError('monthsInUse_date','Date cannot be empty') : handleError('monthsInUse_date','')
                    value < 0 ? handleError('monthsInUse','Must be a Positive number') : value > 12 ? handleError('monthsInUse','Must be less than 12'): handleError('monthsInUse','')
                }
                else{
                    handleError('monthsInUse','')
                    handleError('monthsInUse_date','')
                }
                break;
            case('monthsInUse_date'):
                if(value !==''){
                    form.monthsInUse === '' ? handleError('monthsInUse','Field cannot be empty') : handleError('monthsInUse','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('monthsInUse_date','Invalid Date') : handleError('monthsInUse_date','')
                }
                else{
                    handleError('monthsInUse','')
                    handleError('monthsInUse_date','')
                }
                break;
            case('poolLocation'):
                if(value !==''){
                    form.poolLocation_date === '' ? handleError('poolLocation_date','Date cannot be empty') : handleError('poolLocation_date','')
                }
                else{
                    handleError('poolLocation','')
                    handleError('poolLocation_date','')
                }
                break;
            case('poolLocation_date'):
                if(value !==''){
                    form.poolLocation === '' ? handleError('poolLocation','Field cannot be empty') : handleError('poolLocation','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('poolLocation_date','Invalid Date') : handleError('poolLocation_date','')
                }
                else{
                    handleError('poolLocation','')
                    handleError('poolLocation_date','')
                }
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <h1>Swimming Pool Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect class="Pool Size" className={errors.poolSize === '' ? "valid":'invalid'} value={form.poolSize} interface='popover' placeholder='Pool Size' onIonChange={e => (handleFormChange(e.detail.value!,'poolSize'))}>
                            <IonSelectOption>Recreational (20 yards x 15 yards)</IonSelectOption>
                            <IonSelectOption>Short Course (25 yards x 20 yards)</IonSelectOption>
                            <IonSelectOption>Olympic (50 meters x 25 meters)</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.poolSize}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.poolSize_date} value={form.poolSize_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'poolSize_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Pool Location" className={errors.poolLocation === '' ? "valid":'invalid'} value={form.poolLocation} interface='popover' placeholder='Pool Location' onIonChange={e => (handleFormChange(e.detail.value!,'poolLocation'))}>
                            <IonSelectOption>Indoor</IonSelectOption>
                            <IonSelectOption>Outdoor</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.poolLocation}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.poolLocation_date} value={form.poolLocation_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'poolLocation_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Number of Months in Use" type="number" errorText={errors.monthsInUse}  value={form.monthsInUse} placeholder='# Months In Use' onIonInput={e => (handleFormChange(e.detail.value!,'monthsInUse'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.monthsInUse_date}  value={form.monthsInUse_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'monthsInUse_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default SwimmingPool