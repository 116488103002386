import { IonContent, IonInput, IonItem, IonLabel, IonList, IonPopover} from "@ionic/react"
import { useState } from "react"
import './SearchSelectBuildingInput.css'
import { SimpleBuildingMeters } from "../../interfaces/SimpleBuildingMeters"

/* Component used to render a select input for buildings in AE2. It is grouped by customer.
The customer name is used for the title and followed by the list of buildings that are
associated with the customer. */

interface Props{
    dict:any,
    label?:string,
    dict_key?:string,
    value_key?:string,
    handleFormChange:any,
    errors:any,
    form:any,
    formName:string,
    placeholder:string,
    disabled?:boolean,
    def_value?:string
}

const clickInput = (e:any) => {
    if (e.detail.relatedTarget !==null){
        e.target.click()

    }
}


/**
 *
 * @param dict - list of dicts
 * @param dict_key - key used to get the value for the form
 * @param value - key for value that will be displayed to the user
 * @param errors - any errors to show set to "" for no errors
 * @param form - used to save the value
 * @param handleFormChange - function to handle keyboard input
 * @param formName - name of the value used in the form
 * @param placeholder - placeholder value for input
 * @param disabled - disable input, default set to false
 * @param def_value - value for the default selection set to 'None'
 * @returns input and selections
 */
const SearchSelectBuildingInput:React.FC<Props> = ({dict,label,dict_key='id',value_key='name',errors,form,handleFormChange,formName,placeholder,disabled=false,def_value='None'})=>{

    const [input, setInput] = useState('')

    return<>
        <IonInput id={`${formName}_input`} aria-labelledby={label} label={label} labelPlacement="floating" disabled={disabled} className={errors === '' ? "valid":'ion-invalid ion-touched'} errorText={errors}  onIonFocus={(e)=>{clickInput(e)}} value={input} placeholder={placeholder} onIonInput={e => {setInput(e.detail.value!)}}></IonInput>
            <IonPopover className="select-popover" trigger={`${formName}_input`} reference="trigger" dismissOnSelect showBackdrop={false} backdropDismiss keyboardClose={false} size='cover' side='bottom' alignment='center'>
                <IonContent>
                    <IonList>
                    <IonItem key={'none-sel'} button onClick={() => {handleFormChange('',formName);setInput('')}}><IonLabel> {def_value} </IonLabel></IonItem>
                    {
                        dict.filter((filter_customer:any)=> (
                            filter_customer.cust_name.toLowerCase().includes(input.toLowerCase()) ||
                            filter_customer.buildings.some((building:SimpleBuildingMeters) => building.name.toLowerCase().includes(input.toLowerCase()))
                            )).map((customer:any) =>{
                            return (<div key={customer.uuid}>
                                <IonItem className="header-list">
                                    {customer.cust_name}
                                </IonItem>
                                {customer.buildings.filter((building:any)=>(
                                    customer.cust_name.toLowerCase().includes(input.toLowerCase()) ||
                                    building.name.toLowerCase().includes(input.toLowerCase())
                                )
                                ).map((building:SimpleBuildingMeters)=>{
                                    return (
                                        <IonItem className="building-select-item" key={building.id} onClick={() => {handleFormChange(building[dict_key as keyof SimpleBuildingMeters],formName);setInput(building[value_key as keyof SimpleBuildingMeters] as string)}}>
                                            {building.name}
                                        </IonItem>
                                    )
                                    })}
                            </div>)
                        })
                    }
                    </IonList>
                </IonContent>
        </IonPopover>
    </>
}

export default SearchSelectBuildingInput

