import { IonBadge, IonIcon, IonItem, IonLabel } from '@ionic/react'
import { businessOutline } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import { API_URL } from '../../../actions/settings'
import usePermissionGroups from '../../../utils/usePermissionGroups'

async function getBuildings(token:any) {

    return fetch(`${API_URL}/imported_buildings`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

const NewBuildingsButton = () => {
    const [buildings,setBuildings] = useState<any>([])
    const {hasPermission} = usePermissionGroups()
    const valid_permissions = hasPermission(['ViewEditImportedBuildings'])

    useEffect(() => {
        const handleData = async () => {
        const token = `Token ${(localStorage.getItem("token")||'').slice(1, -1)}`;
        const res = await getBuildings(token)
        if(res.status === 'success'){
            setBuildings(res.data)
        }else{
            setBuildings([])
        }
        };
        if(valid_permissions){
            handleData();
        }

      }, [valid_permissions]);

      if(!valid_permissions){
        return null
      }



  return <IonItem button href="/account?tab=imported_buildings">
        <IonIcon slot="start" ios={businessOutline} md={businessOutline} />
        <IonLabel>New Buildings</IonLabel>
        {buildings.length > 0 ? <IonBadge color={"warning"}>{buildings.length}</IonBadge> : ''}
    </IonItem>
}

export default NewBuildingsButton