
import { IonButton, IonCheckbox, IonCol, IonContent, IonInput, IonItem, IonLabel, IonList, IonLoading, IonPopover, IonRow, IonSelect, IonSelectOption, IonTextarea, useIonAlert } from "@ionic/react"
import { useEffect, useState } from "react"
import { Redirect, useHistory, useParams } from "react-router"
import { API_URL, REACT_APP_GOOGLE_MAPS_KEY } from "../../actions/settings"
import './PropertyForm.css'
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import SearchSelectInput from "../extras/SearchSelectInput"
import IDSearchSelectInput from "../extras/IDSearchSelectInput"
import CheckRole from "../extras/CheckRole"
import NotificationPopUp from "../extras/NotificationPopUp"
import useForm from "../../utils/useForm"
import useHandleState from "../../utils/useHandleState"
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"

interface Params {
    propertyId:string
}

interface types {
    uuid:any,
    property_type:number,
    sub_type_name:string

}

// interface apiRes {
//     pk: string,
//     fields: any
// }

async function editBuilding(data:any,token:string) {
    return fetch(`${API_URL}/buildings`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        },
        body: JSON.stringify(data)

      })
        .then(data => data.json())
     }

async function getAllCampuses(token:any) {


        return fetch(`${API_URL}/campuses`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
          }

        })
          .then(data => data.json())
       }


async function getAllCustomers(token:any) {


        return fetch(`${API_URL}/customers`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
          }

        })
          .then(data => data.json())
       }


async function getAllPrimaryFunctions(token:any) {


        return fetch(`${API_URL}/energystar/primaryfunctions`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
          }

        })
          .then(data => data.json())
       }
async function getPropertyInfo(token:any,id:any) {


        return fetch(`${API_URL}/energystar/property/${id}/form_info`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
          }

        })
          .then(data => data.json())
       }

async function getAllSubTypes(token:any) {


        return fetch(`${API_URL}/subtypes`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
          }

        })
          .then(data => data.json())
       }

const county_choices:any = {
    'IL': [
        ['Adams',	'Adams'],['Alexander',	'Alexander'],['Bond',	'Bond'],['Boone',	'Boone'],['Brown',	'Brown'],['Bureau',	'Bureau'],['Calhoun',	'Calhoun'],['Carroll',	'Carroll'],['Cass',	'Cass'],['Champaign',	'Champaign'],['Christian',	'Christian'],['Clark',	'Clark'],['Clay',	'Clay'],['Clinton',	'Clinton'],['Coles',	'Coles'],['Cook',	'Cook'],['Crawford',	'Crawford'],['Cumberland',	'Cumberland'],['DeKalb',	'DeKalb'],['DeWitt',	'DeWitt'],['Douglas',	'Douglas'],['DuPage',	'DuPage'],['Edgar',	'Edgar'],['Edwards',	'Edwards'],['Effingham',	'Effingham'],['Fayette',	'Fayette'],['Ford',	'Ford'],['Franklin',	'Franklin'],['Fulton',	'Fulton'],['Gallatin',	'Gallatin'],
        ['Greene',	'Greene'],['Grundy',	'Grundy'],['Hamilton',	'Hamilton'],['Hancock',	'Hancock'],['Hardin',	'Hardin'],['Henderson',	'Henderson'],['Henry',	'Henry'],['Iroquois',	'Iroquois'],['Jackson',	'Jackson'],['Jasper',	'Jasper'],['Jefferson',	'Jefferson'],['Jersey',	'Jersey'],['Jo Daviess',	'Jo Daviess'],['Johnson',	'Johnson'],['Kane',	'Kane'],['Kankakee',	'Kankakee'],['Kendall',	'Kendall'],['Knox',	'Knox'],['Lake',	'Lake'],['LaSalle',	'LaSalle'],['Lawrence',	'Lawrence'],['Lee',	'Lee'],['Livingston',	'Livingston'],['Logan',	'Logan'],['Macon',	'Macon'],['Macoupin',	'Macoupin'],['Madison',	'Madison'],['Marion',	'Marion'],['Marshall',	'Marshall'],['Mason',	'Mason'],
        ['Massac',	'Massac'],['McDonough',	'McDonough'],['McHenry',	'McHenry'],['McLean',	'McLean'],['Menard',	'Menard'],['Mercer',	'Mercer'],['Monroe',	'Monroe'],['Montgomery',	'Montgomery'],['Morgan',	'Morgan'],['Moultrie',	'Moultrie'],['Ogle',	'Ogle'],['Peoria',	'Peoria'],['Perry',	'Perry'],['Piatt',	'Piatt'],['Pike',	'Pike'],['Pope',	'Pope'],['Pulaski',	'Pulaski'],['Putnam',	'Putnam'],['Randolph',	'Randolph'],['Richland',	'Richland'],['Rock Island',	'Rock Island'],['Saline',	'Saline'],['Sangamon',	'Sangamon'],['Schuyler',	'Schuyler'],['Scott',	'Scott'],['Shelby',	'Shelby'],['St. Clair',	'St. Clair'],['Stark',	'Stark'],['Stephenson',	'Stephenson'],
        ['Tazewell',	'Tazewell'],['Union',	'Union'],['Vermilion',	'Vermilion'],['Wabash',	'Wabash'],['Warren',	'Warren'],['Washington',	'Washington'],['Wayne',	'Wayne'],['White',	'White'],['Whiteside',	'Whiteside'],['Will',	'Will'],['Williamson',	'Williamson'],['Winnebago',	'Winnebago'],['Woodford',	'Woodford']
    ],
    'WI': [
        ['Adams',	'Adams'], ['Ashland',	'Ashland'], ['Barron',	'Barron'], ['Bayfield',	'Bayfield'], ['Brown',	'Brown'], ['Buffalo',	'Buffalo'], ['Burnett',	'Burnett'], ['Calumet',	'Calumet'], ['Chippewa',	'Chippewa'], ['Clark',	'Clark'], ['Columbia',	'Columbia'], ['Crawford',	'Crawford'], ['Dane',	'Dane'], ['Dodge',	'Dodge'], ['Door',	'Door'], ['Douglas',	'Douglas'], ['Dunn',	'Dunn'], ['Eau Claire',	'Eau Claire'], ['Florence',	'Florence'], ['Fond du Lac',	'Fond du Lac'], ['Forest',	'Forest'], ['Grant',	'Grant'], ['Green',	'Green'], ['Green Lake',	'Green Lake'], ['Iowa',	'Iowa'], ['Iron',	'Iron'], ['Jackson',	'Jackson'], ['Jefferson',	'Jefferson'],
        ['Juneau',	'Juneau'], ['Kenosha',	'Kenosha'], ['Kewaunee',	'Kewaunee'], ['La Crosse',	'La Crosse'], ['Lafayette',	'Lafayette'], ['Langlade',	'Langlade'], ['Lincoln',	'Lincoln'], ['Manitowoc',	'Manitowoc'], ['Marathon',	'Marathon'], ['Marinette',	'Marinette'], ['Marquette',	'Marquette'], ['Menominee',	'Menominee'], ['Milwaukee',	'Milwaukee'], ['Monroe',	'Monroe'], ['Oconto',	'Oconto'], ['Oneida',	'Oneida'], ['Outagamie',	'Outagamie'], ['Ozaukee',	'Ozaukee'], ['Pepin',	'Pepin'], ['Pierce',	'Pierce'], ['Polk',	'Polk'], ['Portage',	'Portage'], ['Price',	'Price'], ['Racine',	'Racine'], ['Richland',	'Richland'], ['Rock',	'Rock'], ['Rusk',	'Rusk'],
        ['Sauk',	'Sauk'], ['Sawyer',	'Sawyer'], ['Shawano',	'Shawano'], ['Sheboygan',	'Sheboygan'], ['St. Croix',	'St. Croix'], ['Taylor',	'Taylor'], ['Trempealeau',	'Trempealeau'], ['Vernon',	'Vernon'], ['Vilas',	'Vilas'], ['Walworth',	'Walworth'], ['Washburn',	'Washburn'], ['Washington',	'Washington'], ['Waukesha',	'Waukesha'], ['Waupaca',	'Waupaca'], ['Waushara',	'Waushara'], ['Winnebago',	'Winnebago'], ['Wood',	'Wood']
    ]
}

const construction_choices:string[][] =[
    ['Existing','Existing'],['Project','Project'],['Test','Test']
]

const occupancy_choices:string[][]=[
    ['100','100'],['95','95'],['90','90'],['85','85'],['80','80'],['75','75'],['70','70'],['65','65'],['60','60'],['55','55'],
    ['50','50'],['45','45'],['40','40'],['35','35'],['30','30'],['25','25'],['20','20'],['15','15'],['10','10'],['5','5'],['0','0']
]

const state_choices:string[][] = [
    ['IL', "Illinois"], ['WI', 'Wisconsin'],
    ['AL', 'Alabama'], ['AR', 'Arkansas'], ['AS', 'American Samoa'], ['AZ', 'Arizona'], ['CA', 'California'], ['CO', 'Colorado'],
    ['CT', 'Connecticut'], ['DC', 'District of Columbia'], ['DE', 'Delaware'], ['FL', 'Florida'], ['GA', 'Georgia'], ['GU', 'Guam'],
    ['HI', 'Hawaii'], ['IA', 'Iowa'], ['ID', 'Idaho'], ['IN', 'Indiana'], ['KS', 'Kansas'], ['KY', 'Kentucky'], ['LA', 'Louisiana'], ['MA', 'Massachusetts'],
    ['MD', 'Maryland'], ['ME', 'Maine'], ['MH', 'Marshall Islands'], ['MI', 'Michigan'], ['MN', 'Minnesota'], ['MO', 'Missouri'], ['MP', 'Northern Mariana Islands'],
    ['MS', 'Mississippi'], ['MT', 'Montana'], ['NC', 'North Carolina'], ['ND', 'North Dakota'], ['NE', 'Nebraska'], ['NH', 'New Hampshire'], ['NJ', 'New Jersey'],
    ['NN', 'Navajo Nation'], ['NV', 'Nevada'], ['NY', 'New York'], ['OH', 'Ohio'], ['OK', 'Oklahoma'], ['OR', 'Oregon'], ['PA', 'Pennsylvania'], ['PI', 'Pacific Island'],
    ['PR', 'Puerto Rico'], ['RI', 'Rhode Island'], ['SC', 'South Carolina'], ['SD', 'South Dakota'], ['TN', 'Tennessee'], ['TT', 'Trust Territories'],
    ['TX', 'Texas'], ['UM', 'U.S. Minor Outlying Islands'], ['UT', 'Utah'], ['VA', 'Virginia'], ['VI', 'Virgin Islands'], ['VT', 'Vermont'], ['WA', 'Washington'],
    ['WQ', 'Wake Island'], ['WV', 'West Virginia'], ['WY', 'Wyoming'], ['AB', 'Alberta'], ['BC', 'British Columbia'], ['MB', 'Manitoba'], ['NB', 'New Brunswick'],
    ['NL', 'Newfoundland'], ['NS', 'Nova Scotia'], ['NT', 'Northern Territories'], ['NU', 'Nunavut'], ['ON', 'Ontario'], ['PE', 'Prince Edward Island'],
    ['QC', 'Quebec'], ['SK', 'Saskatchewan'], ['YT', 'Yukon']]

const country_choices:string[][]=[
    ['US','United States'],['CA','Canada']
]

const getInfo = async (token:any, propertyId:any, setLoaded:any, setForm:any) => {
    const property = await getPropertyInfo(`Token ${token.slice(1,-1)}`,propertyId)
    setForm(
        {
            estar_id:property.data.property_id,
            cust_uuid:property.data.ae2_cust_id,
            campus_uuid: property.ae2 ? property.ae2.campus_uuid ? property.ae2.campus_uuid  : "" : "",
            building_name: property.data.name!,
            building_description: property.ae2 ? property.ae2.description : "",
            building_address: property.data.address,
            building_address_2: property.data.address2 || '',
            building_city: property.data.city,
            building_state: property.data.state,
            building_zipcode: property.data.postalcode,
            building_county: property.ae2 ? property.ae2.building_county : "",
            building_sq_ft: property.data.grossfloorarea,
            building_type: property.data.primaryfunction.id,
            building_sub_type:  property.ae2 && property.ae2.building_sub_type ? property.ae2.building_sub_type : "",
            building_incentive: property.ae2 ? property.ae2.building_incentive : null,
            edit_estar: true,
            edit_ae2: true,
            in_estar: true,
            in_ae2: property.ae2 ? true : false,
            estar_country:"US",
            estar_year_built:property.data.yearbuilt,
            estar_construction_status:property.data.constructionstatus,
            estar_occupancy:String(property.data.occupancypercentage),
            estar_is_federal_property: property.data.isfederalproperty,
            estar_federal_owner:property.data.federalowner,
            number_of_buildings:property.data.numberofbuildings
        }
    )
}

const EditEstarProperty: React.FC = () =>{

    const history = useHistory()
    const { propertyId } = useParams<Params>();
    const [loaded, setLoaded] = useState(false)


    const {
        placesService,
        placePredictions,
        getPlacePredictions,
      } = usePlacesService({
        apiKey: REACT_APP_GOOGLE_MAPS_KEY,
      });

      const handlePlace = (item:any) => {
        placesService?.getDetails(
            {
              placeId: item.place_id,
            },
            (placeDetails:any) => {
                handleFormChange(placeDetails.address_components.filter((r:any) => r.types.includes('postal_code'))[0].long_name,'building_zipcode');
                handleFormChange(
                    `${placeDetails.address_components.filter((r:any) => r.types.includes('street_number'))[0].long_name} ${placeDetails.address_components.filter((r:any) => r.types.includes('route'))[0].short_name}`,'building_address');
                handleFormChange(placeDetails.address_components.filter((r:any) => r.types.includes('locality'))[0].long_name,'building_city');
                handleFormChange(placeDetails.address_components.filter((r:any) => r.types.includes('administrative_area_level_1'))[0].short_name,'building_state');
                }
          );

    }

    const startingForm = {
        cust_uuid:null,
        campus_uuid: "",
        building_name: null,
        building_description: "",
        building_address: null,
        building_address_2: null,
        building_city: null,
        building_state: "",
        building_zipcode: null,
        building_county: "",
        building_sq_ft: null,
        building_type: "",
        building_sub_type: null,
        building_incentive:null,
        edit_estar: true,
        edit_ae2: true,
        in_estar: false,
        in_ae2: false,
        estar_country:"US",
        estar_year_built:null,
        estar_construction_status:"",
        estar_occupancy:"",
        estar_is_federal_property: false,
        estar_federal_owner:null,
        number_of_buildings:1

    }
    const startingErrors:any = {
        campus_uuid:'',
        building_name : '',
        building_description:'',
        building_address: '',
        building_address_2: '',
        building_city: "",
        building_state: "",
        building_zipcode: "",
        building_county:"",
        building_sq_ft:"",
        building_type:"",
        building_sub_type:"",
        building_incentive:"",
        edit_estar:"",
        edit_ae2:"",
        in_estar:"",
        estar_country:"",
        estar_year_built:"",
        estar_construction_status:"",
        estar_occupancy:"",
        estar_is_federal_owner:"",
        estar_federal_owner:"",
        number_of_buildings:""
    }

    const validate = (name:string, val:any) => {
        const regExp = /[a-zA-Z]/g;
        switch (name) {
            case 'building_name':
                if(val === null || val.length <=1){
                    return {building_name:'Name Must Be longer than 2 letters'}
                }
                else{
                    return {building_name:''}
                }

            case 'building_address':
                if(val === null || val.length === 0 || val === " " ){
                    return {building_address:'Address cannot be empty'}
                }
                else{
                    return {building_address:''}
                }
            case 'building_city':
                if(val === null || val.length === 0 ){
                    return {building_city:'City cannot be empty'}
                }
                else{
                    return {building_city:''}
                }

            case 'building_state':
                if(val === null || val.length === 0 ){
                    return {building_state:'Select A State'}
                }
                else if(!state_choices.some(st=> st.includes(val)) || val.length >=3  ){
                    return {building_state:'Select State From The List'}
                }
                else{
                    return {building_state:''}
                }
            case 'building_county':
                if(form.in_ae2 && ( val === null || val.length === 0) ){
                    return {building_county:'County Cannot be Empty'}
                }
                else{
                    return {building_county:''}
                }
            case 'building_zipcode':
                if(val === null || val.length === 0 ){
                    return {building_zipcode:'Zip Code cannot be empty'}

                }
                else if (regExp.test(val)){
                    return {building_zipcode:'Zip Code cannot contain letters'}
                }
                else{
                    return {building_zipcode:''}
                }
            case 'building_sq_ft':
                if (val === null || val < 0){
                    return {building_sq_ft:'Building Square Feet must be greater than 0'}
                }else{
                    return {building_sq_ft:''}
                }
            case 'building_sub_type':
                val = form.building_sub_type
                if(Number(val) === 30 || Number(val) === 48){
                    return {building_sub_type:"Select Sub Type"}
                }else{
                    return {building_sub_type:''}
                }
            case 'building_type':
                if(val === null || val === ''){
                    return {building_type:"Select Property Type"}
                }
                else if( primaryfunctions && !primaryfunctions.some((st:any)=> st.id === val)  ){
                    return {building_type:'Select Property Type From List'}
                }
                else{
                    return {building_type:''}
                }
            case 'campus_uuid':
                if(val === null || val === ''){
                    return {campus_uuid:""}
                }
                else if( campuses && !campuses.some((st:any)=> st.pk === val)  ){
                    return {campus_uuid:'Select Campus From List'}
                }
                else{
                    return {campus_uuid:''}
                }
            case 'estar_year_built':
                if(form.edit_estar && (val === null || val < 1700 || val > getCurrentYear() )){
                    return {estar_year_built:"Enter a valid year "}
                }else{
                    return {estar_year_built:''}
                }
            case 'estar_country':
                if(form.edit_estar && (val === '' || val === null)){
                    return {estar_country:"Country cannot be empty "}
                }
                else if( form.edit_estar && (!country_choices.some(st=> st.includes(val)) || val.length >=3)  ){
                    return {estar_country:'Select Country From The List'}
                }
                else{
                    return {estar_country:''}
                }
            case 'estar_construction_status':
                if(form.edit_estar && (val === null || val === '') ){
                    return {estar_construction_status:"Construction Status cannot be empty."}
                }
                else if( form.edit_estar && (!construction_choices.some(st=> st.includes(val)))  ){
                        return {estar_construction_status:'Select Construction Status From The List'}
                    }
                else{
                    return {estar_construction_status:''}
                }
            case 'estar_occupancy':
                if(form.edit_estar && val === null ){
                    return {estar_occupancy:"Occupancy cannot be empty."}
                }
                else if( form.edit_estar && (!occupancy_choices.some(st=> st.includes(val)) || val.length >=4)  ){
                    return {estar_occupancy:'Select Percentage From The List'}
                }else{
                    return {estar_occupancy:''}
                }
            case 'number_of_buildings':
                if(form.edit_estar && (val === null || val < 1) ){
                    return {number_of_buildings:"Number of Buildings must be 1 or greater"}
                }else{
                    return {number_of_buildings:''}
                }
            case 'estar_federal_owner':
                if(form.estar_is_federal_property && val === null ){
                    return {estar_federal_owner:"Federal owner cannot be empty."}
                }else{
                    return {estar_federal_owner:''}
                }

            default:
                break;
        }
    }

    const user = useAppSelector(selectUser)
    const {form, handleFormChange, errors, resetForm, validateForm, loadForm } = useForm(startingForm,startingErrors,validate)
    const [campuses, handleCampuses] = useHandleState([])
    const [primaryfunctions, handlePrimaryFunctions] = useHandleState([],true)
    const [subTypes, handleSubtypes] = useHandleState([],true)
    const [customers, handleCustomers] = useHandleState([])
    const [confirmChange]  = useIonAlert()

    useEffect(() => {
        const token = localStorage.getItem('token')
        handleCampuses(token,getAllCampuses)
        handleCustomers(token,getAllCustomers)
        handlePrimaryFunctions(token,getAllPrimaryFunctions)
        handleSubtypes(token,getAllSubTypes)
        getInfo(token, propertyId, setLoaded, loadForm)
        setLoaded(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [propertyId])

    const handleSubmit = async () => {
        const token:any = localStorage.getItem('token')
        const valid = validateForm() || false
        // form.cust_uuid = id
        if(valid){
        const res = await editBuilding(form,`Token ${token.slice(1,-1)}`)
        if (res.status === "success"){
            resetForm()
            history.push(`/energystar/properties/${propertyId}`)
            NotificationPopUp("success","Property Edited")
        }

        else{
            NotificationPopUp('error',res.detail||res.ERROR,res.error||"Error")
        }
        }
    }

    const getCurrentYear = () => {
        return new Date().getFullYear();
      };

      if (!loaded || !user.username){
        return(
            <IonLoading
        cssClass='my-custom-class'
        isOpen={!loaded}
        message={'Loading...'}
        spinner='bubbles'
      />
        )
    }

    if(!CheckRole(user,['Admin','Supervisor'])){
        return <Redirect to="/403"/>
    }

    const clickAddress = (input:any,e:any) => {
        if (input.length === 1){
            e.target.click()
        }
    }

    return (

        <form>
            <IonRow className="form-title-row" ><h2>Edit Property</h2> <IonButton className="cancel-btn" size="small"  color="danger" href={`/energystar/properties/${propertyId}`}> Cancel </IonButton></IonRow>
            <IonItem hidden={!form.in_ae2}>
                <IonCheckbox checked={form.edit_ae2} mode='ios' slot="start" aria-label='edit AE2' onIonChange={e => (handleFormChange(e.detail.checked,'edit_ae2'))} >
                Edit in AE2
                </IonCheckbox>
            </IonItem>
            <IonItem hidden={user.role.name !== 'Admin'}>
                <div style={{width:'100%'}}>
                <IonSelect label="Customer Associated With this Property" labelPlacement="floating" className={errors.campus_uuid === '' ? "valid" : "invalid"} interface='popover' aria-label="Customer" value={form.cust_uuid} placeholder="Campus" onIonChange={(e) =>  form.cust_uuid === null || e.detail.value! === null ? " ": confirmChange({
                    cssClass: 'alert-css',
                    header: "Change Customer?",
                    message: "Are you sure you want to change the customer associated with this property",
                    buttons: [
                        {text:'Cancel', handler: (i = e.detail.value!) => (handleFormChange(form.cust_uuid,'cust_uuid'))},
                        {text: 'Change', handler:(id=e.detail.value!) => (handleFormChange(id,'cust_uuid'))}],
                })}>
                        {customers.map((customer:any) => {
                            return (
                                <IonSelectOption key={customer.pk} value={customer.pk}>{customer.cust_name}</IonSelectOption>
                            )
                        })}
                        </IonSelect>
                        <div className="error-detail">{errors.campus_uuid}</div>

                </div>
            </IonItem>
            <IonItem lines="none">
                <IonInput label='Building Name' labelPlacement='floating' className={errors.building_name === '' ? "valid":'invalid'} errorText={errors.building_name} aria-labelledby="Building Name" value={form.building_name} placeholder="Name" onIonInput={e => ( handleFormChange(e.detail.value!,'building_name'))}> </IonInput>
            </IonItem>
            <IonItem lines="none" hidden={!form.in_ae2} disabled={!form.edit_ae2}>
                <IonTextarea label='building Description' labelPlacement='floating' className={errors.building_description === '' ? "valid":'ion-invalid ion-touched'} errorText={errors.building_description} aria-labelledby="Building Description" auto-grow value={form.building_description} placeholder='Description' onIonInput={e => (handleFormChange(e.detail.value!,'building_description'))}> </IonTextarea>
            </IonItem>
            <IonItem lines="none">
                <IonRow className="address-row">
                    <IonCol size="6">
                        <IonInput label='Address' labelPlacement='floating' id="address" className={errors.building_address === '' ? "valid":'invalid'} errorText={errors.building_address} aria-labelledby="Address" value={form.building_address} placeholder="Address" onIonInput={e => {handleFormChange(e.detail.value!,'building_address'); getPlacePredictions({input:e.detail.value!}); clickAddress(e.detail.value!,e)}}> </IonInput>
                        <IonPopover trigger="address" className='address-list' reference="trigger" dismissOnSelect showBackdrop={false} keyboardClose={false} size='cover'>
                            <IonContent>
                                <IonList>
                        {placePredictions.map((item) => <IonItem className="address-item" button key={item.place_id} onClick={() => handlePlace(item)}><IonLabel color="dark">{item.description}</IonLabel></IonItem>)}
                        </IonList></IonContent>
                        </IonPopover>
                    </IonCol>
                    <IonCol size="6">
                        <IonInput label="Address 2" labelPlacement="floating" className={errors.building_address_2 === '' ? "valid":'invalid'} errorText={errors.building_address_2} aria-labelledby="Address 2" value={form.building_address_2} placeholder="Address 2" onIonInput={e => (handleFormChange(e.detail.value!,'building_address_2'))}> </IonInput>
                    </IonCol>
                </IonRow>
            </IonItem>
                <IonRow className="address-row-2">
                    <IonCol>
                        <IonInput label="City" labelPlacement="floating" className={errors.building_city === '' ? "valid":'invalid'} errorText={errors.building_city} value={form.building_city} aria-labelledby="City" placeholder="City" onIonInput={e => (handleFormChange(e.detail.value!,'building_city'))}> </IonInput>
                    </IonCol>
                    <IonCol>
                    <SearchSelectInput list={state_choices} errors={errors.building_state} label="State" form={form.building_state} handleFormChange={handleFormChange} formName={'building_state'} placeholder={'State'}/>
                    </IonCol>
                    <IonCol>
                        <IonInput label="Zip Code" labelPlacement="floating" className={errors.building_zipcode === '' ? "valid":'invalid'} errorText={errors.building_zipcode} aria-labelledby="Zip Code" value={form.building_zipcode} placeholder="Zip Code" onIonInput={e => (handleFormChange(e.detail.value!,'building_zipcode'))}> </IonInput>
                    </IonCol>
                    <IonCol hidden={!form.in_ae2}>
                        { form.building_state === 'IL' || form.building_state === 'WI' ?
                        <IonItem lines="none" disabled={!form.edit_ae2}>
                            <SearchSelectInput  list={county_choices[form.building_state]} label="County" errors={errors.building_county} form={form.building_county} handleFormChange={handleFormChange} formName={'building_county'} placeholder={'County'}/>
                        </IonItem>
                        :
                        <IonInput label="County" labelPlacement="floating" disabled={!form.edit_ae2} className={errors.building_county === '' ? "valid":'invalid'} errorText={errors.building_county} aria-labelledby="County" value={form.building_county} placeholder='County' onIonInput={e => (handleFormChange(e.detail.value!,'building_county'))}> </IonInput>
                        }

                    </IonCol>
                </IonRow>

            <IonRow>
                <IonCol>
                    <IonItem lines='none'>
                        <IonInput label='Gross Floor Area' labelPlacement='floating' className={errors.building_sq_ft === '' ? "valid":'invalid'} errorText={errors.building_sq_ft} type="number" aria-labelledby="Gross Floor Area" value={form.building_sq_ft} placeholder='Gross Floor Area' onIonInput={e => (handleFormChange(e.detail.value!,'building_sq_ft'))}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol hidden={!form.in_ae2} >
                    <IonItem lines="none" disabled={!form.in_ae2}>
                        <IDSearchSelectInput dict={campuses.filter((c:any)=>c.cust_uuid === form.cust_uuid)} label="Campus Associated With This Building" dict_key={'pk'} value={'campus_name'} errors={errors.campus_uuid} form={form.campus_uuid} handleFormChange={handleFormChange} formName={'campus_uuid'} placeholder={'Campus'}/>
                    </IonItem>
                </IonCol>
                <IonCol hidden={!form.in_ae2}>
                    <IonItem>
                        <IonInput label='Incentive' labelPlacement='floating' disabled={!form.edit_ae2} className={errors.building_incentive === '' ? "valid":'invalid'} errorText={errors.building_incentive} aria-labelledby="Incentive" type="number" value={form.building_incentive} onIonInput={e=>(handleFormChange(e.detail.value!,'building_incentive'))}></IonInput>
                    </IonItem>
                </IonCol>
                </IonRow>
                <IonRow>
                <IonCol>
                    <IonItem lines="none">
                        <IDSearchSelectInput dict={primaryfunctions} dict_key={'id'} value={'name'} label='Primary Function' errors={errors.building_type} form={form.building_type} handleFormChange={handleFormChange} formName={'building_type'} placeholder={'Primary Function'}/>
                    </IonItem>
                </IonCol>
                {Number(form.building_type) === 30 || Number(form.building_type) === 48 ?
                <IonCol hidden={!form.in_ae2}>
                    <IonItem>

                        <IonSelect label='Sub-Type' labelPlacement='floating' disabled={!form.edit_ae2} className={errors.building_sub_type === '' ? "valid":'invalid'} aria-label="Sub-Type" interface='popover' value={form.building_sub_type} placeholder="Sub Property Type" onIonChange={e => (handleFormChange(e.detail.value!,'building_sub_type'))}>
                            {subTypes.map((item:types,index:any) => {

                               if(item.property_type === Number(form.building_type)){
                                    return(<IonSelectOption key={index} value={item.uuid}>{item.sub_type_name}</IonSelectOption>)
                                }
                                else {
                                    return null
                                }

                            })}
                            </IonSelect>


                    </IonItem>
                    <div className="error-detail">{errors.building_sub_type}</div>
                </IonCol> : ""}

            </IonRow>
            <IonItem disabled={form.edit_estar}>
                <IonCheckbox checked={form.edit_estar} mode='ios' slot="start" aria-label="Edit in Energy Star" onIonChange={e => (handleFormChange(e.detail.checked,'edit_estar'))}>
                Edit in EnergyStar
                </IonCheckbox>
            </IonItem>

            {/* Energy Start Form Data Needed */}
            { form.edit_estar ? <>

                <h3>Energy Star Form</h3>
                <p>Fill out the rest of the form to add the property to Energy Star.</p>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <SearchSelectInput list={country_choices} errors={errors.estar_country} label='Country' form={form.estar_country} handleFormChange={handleFormChange} formName={'estar_country'} placeholder={'Country'}/>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem lines='none'>
                            <IonInput label='Year built' labelPlacement='floating' className={errors.estar_year_built === '' ? "valid":'invalid'} aria-labelledby="Year Built" type="number" value={form.estar_year_built} placeholder={`${getCurrentYear()}`} max={getCurrentYear()} min={1800} onIonChange={e => (handleFormChange(e.detail.value!,'estar_year_built'))}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem lines='none'>
                            <SearchSelectInput list={construction_choices} errors={errors.estar_construction_status} label="Construction Status" form={form.estar_construction_status} handleFormChange={handleFormChange} formName={'estar_construction_status'} placeholder={'Construction Status'}/>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                    <IonItem lines='none'>
                        <SearchSelectInput list={occupancy_choices} errors={errors.estar_occupancy} form={form.estar_occupancy} label="Occupancy" handleFormChange={handleFormChange} formName={'estar_occupancy'} placeholder={'Occupancy'}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem lines='none'>
                                <IonInput label='Number of Buildings' labelPlacement='floating' className={errors.number_of_buildings === '' ? "valid":'invalid'} errorText={errors.number_of_buildings} aria-labelledby="Number of Buildings" type="number" value={form.number_of_buildings} onIonInput={e => (handleFormChange(e.detail.value!,'number_of_buildings'))}></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem lines="none">
                            <IonCheckbox checked={form.estar_is_federal_property} mode='ios' slot="start" aria-label="Federal Property?" onIonChange={e => (handleFormChange(e.detail.checked,'estar_is_federal_property'))}>
                            Federal Property?
                            </IonCheckbox>
                        </IonItem>
                    </IonCol>
                    {form.estar_is_federal_property ?
                    <IonCol>
                        <IonItem lines="none">
                            <IonSelect className={errors.estar_federal_owner === '' ? "valid":'invalid'} aria-label="Federal Owner" value={form.estar_federal_owner} interface='popover' placeholder='Federal Owner' onIonChange={e => (handleFormChange(e.detail.value!, 'estar_federal_owner'))}>
                                <IonSelectOption value='US'>United States</IonSelectOption>
                                <IonSelectOption value='CA'>Canada</IonSelectOption>
                            </IonSelect>

                        </IonItem>
                        <div className="error-detail">{errors.estar_federal_owner}</div>
                    </IonCol>: undefined}
                </IonRow>


                </>

            : " "}





            <div className="button-container">
            <IonItem className='form-submit-button' button color='primary' onClick={handleSubmit}>
                <IonLabel>
                  Submit
                </IonLabel>
            </IonItem>
            </div>

        </form>
    )
}


export default EditEstarProperty