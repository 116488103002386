import React, { useEffect, useState } from "react";
import { IonCol, IonItem, IonRow } from "@ionic/react";
import { Redirect } from "react-router";
import usePermissionGroups from "../../../utils/usePermissionGroups";
import { API_URL } from "../../../actions/settings";
import { Building } from "../../../interfaces/Building";

async function getBuildings(token:any) {

  return fetch(`${API_URL}/imported_buildings`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }

const ImportedBuildings = () => {
  const {hasPermission} = usePermissionGroups()

  const [buildings,setBuildings] = useState<any>([])

    useEffect(() => {
        const handleData = async () => {
        const token = `Token ${(localStorage.getItem("token")||'').slice(1, -1)}`;
        const res = await getBuildings(token)
        if(res.status === 'success'){
            setBuildings(res.data)
        }else{
            setBuildings([])
        }
        };

        handleData();


      }, []);

  if(!hasPermission(['ViewEditImportedBuildings'])){
    return <Redirect to="/403"/>
  }

  return (
    <IonRow className="ion-justify-content-center">
      <IonCol sizeMd="5">
        <h1 style={{ textAlign: "center" }}>Recently Imported Buildings From Energy Star</h1>
        <h5 style={{textAlign:'center'}}> Edit the Customer, County, Incentive, Fan Cycling, FOA, KPI, and Campus Optimizer</h5>
        {buildings.length > 0 ?
        buildings.map((b:Building)=>{
          return (
          <IonItem key={b.uuid} href={`building/${b.uuid}/edit`}>
            {b.building_name} - {b.building_address}
          </IonItem>)
        }) :
        "No New Buildings"}

      </IonCol>
    </IonRow>
  );
};

export default ImportedBuildings;
