import { IonLabel, IonItem, IonModal, IonTitle, IonButton, IonHeader, IonRow, IonCol, IonSelect, IonSelectOption, IonIcon, IonContent} from "@ionic/react"
import { useEffect, useState } from "react"
import { API_URL } from "../../../../actions/settings"
import NotificationPopUp from "../../../extras/NotificationPopUp"
import useHandleState from "../../../../utils/useHandleState"
import { trashBinOutline } from "ionicons/icons";
import AE2Input from "../../../elements/AE2Input"

interface Props{
    opened:boolean,
    setShowModal:any,
    setDashboards:any,
    // edit:any
}

async function addDashboard(data:any,token:any) {

    return fetch(`${API_URL}/quicksightDashboard`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(data)

    })
      .then(data => data.json())
   }


async function getInputTypes(token:any) {

    return fetch(`${API_URL}/input_types`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },

    })
      .then(data => data.json())
   }


const startForm = {
    'name':"",
    'quicksight_id':"",
    'params':[],
}


const errorsForm:any = {
    'name':"",
    'quicksight_id':"",
    'params':[],
}

const QuicksightDashboardForm:React.FC<Props> = ({opened,setShowModal,setDashboards}) =>{
    const [form, setForm] = useState<any>(startForm)
    const [errors, setErrors] = useState(errorsForm)
    const [types, handleTypes] = useHandleState([])
    // const [toEdit,setToEdit] = edit

    useEffect(()=>{
        const token = localStorage.getItem('token')
        handleTypes(token,getInputTypes)
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const validate = (name:string, val:any,param="",idx:any="") => {
        switch (name) {
            case 'quicksight_id':
                if(val === null || val.length <1){
                    return {quicksight_id:'ID Cannot Be Empty'}
                }
                else{
                    return {quicksight_id:''}
                }
            case 'name':
                if(val === null || val.length <1){
                    return {name:'Dashboard Name Cannot Be Empty'}
                }
                else{
                    return {name:''}
                }
            case 'params':
                if((val == null || val.length <1) && param !==  'choices' ){
                    errors.params[idx][param]='Cannot Be Empty'
                    return {
                    params : [...errors.params]
                    }
                }
                else{
                    errors.params[idx][param]=''
                    return {
                        params : [...errors.params]
                    }
                }
            default:
                break;

        }
    }

    const validateAll = (form:any) => {
        let validation = errorsForm
        let valid = true
        for (let item in form){

            if(item === 'params'){
                for(let idx in form.params){
                    for (let i in form.params[idx]){
                        validation = {...validation, ...validate(item,form['params'][idx][i],i,idx)}
                    }

                }
            }
            else{
            validation = {...validation, ...validate(item,form[item])}
            }

        }
        setErrors({...errors,...validation})

        for(let val in validation){
            if (validation[val] !== '' && val !== 'params'){
                valid = false;
            }

        }
        for( let param in validation['params']){
            for (let item in validation['params'][param]){
                if (validation['params'][param][item] !== ''){
                    valid = false;
                }
            }
        }

        return valid
        }

    const handleFormChange = (value:any,name:string) => {
        setErrors({...errors, ...validate(name,value) })
        setForm((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
    }
    const handleParamFormChange = (index:number,value:any,name:string) => {
        setErrors({...errors, ...validate('params',value,name,index) })
        setForm((prevState:any) => {
            const params = [...form['params']]
            params[index][name]=value
            return {
                ...prevState,
                'params':[...params]
            }
        } )
    }
    const handleOptionFormChange = (p_index:number,c_index:any,value:any,name:string) => {
        setForm((prevState:any) => {
            const params = [...prevState['params']]
            params[p_index]['choices'][c_index][name]=value
            return {
                ...prevState,
                'params':[...params]
            }
        } )
    }

    const handleAddParameter = () =>{
        setForm((prevState:any) =>{
            const params = [...prevState['params']]
            params.push({
                'name':"",
                'display_name':"",
                'type':"",
                'choices':[]
            })
            return {
                ...prevState,
                'params': [...params]
            }
        })
        setErrors((prevState:any) =>{
            const errors = [...prevState['params']]
            errors.push({
                'name':"",
                'display_name':"",
                'type':"",
                'choices':[]
            })
            return {
                ...prevState,
                'params': [...errors]
            }
        })
    }
    const handleAddOption = (index:number) =>{
        setForm((prevState:any) =>{
            let params = [...prevState['params']]
            params[index].choices.push({
                'name':"",
                'value':"",
            })
            return {
                ...prevState,
                'params': [...params]
            }
        })
    }
    const handleDeleteParameter = (index:number) =>{
        setForm((prevState:any) =>{
            return {
                ...prevState,
                'params': form['params'].filter((param:any,i:number)=> i !== index)
            }
        })
        setErrors((prevState:any) =>{
            return {
                ...prevState,
                'params': errors['params'].filter((param:any,i:number)=> i !== index)
            }
        })
    }
    const handleDeleteOption = (index:number,i:number) =>{
        setForm((prevState:any) =>{
            let params = [...prevState['params']]
            params[index].choices = params[index].choices.filter((choice:any,idx:any) => {return idx !== i})
            return {
                ...prevState,
                'params': [...params]
            }
        })
    }

    const handleSubmit = async () =>{
        const valid = validateAll(form)
        if(valid){
            const token = localStorage.getItem('token') || ""
            const res = await addDashboard(form,`Token ${token.slice(1,-1)}`)
            if (res.status === 'success'){
                setForm(startForm)
                setShowModal(false)
                setDashboards((prevState:any)=>{
                    return [...prevState,
                    res.data]
                })
                NotificationPopUp('success','Dashboard Added')
            }
            else{
                NotificationPopUp("Error",res.detail||res.data)
            }
        }
        else{
            console.log('Form Errors',errors)
        }
    }


    return (
        <IonModal style={{"--min-width":'50%','--height':'50%','--max-height':'80%', 'overflowY':'auto'}} id="unitsModal" isOpen={opened} onDidDismiss={()=>{setShowModal(false);setForm(startForm)}}>
                <IonHeader><IonTitle>Add Dashboard</IonTitle></IonHeader>
                <IonContent style={{'--background':'transparent'}}>
                    <form>

                        <IonItem>
                            <AE2Input label='Dashboard Name:' labelPlacement='stacked' errorText={errors.name} value={form.name} placeholder="Name" onIonInput={(e)=> handleFormChange(e.detail.value!,'name')}></AE2Input>
                        </IonItem>
                        <IonItem>
                            <AE2Input label='Quicksight ID:' labelPlacement='stacked' errorText={errors.quicksight_id} value={form.quicksight_id} placeholder="ID" onIonInput={(e)=> handleFormChange(e.detail.value!,'quicksight_id')}></AE2Input>
                        </IonItem>
                        {form['params'].map((input:any,index:number)=>{
                            return (
                            <IonItem key={index}>
                                    <IonButton slot="end" fill="solid" color="danger" onClick={()=>handleDeleteParameter(index)}>
                                        <IonIcon color="dark" src={trashBinOutline}></IonIcon>
                                    </IonButton>
                                    <IonLabel position="stacked">Parameter: </IonLabel>
                                    <IonRow style={{minWidth:'100%'}}>
                                        <IonCol >
                                            <AE2Input  errorText={errors.params[index].name} value={form.params[index].name} placeholder="Name" onIonInput={e => handleParamFormChange(index,e.detail.value!,'name')}></AE2Input>
                                        </IonCol><IonCol>
                                            <AE2Input  errorText={errors.params[index].display_name} value={form.params[index].display_name} placeholder="Display Name" onIonInput={e => handleParamFormChange(index,e.detail.value!,'display_name')}></AE2Input>
                                        </IonCol>
                                    </IonRow>

                                    <IonSelect placeholder="Parameter Type" value={form.params[index].type} onIonChange={e => handleParamFormChange(index,e.detail.value!,'type')}>
                                        {types.map((type:any)=>{
                                            return <IonSelectOption key={type.id} value={type.id} >{type.name}</IonSelectOption>
                                        })}
                                    </IonSelect>
                                    <IonRow hidden={form['params'][index].type !== '0bca5ed6-f59c-46da-bedd-5f49d5a3e27d'} >
                                        {form['params'][index].choices.map((choice:any,i:number) => {
                                            return(
                                                <IonItem key={i}>
                                                    <IonButton slot="end" fill="solid" color="danger" onClick={()=>handleDeleteOption(index,i)}>
                                                        <IonIcon color="dark" src={trashBinOutline}></IonIcon>
                                                    </IonButton>
                                                    <IonLabel position="stacked">Select Option: </IonLabel>
                                                    <IonRow style={{minWidth:'100%'}}>
                                                        <IonCol >
                                                            <AE2Input value={form.params[index]['choices'][i]['name']} placeholder="Name" onIonInput={e => handleOptionFormChange(index,i,e.detail.value!,'name')}></AE2Input>
                                                        </IonCol><IonCol>
                                                            <AE2Input value={form.params[index]['choices'][i]['value']} placeholder="Value" onIonInput={e => handleOptionFormChange(index,i,e.detail.value!,'value')}></AE2Input>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonItem>
                                            )
                                        })}
                                        <IonItem>
                                        <IonButton slot="end" onClick={()=>handleAddOption(index)}>add option</IonButton>
                                        </IonItem>
                                    </IonRow>

                            </IonItem>)
                        })}
                        <IonButton onClick={()=>handleAddParameter()}>Add Param</IonButton>

                    </form>
                    </IonContent>
                    <div className='buttons'>

                    <IonButton onClick={()=>handleSubmit()} >Submit</IonButton>
                    <IonButton onClick={()=>setShowModal(false)} color='danger'>cancel</IonButton>
                    </div>
            </IonModal>
    )
}

export default QuicksightDashboardForm