import { IonAccordion, IonAccordionGroup, IonCol, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, IonTitle } from "@ionic/react"
import { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { API_URL } from "../../../actions/settings"
import { displayValue } from "../../extras/Calculations"
import LoadingSpinner from "../../extras/LoadingSpinner"
import './DegreeDaysTable.css'


interface props {
    id?:any,
    type:any
}

async function getData(token:string,id:any,type:any) {
    return fetch(`${API_URL}/deg_days/${type}/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        }

      })
        .then(data => data.json())
     }
async function getAllData(token:string) {
    return fetch(`${API_URL}/buildings_performance`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        }

      })
        .then(data => data.json())
     }

async function getBuildingTypes(token:any) {

        return fetch(`${API_URL}/energystar/primaryfunctions`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
          }

        })
          .then(data => data.json())
       }


const DegreeDaysTable: React.FC<props> = ({id=false, type}) => {
    const [loaded, setLoaded] = useState(false)
    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    const [types, setTypes] = useState([])
    const [failed, setFailed] = useState(false)

    const handleData = async (id:any) =>{
        const token = localStorage.getItem('token') || ""
        if (id){
            const res = await getData(`Token ${token.slice(1,-1)}`,id,type)
            if(res.status === 'OK'){
                setData(res.data)
                setAllData(res.data)
                setLoaded(true)
            }
            else{
                setLoaded(true)
                setFailed(true)
            }
        }
        else{
            const res = await getAllData(`Token ${token.slice(1,-1)}`)
            if(res.status === 'OK'){
                setData(res.data)
                setAllData(res.data)
                setLoaded(true)
            }
            else{
                setLoaded(true)
                setFailed(true)
            }
            const types = await getBuildingTypes(`Token ${token.slice(1,-1)}`)
            setTypes(types)
        }
    }

    useEffect(()=>{
        handleData(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id])

    if(!loaded){
        return(
            <LoadingSpinner/>
        )
    }
    if(failed){
        return(
            <span>Could Not Load Data.</span>
        )
    }

    const filterType = (type:any) =>{
        if(type === 'all'){
            setData(allData)
            return
        }
        const res = allData.filter((item:any) => item.primaryfunction === type)
        setData(res)
    }


    //Return if user on building page and on mobile
    if (isMobile && type==='building'){
        return(
            <>
                {data.map((row:any) => {
                    return (
                        <IonAccordionGroup key={row.building_uuid} className='dd-accordion'>
                            <IonAccordion toggleIcon="none" readonly>
                                <IonItem slot="header"  color="medium">
                                    <IonLabel>Square Feet: {displayValue(row.building_sq_ft, 'area')}</IonLabel>
                                </IonItem>
                            </IonAccordion>
                            <IonAccordion toggleIcon="none" readonly>
                                <IonItem slot="header"  color="medium">
                                    <IonLabel>Building Type: {row.primaryfunction}</IonLabel>
                                </IonItem>
                            </IonAccordion>
                            <IonAccordion value="kwh">
                                <IonItem slot="header"  color="primary">
                                    <IonLabel>KWH</IonLabel>
                                </IonItem>
                                <div slot='content'>
                                    <ul>
                                        <li>Total: {displayValue(row.electricity_use)}</li>
                                        <li>kWh/sf: {displayValue(row.kwh_sf)}</li>
                                    </ul>

                                </div>
                            </IonAccordion>
                            <IonAccordion value="therms">
                                <IonItem slot="header"  color="light">
                                    <IonLabel>THERMS</IonLabel>
                                </IonItem>
                                <div slot='content'>
                                    <ul>
                                        <li>Total: {displayValue(row.gas_use)}</li>
                                        <li>therms/sf: {displayValue(row.therms_sf)}</li>
                                    </ul>

                                </div>
                            </IonAccordion>
                            <IonAccordion value="kbtu">
                                <IonItem slot="header"  color="light">
                                    <IonLabel>KBTU</IonLabel>
                                </IonItem>
                                <div slot='content'>
                                    <ul>
                                        <li>Total: {displayValue(row.kbtu)}</li>
                                        <li>kbtu/sf (EUI): {displayValue(row.kbtu_sf)}</li>
                                    </ul>

                                </div>
                            </IonAccordion>
                            <IonAccordion value="dd">
                                <IonItem slot="header"  color="light">
                                    <IonLabel>Degree Days</IonLabel>
                                </IonItem>
                                <div slot='content'>
                                    <ul>
                                        <li>Climate Zone: {displayValue(row.climate_zone)}</li>
                                        <li>HDD: {displayValue(row.hdd_degdays)}</li>
                                        <li>CDD: {displayValue(row.cdd_degdays)}</li>
                                    </ul>

                                </div>
                            </IonAccordion>
                        </IonAccordionGroup>
                    )})}
            </>
        )
    }

    //Return for building page on desktop
    if(type==='building'){
        const row:any = data[0]
        return (<div>
            <IonRow className="DD-desktop-data">
                <IonCol >
                    <IonTitle>Square Feet</IonTitle>
                    <ul>
                        <li>Total: {displayValue(row.building_sq_ft, 'area')}</li>
                    </ul>
                </IonCol>
                <IonCol >
                    <IonTitle>Building Type</IonTitle>
                    <ul>
                        <li>Type: {row.primaryfunction}</li>
                    </ul>
                </IonCol>
                <IonCol >
                    <IonTitle>KWh</IonTitle>
                    <ul>
                        <li>Total: {displayValue(row.electricity_use)}</li>
                        <li>kWh/sf: {displayValue(row.kwh_sf)}</li>
                    </ul>
                </IonCol>
                <IonCol >
                    <IonTitle>Therms</IonTitle>
                    <ul>
                        <li>Total: {displayValue(row.gas_use)}</li>
                        <li>Therms/sf: {displayValue(row.therms_sf)}</li>
                    </ul>
                </IonCol>
                <IonCol>
                    <IonTitle>kBtu</IonTitle>
                    <ul>
                        <li>Total: {displayValue(row.total_kbtu)}</li>
                        <li>kBtu/sf (EUI): {displayValue(row.kbtu_sf)}</li>
                    </ul>
                </IonCol>
            </IonRow>
            <IonRow>
                <ul className="DD-list">
                    <IonCol><li>Climate Zone: {displayValue(row.climate_zone)}</li></IonCol>
                    <IonCol><li>HDD: {displayValue(row.hdd_degdays)}</li></IonCol>
                    <IonCol><li>CDD: {displayValue(row.cdd_degdays)}</li></IonCol>
                </ul>
            </IonRow>
        </div>)
    }


    //return for regular page
    return (
        <div className="table-container">
            <IonRow hidden={type !== 'all'}>
                <IonCol sizeMd="6" sizeLg="4" sizeXl="2">
                    <IonItem>
                    <IonLabel>Filter</IonLabel>
                    <IonSelect interface="popover" placeholder="Building Type" onIonChange={(e)=>filterType(e.detail.value)}>
                    <IonSelectOption key='all' value='all'>All</IonSelectOption>
                    {types.map((tp:any)=>{
                        return (
                            <IonSelectOption key={tp['id']} value={tp['name']}>{tp['name']}</IonSelectOption>
                        )
                    })}
                </IonSelect>
                </IonItem>
                </IonCol>
            </IonRow>
        <div className="degree-days-table">
        <IonRow>

            <IonCol size={type !== 'all' ? '1':'0.92'} className="heading-col border-left">Building</IonCol>
            <IonCol size={type !== 'all' ? '1':'0.92'} className="heading-col">Type</IonCol>
            <IonCol size={type !== 'all' ? '1':'0.92'} className="heading-col" hidden={type !== 'all'}>Customer</IonCol>
            <IonCol size={type !== 'all' ? '1':'0.92'} className="heading-col">Square Feet</IonCol>
            <IonCol size={type !== 'all' ? '1':'0.92'} className="heading-col">kWh</IonCol>
            <IonCol size={type !== 'all' ? '1':'0.92'} className="heading-col">therms</IonCol>
            <IonCol size={type !== 'all' ? '1':'0.92'} className="heading-col">kBtu</IonCol>
            <IonCol size={type !== 'all' ? '1':'0.92'} className="heading-col">kWh/sf</IonCol>
            <IonCol size={type !== 'all' ? '1':'0.92'} className="heading-col">therms/sf</IonCol>
            <IonCol size={type !== 'all' ? '1':'0.92'} className="heading-col">kBtu/sf (EUI)</IonCol>
            <IonCol size={type !== 'all' ? '1':'0.92'} className="heading-col">Climate Zone</IonCol>
            <IonCol size={type !== 'all' ? '1':'0.92'} className="heading-col">HDD</IonCol>
            <IonCol size={type !== 'all' ? '1':'0.92'} className="heading-col">CDD</IonCol>
        </IonRow>
        {data.map((row:any)=>{
            return(
                <IonRow class="data-row" key={row.building_uuid}>
                    <IonCol size={type !== 'all' ? '1':'0.92'} className="border-left">{row.building_name}</IonCol>
                    <IonCol size={type !== 'all' ? '1':'0.92'} >{row.primaryfunction}</IonCol>
                    <IonCol size={type !== 'all' ? '1':'0.92'} hidden={type !== 'all'}><a href={`/?id=${row.cust_uuid}&sec=customers`}>{row.cust_name}</a></IonCol>
                    <IonCol size={type !== 'all' ? '1':'0.92'}>{displayValue(row.building_sq_ft, 'area')}</IonCol>
                    <IonCol size={type !== 'all' ? '1':'0.92'}>{displayValue(row.electricity_use)}</IonCol>
                    <IonCol size={type !== 'all' ? '1':'0.92'}>{displayValue(row.gas_use)}</IonCol>
                    <IonCol size={type !== 'all' ? '1':'0.92'}>{displayValue(row.total_kbtu)}</IonCol>
                    <IonCol size={type !== 'all' ? '1':'0.92'}>{displayValue(row.kwh_sf)}</IonCol>
                    <IonCol size={type !== 'all' ? '1':'0.92'}>{displayValue(row.therms_sf)}</IonCol>
                    <IonCol size={type !== 'all' ? '1':'0.92'}>{displayValue(row.kbtu_sf)}</IonCol>
                    <IonCol size={type !== 'all' ? '1':'0.92'}>{displayValue(row.climate_zone)}</IonCol>
                    <IonCol size={type !== 'all' ? '1':'0.92'}>{displayValue(row.hdd_degdays)}</IonCol>
                    <IonCol size={type !== 'all' ? '1':'0.92'}>{displayValue(row.cdd_degdays)}</IonCol>
                </IonRow>
            )
        })}
        </div>
        </div>
    )
}

export default DegreeDaysTable