import React from "react";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import { isStaff } from "../../components/extras/Functions";
import EnergyStarData from "../../components/Energystar/EnergyStarData";
import { IonContent, IonPage } from "@ionic/react";
import EstarHeader from "../../components/Energystar/EstarHeader";
import NotAuthorized from "../Errors/NotAuthorized";
import "./Energystar.css";

const EnergyStarDataPage: React.FC = () => {
  const user = useAppSelector(selectUser);

  if (user.first_name !== "") {
    if (isStaff(user)) {
      return (
        <IonPage className="ae2-home">
          <EstarHeader />
          <IonContent className="ae2-content">
            <EnergyStarData />
          </IonContent>
        </IonPage>
      );
    } else {
      return <NotAuthorized />;
    }
  }
  return null;
};

export default EnergyStarDataPage;
