import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonItem,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import AE2Input from "../../elements/AE2Input";

interface Props {
  opened: boolean;
  setOpened: any;
  title: string;
  setTitle: any;
  proposals: any;
  save: any;
  loadedFile: any;
  override: boolean;
  setOverride: any;
}
const SaveOnePageProposalModal: React.FC<Props> = ({
  opened,
  setOpened,
  title,
  setTitle,
  proposals,
  save,
  loadedFile,
  override,
  setOverride,
}) => {
  const [exists, setExists] = useState<boolean>(false);

  useEffect(() => {
    if (loadedFile) {
      setOverride(true);
      setExists(true);
    }
  }, [loadedFile, setOverride]);

  const handleChange = (input: string) => {
    for (let proposal of proposals) {
      if (proposal.title === input) {
        setExists(true);
      } else {
        setExists(false);
      }
    }
    setTitle(input);
  };

  return (
    <IonModal
      id="saveOnePageProposalModal"
      className="modal-background"
      style={{ "--max-height": "400px" }}
      isOpen={opened}
      onDidDismiss={() => {
        setOpened(false);
      }}
    >
      <IonToolbar>
        <IonTitle>Save Proposal</IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={() => setOpened(false)}>close</IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent>
        <div className="center-all">
          <div style={{ width: "100%" }}>
            <form style={{ width: "100%" }}>
              <IonItem style={{ width: "100%", marginBottom: "0.25em" }}>
                <AE2Input
                  style={{ width: "100%", borderBottom: " 0.5px solid white" }}
                  label="File Name:"
                  placeholder="proposal name"
                  value={title}
                  onIonInput={(e) => handleChange(e.detail.value!)}
                  maxlength={100}
                ></AE2Input>
              </IonItem>
              <div hidden={!exists} style={{ color: "red" }}>
                File with that title already exists, override and save?
              </div>
              <IonItem hidden={!exists}>
                <IonCheckbox
                  aria-label="Override"
                  mode="ios"
                  slot="start"
                  checked={override}
                  onIonChange={(e) => setOverride(e.detail.checked)}
                >
                  {" "}
                  Override
                </IonCheckbox>
              </IonItem>
            </form>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <IonButton
                size="large"
                disabled={
                  !(title.length > 1 && (!exists || (exists && override)))
                }
                onClick={() => save()}
              >
                Save
              </IonButton>
            </div>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default SaveOnePageProposalModal;
