import { IonButton, IonContent, IonModal, IonRow } from "@ionic/react";
import React from "react";

import BulkUploadConsumption from "../extras/BulkUploadConsumption";
import "./ConsumptionDataModal.css";

interface Props {
  opened: boolean;
  setOpened: any;
  progress: any;
  setResultsOpened: any;
}

const ConsumptionDataModal: React.FC<Props> = ({
  opened,
  setOpened,
  progress,
  setResultsOpened,
}) => {
  return (
    <IonModal
      className="modal-background"
      id="consumption-data-modal"
      isOpen={opened}
      onDidDismiss={() => setOpened(false)}
    >
      <IonContent>
        <IonRow className="ion-justify-content-between">
          <h2> Add Consumption Data</h2>
          <div>
            <IonButton
              color="danger"
              fill="solid"
              onClick={() => {
                setOpened(false);
              }}
            >
              X
            </IonButton>
          </div>
        </IonRow>

        <div style={{ maxHeight: "600px" }}>
          {" "}
          <BulkUploadConsumption
            progress={progress}
            setModal={setOpened}
            setResultsOpened={setResultsOpened}
          />{" "}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ConsumptionDataModal;
