import React, { useEffect, useState } from "react";
import { Building } from "../../interfaces/Building";
import {
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

interface Props {
  buildings: Building[] | null;
  selectedBuildings: any;
  setSelectedBuildings: any;
}

const EnergyStarSelectBuildings: React.FC<Props> = ({
  buildings,
  selectedBuildings,
  setSelectedBuildings,
}) => {
  const [filteredBuildings, setFilteredBuildings] = useState<any>([]);
  const [selectedCustomer, setSelectedCustomers] = useState<any>([]);
  const [selectAll, setSelectAll] = useState(false);
  const customers = buildings?.map((c: any) => {
    return { cust_name: c.cust_name, uuid: c.uuid };
  });

  useEffect(() => {
    if (selectedCustomer.length > 0) {
      setFilteredBuildings(
        buildings?.filter((b: any) => selectedCustomer.includes(b.uuid))
      );
    } else {
      setFilteredBuildings(buildings);
    }
  }, [buildings, selectedCustomer]);

  useEffect(() => {
    if (selectAll) {
      setSelectedCustomers([]);
      setSelectedBuildings(
        buildings?.map((c: any) => c.buildings.map((b: any) => b.id)).flat()
      );
    } else {
      setSelectedBuildings([]);
    }
  }, [selectAll]); // eslint-disable-line

  const handleSelectCustomer = (i: any) => {
    setSelectedCustomers((prev: any) => {
      const existingIndex = prev.indexOf(i);
      if (existingIndex === -1) {
        return [...prev, i];
      } else {
        return prev.filter((j: any) => j !== i);
      }
    });
  };
  const handleSearchBuilding = (searchVal: any) => {
    setFilteredBuildings(
      buildings?.filter(
        (c: any) =>
          c.cust_name.toLowerCase().includes(searchVal.toLowerCase()) ||
          c.buildings.some((b: any) =>
            b.name.toLowerCase().includes(searchVal.toLowerCase())
          )
      )
    );
  };

  const handleSelectBuilding = (i: any) => {
    setSelectedBuildings((prev: any) => {
      const existingIndex = prev.indexOf(i);
      if (existingIndex === -1) {
        return [...prev, i];
      } else {
        return prev.filter((j: any) => j !== i);
      }
    });
  };

  return (
    <IonGrid className="Select-Estar_Buildings-Wrapper">
      <IonCol size="12" className="mobile-only">
        <IonSelect
          aria-label="customers"
          value={selectedCustomer}
          fill="outline"
          placeholder="Filter By Customer"
          multiple={true}
          onIonChange={(e) => setSelectedCustomers(e.detail.value)}
        >
          {customers?.map((c: any) => {
            return (
              <IonSelectOption key={c.uuid} value={c.uuid}>
                {c.cust_name}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </IonCol>

      <IonRow style={{ height: "700px" }}>
        <IonCol
          size="12"
          sizeMd="9"
          style={{ padding: "1em", border: "2px solid black" }}
        >
          <IonRow className="ion-justify-content-between">
            <h6>Select Buildings</h6>
            <IonCheckbox
              mode="ios"
              checked={selectAll}
              onIonChange={() => setSelectAll(!selectAll)}
            >
              Select ALL
            </IonCheckbox>
          </IonRow>
          <IonRow>
            <IonSearchbar
              onIonInput={(e) => {
                handleSearchBuilding(e.detail.value);
              }}
            ></IonSearchbar>
          </IonRow>
          <IonContent style={{ overflowY: "auto", maxHeight: "85%" }}>
            {filteredBuildings?.map((c: any) => (
              <div key={c.uuid}>
                <b>{c.cust_name}</b>
                {c.buildings.map((b: any) => {
                  return (
                    <IonItem key={b.id}>
                      <IonCheckbox
                        checked={selectedBuildings.includes(b.id)}
                        onIonChange={() => handleSelectBuilding(b.id)}
                      >
                        {b.name}
                      </IonCheckbox>
                    </IonItem>
                  );
                })}
              </div>
            ))}
          </IonContent>
        </IonCol>
        <IonCol
          size="12"
          sizeMd="3"
          className="pc-only"
          style={{ border: "2px solid black" }}
        >
          <h6> Filter By Customer </h6>
          <IonContent style={{ overflowY: "auto", maxHeight: "90%" }}>
            {customers?.map((c: any) => {
              return (
                <IonItem key={`customer-${c.uuid}`}>
                  <IonCheckbox
                    checked={selectedCustomer.includes(c.uuid)}
                    onIonChange={() => handleSelectCustomer(c.uuid)}
                  >
                    {c.cust_name}
                  </IonCheckbox>
                </IonItem>
              );
            })}
          </IonContent>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default EnergyStarSelectBuildings;
