import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
} from "@ionic/react";
import React from "react";

interface Props {
  data: Result[];
}

interface Result {
  property: string;
  savings?: SavingsResult;
  error?: string;
}

interface SavingsResult {
  baseline_kwh_over_cdd: number | null;
  baseline_kwh_over_cdd_r_squared: number | null;
  baseline_kwh_over_hdd: number | null;
  baseline_kwh_over_hdd_r_squared: number | null;
  baseline_min_therms: number | null;
  baseline_therms_hdd_intercept: number | null;
  baseline_therms_over_hdd: number | null;
  baseline_therms_over_hdd_r_squared: number | null;
  id: string;
  intercept: number | null;
  property_id: string;
}

const CorrelationsResults: React.FC<Props> = ({ data }) => {
  return (
    <div>
      <IonAccordionGroup>
        {data.map((row: Result, i: Number) => {
          return (
            <IonAccordion key={`cost-avoidance-${i}`}>
              <IonItem slot="header">
                <IonLabel>
                  {row.error ? (
                    <span style={{ color: "red" }}> Error </span>
                  ) : (
                    <span style={{ color: "green" }}>Success </span>
                  )}{" "}
                  : {row.property}
                </IonLabel>
              </IonItem>
              <div slot="content" style={{ color: "black" }}>
                {row.error ? (
                  <p style={{ color: "red" }}>{row.error}</p>
                ) : (
                  <pre style={{ color: "black" }}>{JSON.stringify(row.savings, null, 2)}</pre>
                )}
              </div>
            </IonAccordion>
          );
        })}
      </IonAccordionGroup>
    </div>
  )
};

export default CorrelationsResults;
