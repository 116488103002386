import {
  IonItemSliding,
  IonItem,
  IonIcon,
  IonLabel,
  IonList,
} from "@ionic/react";
import { alertCircleOutline } from "ionicons/icons";
import React from "react";
import $ from 'jquery';




const AboutButton = () => {

    const showList = (name: string) => {
        const list = $(`.${name}-list`);
        list.slideToggle();
      };


  return (
    <IonItemSliding>
    <div className={'about-block'}>
      <IonItem button onClick={() => showList("about")}>
        <IonIcon
          slot="start"
          ios={alertCircleOutline}
          md={alertCircleOutline}
        />
        <IonLabel>About</IonLabel>
        </IonItem>

        <IonList className="about-list item-lists" role="list">
          <IonItem
            button
            href={"/privacy"}
          >
            Privacy Policy
          </IonItem>
          <IonItem
            button
            href={"/terms_and_conditions"}
          >
            Terms & Conditions
          </IonItem>
        </IonList>
        </div>


    </IonItemSliding>
  );
};

export default AboutButton;
