import { IonButton, IonCol, IonLoading, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { API_URL } from "../../../actions/settings";
import { useAppSelector } from "../../../Hooks";
import { selectUser } from "../../../reducers/UserReducer";
import CheckRole from "../../extras/CheckRole";
import './AE2Software.css'
import AE2SoftwareDocsForm from "./AE2SoftwareDocsForm";
import { Redirect } from "react-router";


async function getDocs(token:any,id:any,doc_id:any) {
  let ver = 'latest'
  if(doc_id){
    id = doc_id
    ver = 'prev'
  }

  return fetch(`${API_URL}/ae2_software_docs/${id}/${ver}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }

const AE2SoftwareDocs: React.FC = () => {
    const [loaded, setLoaded] = useState(false)
    const [docs, setDocs] = useState<any>({})
    const user = useAppSelector(selectUser)

    let params =  new URLSearchParams(window.location.search);
    const id = params.get('software_id') || ''
    const [ce,setCE] = useState<any>(false) //ce = Create/Edit
    const doc_id = params.get('doc_id')

    useEffect(()=>{
      let parameters =  new URLSearchParams(window.location.search);
      const handleDocs = async () =>{
        const token = localStorage.getItem('token') || ""
        const res = await getDocs(`Token ${token.slice(1,-1)}`, id,doc_id)
        setDocs(res.data)
        setLoaded(true)
      }
      if (parameters.get('CE')){
        setCE(parameters.get('CE'))
      }
      else{
        handleDocs()
      }
      setLoaded(true)
    },[id,ce,doc_id])

    // role 4 - Customer
    if(CheckRole(user,[4])){
      return <Redirect to="/403"/>
  }

    if(ce){
        return(
          <AE2SoftwareDocsForm id={id} ce={ce}/>
        )
      }

    return (
      <>{ !loaded ?
        <IonLoading
          cssClass='my-custom-class'
          isOpen={!loaded}
          message={'Loading...'}
          spinner='bubbles'
          duration={3000}
          />
        :
        !docs.title ?
        <IonRow className="ion-justify-content-center">
          <IonCol size="12">
            <IonRow className="ion-justify-content-center">
                <h1>No Documentation Available For This Software</h1>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonButton hidden={!CheckRole(user,['Admin'])} href={`/account?tab=software_docs&software_id=${id}&CE=create`} >Add Documentation</IonButton>
            </IonRow>
          </IonCol>
        </IonRow>
        :
        <IonRow className="ion-justify-content-center">
          <IonCol size="12">
            <IonRow className="ion-justify-content-center">
                <h1 className="main-heading">{docs.title}</h1>
            </IonRow>
            <IonRow className="ion-justify-content-end">
              <IonButton href={`/account?tab=software_docs_all&software_id=${id}`}> View All Docs</IonButton>
              <IonButton hidden={!CheckRole(user,['Admin'])} href={`/account?tab=software_docs&software_id=${id}&CE=edit`}>Update Latest Doc</IonButton>
            </IonRow>
            <IonRow>
              <div className='document-container' dangerouslySetInnerHTML={{__html: docs.html}}></div>
            </IonRow>
          </IonCol>
        </IonRow>
      }</>
    );
  };

  export default AE2SoftwareDocs;
