import { IonCol, IonIcon, IonItem, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { star, starOutline } from "ionicons/icons"
import { useEffect, useState } from "react"
import AE2Input from "../../elements/AE2Input"


interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
totalGrossFloorArea : "",
totalGrossFloorArea_units : "",
totalGrossFloorArea_date : "",
fullServiceSpaFloorArea : "",
fullServiceSpaFloorArea_units : "",
fullServiceSpaFloorArea_date : "",
gymCenterFloorArea:"",
gymCenterFloorArea_units:"",
gymCenterFloorArea_date:"",
hoursPerDayGuestsOnsite : "",
hoursPerDayGuestsOnsite_date : "",
numberOfCommercialRefrigerationUnits : "",
numberOfCommercialRefrigerationUnits_date : "",
numberOfGuestMealsServedPerYear : "",
numberOfGuestMealsServedPerYear_date : "",
numberOfHotelRooms : "",
numberOfHotelRooms_date : "",
laundryFacility:"",
laundryFacility_date:"",
cookingFacilities:"",
cookingFacilities_date:"",
onSiteLaundryFacility:"",
onSiteLaundryFacility_date:"",
numberOfWorkers:"",
numberOfWorkers_date:"",
percentCooled : "",
percentCooled_date : "",
percentHeated : "",
percentHeated_date : "",
grossFloorAreaUsedForFoodPreparation : "",
grossFloorAreaUsedForFoodPreparation_units : "",
grossFloorAreaUsedForFoodPreparation_date : "",
amountOfLaundryProcessedAnnually : "",
amountOfLaundryProcessedAnnually_units : "",
amountOfLaundryProcessedAnnually_date : "",
grossFloorAreaHotelConferenceSpace : "",
grossFloorAreaHotelConferenceSpace_units : "",
grossFloorAreaHotelConferenceSpace_date : "",
}


const startErrors = {
    totalGrossFloorArea : "Field Required",
    totalGrossFloorArea_units : "Select Units",
    totalGrossFloorArea_date : "Date is required",
    fullServiceSpaFloorArea : "",
    fullServiceSpaFloorArea_units : "",
    fullServiceSpaFloorArea_date : "",
    gymCenterFloorArea:"",
    gymCenterFloorArea_units:"",
    gymCenterFloorArea_date:"",
    hoursPerDayGuestsOnsite : "",
    hoursPerDayGuestsOnsite_date : "",
    numberOfCommercialRefrigerationUnits : "",
    numberOfCommercialRefrigerationUnits_date : "",
    numberOfGuestMealsServedPerYear : "",
    numberOfGuestMealsServedPerYear_date : "",
    numberOfHotelRooms : "",
    numberOfHotelRooms_date : "",
    laundryFacility:"",
    laundryFacility_date:"",
    cookingFacilities:"",
    cookingFacilities_date:"",
    onSiteLaundryFacility:"",
    onSiteLaundryFacility_date:"",
    numberOfWorkers:"",
    numberOfWorkers_date:"",
    percentCooled : "",
    percentCooled_date : "",
    percentHeated : "",
    percentHeated_date : "",
    grossFloorAreaUsedForFoodPreparation : "",
    grossFloorAreaUsedForFoodPreparation_units : "",
    grossFloorAreaUsedForFoodPreparation_date : "",
    amountOfLaundryProcessedAnnually : "",
    amountOfLaundryProcessedAnnually_units : "",
    amountOfLaundryProcessedAnnually_date : "",
    grossFloorAreaHotelConferenceSpace : "",
    grossFloorAreaHotelConferenceSpace_units : "",
    grossFloorAreaHotelConferenceSpace_date : "",
    }

const Hotel: React.FC<Props> = ({setParentForm,formErrors}) => {
    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])
    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Area Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case ('fullServiceSpaFloorArea'):
                if (value === ''){
                    handleError('fullServiceSpaFloorArea','Floor Area Cannot be Empty')
                }
                else if (value < 0){
                    handleError('fullServiceSpaFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('fullServiceSpaFloorArea','')
                }
                break;
            case ('fullServiceSpaFloorArea_units'):
                if (value === ''){
                    handleError('fullServiceSpaFloorArea_units','Select gfa units')
                }
                else {
                    handleError('fullServiceSpaFloorArea_units','')
                }
                break;
            case ('fullServiceSpaFloorArea_date'):
                if (value === ''){
                    handleError('fullServiceSpaFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('fullServiceSpaFloorArea_date','Invalid Date')
                }
                else {
                    handleError('fullServiceSpaFloorArea_date','')
                }
                break;
            case ('gymCenterFloorArea'):
                if (value === ''){
                    handleError('gymCenterFloorArea','Floor Area Cannot be Empty')
                }
                else if (value < 0){
                    handleError('gymCenterFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('gymCenterFloorArea','')
                }
                break;
            case ('gymCenterFloorArea_units'):
                if (value === ''){
                    handleError('gymCenterFloorArea_units','Select gfa units')
                }
                else {
                    handleError('gymCenterFloorArea_units','')
                }
                break;
            case ('gymCenterFloorArea_date'):
                if (value === ''){
                    handleError('gymCenterFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('gymCenterFloorArea_date','Invalid Date')
                }
                else {
                    handleError('gymCenterFloorArea_date','')
                }
                break;
            case('hoursPerDayGuestsOnsite'):
                if(value !==''){
                    form.hoursPerDayGuestsOnsite_date === '' ? handleError('hoursPerDayGuestsOnsite_date','Date cannot be empty') : handleError('hoursPerDayGuestsOnsite_date','')
                }
                else{
                    handleError('hoursPerDayGuestsOnsite','')
                    handleError('hoursPerDayGuestsOnsite_date','')
                }
                break;
            case('hoursPerDayGuestsOnsite_date'):
                if(value !==''){
                    form.hoursPerDayGuestsOnsite === '' ? handleError('hoursPerDayGuestsOnsite','Field cannot be empty') : handleError('hoursPerDayGuestsOnsite','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('hoursPerDayGuestsOnsite_date','Invalid Date') : handleError('hoursPerDayGuestsOnsite_date','')
                }
                else{
                    handleError('hoursPerDayGuestsOnsite','')
                    handleError('hoursPerDayGuestsOnsite_date','')
                }
                break;
            case('numberOfCommercialRefrigerationUnits'):
                if(value !==''){
                    form.numberOfCommercialRefrigerationUnits_date === '' ? handleError('numberOfCommercialRefrigerationUnits_date','Date cannot be empty') : handleError('numberOfCommercialRefrigerationUnits_date','')
                    value < 0 ? handleError('numberOfCommercialRefrigerationUnits','Must be a Positive number') : handleError('numberOfCommercialRefrigerationUnits','')
                }
                else{
                    handleError('numberOfCommercialRefrigerationUnits','')
                    handleError('numberOfCommercialRefrigerationUnits_date','')
                }
                break;
            case('numberOfCommercialRefrigerationUnits_date'):
                if(value !==''){
                    form.numberOfCommercialRefrigerationUnits === '' ? handleError('numberOfCommercialRefrigerationUnits','Field cannot be empty') : handleError('numberOfCommercialRefrigerationUnits','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfCommercialRefrigerationUnits_date','Invalid Date') : handleError('numberOfCommercialRefrigerationUnits_date','')
                }
                else{
                    handleError('numberOfCommercialRefrigerationUnits','')
                    handleError('numberOfCommercialRefrigerationUnits_date','')
                }
                break;
            case('numberOfGuestMealsServedPerYear'):
                if(value !==''){
                    form.numberOfGuestMealsServedPerYear_date === '' ? handleError('numberOfGuestMealsServedPerYear_date','Date cannot be empty') : handleError('numberOfGuestMealsServedPerYear_date','')
                    value < 0 ? handleError('numberOfGuestMealsServedPerYear','Must be a Positive number') : handleError('numberOfGuestMealsServedPerYear','')
                }
                else{
                    handleError('numberOfGuestMealsServedPerYear','')
                    handleError('numberOfGuestMealsServedPerYear_date','')
                }
                break;
            case('numberOfGuestMealsServedPerYear_date'):
                if(value !==''){
                    form.numberOfGuestMealsServedPerYear === '' ? handleError('numberOfGuestMealsServedPerYear','Field cannot be empty') : handleError('numberOfGuestMealsServedPerYear','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfGuestMealsServedPerYear_date','Invalid Date') : handleError('numberOfGuestMealsServedPerYear_date','')
                }
                else{
                    handleError('numberOfGuestMealsServedPerYear','')
                    handleError('numberOfGuestMealsServedPerYear_date','')
                }
                break;
            case('numberOfHotelRooms'):
                if(value !==''){
                    form.numberOfHotelRooms_date === '' ? handleError('numberOfHotelRooms_date','Date cannot be empty') : handleError('numberOfHotelRooms_date','')
                    value < 0 ? handleError('numberOfHotelRooms','Must be a Positive number') : handleError('numberOfHotelRooms','')
                }
                else{
                    handleError('numberOfHotelRooms','')
                    handleError('numberOfHotelRooms_date','')
                }
                break;
            case('numberOfHotelRooms_date'):
                if(value !==''){
                    form.numberOfHotelRooms === '' ? handleError('numberOfHotelRooms','Field cannot be empty') : handleError('numberOfHotelRooms','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfHotelRooms_date','Invalid Date') : handleError('numberOfHotelRooms_date','')
                }
                else{
                    handleError('numberOfHotelRooms','')
                    handleError('numberOfHotelRooms_date','')
                }
                break;
            case('laundryFacility'):
                if(value !==''){
                    form.laundryFacility_date === '' ? handleError('laundryFacility_date','Date cannot be empty') : handleError('laundryFacility_date','')

                }
                else{
                    handleError('laundryFacility','')
                    handleError('laundryFacility_date','')
                }
                break;
            case('laundryFacility_date'):
                if(value !==''){
                    form.laundryFacility === '' ? handleError('laundryFacility','Field cannot be empty') : handleError('laundryFacility','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('laundryFacility_date','Invalid Date') : handleError('laundryFacility_date','')
                }
                else{
                    handleError('laundryFacility','')
                    handleError('laundryFacility_date','')
                }
                break;
            case('percentCooled'):
                if(value !==''){
                    form.percentCooled_date === '' ? handleError('percentCooled_date','Date cannot be empty') : handleError('percentCooled_date','')

                }
                else{
                    handleError('percentCooled','')
                    handleError('percentCooled_date','')
                }
                break;
            case('percentCooled_date'):
                if(value !==''){
                    form.percentCooled === '' ? handleError('percentCooled','Field cannot be empty') : handleError('percentCooled','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('percentCooled_date','Invalid Date') : handleError('percentCooled_date','')
                }
                else{
                    handleError('percentCooled','')
                    handleError('percentCooled_date','')
                }
                break;
            case('percentHeated'):
                if(value !==''){
                    form.percentHeated_date === '' ? handleError('percentHeated_date','Date cannot be empty') : handleError('percentHeated_date','')

                }
                else{
                    handleError('percentHeated','')
                    handleError('percentHeated_date','')
                }
                break;
            case('percentHeated_date'):
                if(value !==''){
                    form.percentHeated === '' ? handleError('percentHeated','Field cannot be empty') : handleError('percentHeated','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('percentHeated_date','Invalid Date') : handleError('percentHeated_date','')
                }
                else{
                    handleError('percentHeated','')
                    handleError('percentHeated_date','')
                }
                break;
            case('cookingFacilities'):
                if(value !==''){
                    form.cookingFacilities_date === '' ? handleError('cookingFacilities_date','Date cannot be empty') : handleError('cookingFacilities_date','')

                }
                else{
                    handleError('cookingFacilities','')
                    handleError('cookingFacilities_date','')
                }
                break;
            case('cookingFacilities_date'):
                if(value !==''){
                    form.cookingFacilities === '' ? handleError('cookingFacilities','Field cannot be empty') : handleError('cookingFacilities','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('cookingFacilities_date','Invalid Date') : handleError('cookingFacilities_date','')
                }
                else{
                    handleError('cookingFacilities','')
                    handleError('cookingFacilities_date','')
                }
                break;
            case ('amountOfLaundryProcessedAnnually'):
                if (value === ''){
                    handleError('amountOfLaundryProcessedAnnually','Floor Area Cannot be Empty')
                }
                else if (value < 0){
                    handleError('amountOfLaundryProcessedAnnually','GFA must be a positive number')
                }
                else {
                    handleError('amountOfLaundryProcessedAnnually','')
                }
                break;
            case ('amountOfLaundryProcessedAnnually_units'):
                if (value === ''){
                    handleError('amountOfLaundryProcessedAnnually_units','Select gfa units')
                }
                else {
                    handleError('amountOfLaundryProcessedAnnually_units','')
                }
                break;
            case ('amountOfLaundryProcessedAnnually_date'):
                if (value === ''){
                    handleError('amountOfLaundryProcessedAnnually_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('amountOfLaundryProcessedAnnually_date','Invalid Date')
                }
                else {
                    handleError('amountOfLaundryProcessedAnnually_date','')
                }
                break;
            case('numberOfWorkers'):
                if(value !==''){
                    form.numberOfWorkers_date === '' ? handleError('numberOfWorkers_date','Date cannot be empty') : handleError('numberOfWorkers_date','')
                    value < 0 ? handleError('numberOfWorkers','Must be a Positive number') : handleError('numberOfWorkers','')
                }
                else{
                    handleError('numberOfWorkers','')
                    handleError('numberOfWorkers_date','')
                }
                break;
            case('numberOfWorkers_date'):
                if(value !==''){
                    form.numberOfWorkers === '' ? handleError('numberOfWorkers','Field cannot be empty') : handleError('numberOfWorkers','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfWorkers_date','Invalid Date') : handleError('numberOfWorkers_date','')
                }
                else{
                    handleError('numberOfWorkers','')
                    handleError('numberOfWorkers_date','')
                }
                break;
            case('grossFloorAreaUsedForFoodPreparation'):
                if(value !== ''){
                    form.grossFloorAreaUsedForFoodPreparation_date === '' ? handleError('grossFloorAreaUsedForFoodPreparation_date','Date Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_date',"")
                    form.grossFloorAreaUsedForFoodPreparation_units === '' ? handleError('grossFloorAreaUsedForFoodPreparation_units','Units Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_units',"")
                    value < 0 ? handleError('grossFloorAreaUsedForFoodPreparation','Must be a Positive Number') : handleError('grossFloorAreaUsedForFoodPreparation',"")
                }
                else{
                    handleError('grossFloorAreaUsedForFoodPreparation','')
                    if(form.grossFloorAreaUsedForFoodPreparation_date === '' && form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation_date','')
                        handleError('grossFloorAreaUsedForFoodPreparation_units','')}
                    else if(form.grossFloorAreaUsedForFoodPreparation_date === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_date'," Date Cannot be empty")
                    }
                    else if(form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_units'," Unit Cannot be empty")
                    }

                }
                break;
            case('grossFloorAreaUsedForFoodPreparation_date'):
                if(value !== ''){
                    form.grossFloorAreaUsedForFoodPreparation === '' ? handleError('grossFloorAreaUsedForFoodPreparation','Field Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation',"")
                    form.grossFloorAreaUsedForFoodPreparation_units === '' ? handleError('grossFloorAreaUsedForFoodPreparation_units','Units Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_units',"")
                    new Date(value).getTime() > getCurrentDate() ? handleError('grossFloorAreaUsedForFoodPreparation_date','Invalid Date') : handleError('grossFloorAreaUsedForFoodPreparation_date','')
                }
                else{
                    handleError('grossFloorAreaUsedForFoodPreparation_date','')
                    if(form.grossFloorAreaUsedForFoodPreparation === '' && form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation','')
                        handleError('grossFloorAreaUsedForFoodPreparation_units','')}
                    else if(form.grossFloorAreaUsedForFoodPreparation === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_date'," Date Cannot be empty")
                    }
                    else if(form.grossFloorAreaUsedForFoodPreparation_units === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation_date'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation'," Unit Cannot be empty")
                    }

                }
                break;
            case('grossFloorAreaUsedForFoodPreparation_units'):
                if(value !== ''){
                    form.grossFloorAreaUsedForFoodPreparation === '' ? handleError('grossFloorAreaUsedForFoodPreparation','Field Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation',"")
                    form.grossFloorAreaUsedForFoodPreparation_date === '' ? handleError('grossFloorAreaUsedForFoodPreparation_date','Units Cannot be Empty') : handleError('grossFloorAreaUsedForFoodPreparation_date',"")

                }
                else{
                    handleError('grossFloorAreaUsedForFoodPreparation_units','')
                    if(form.grossFloorAreaUsedForFoodPreparation === '' && form.grossFloorAreaUsedForFoodPreparation_date === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation','')
                        handleError('grossFloorAreaUsedForFoodPreparation_date','')}
                    else if(form.grossFloorAreaUsedForFoodPreparation === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation_units'," Date Cannot be empty")
                    }
                    else if(form.grossFloorAreaUsedForFoodPreparation === ''){
                        handleError('grossFloorAreaUsedForFoodPreparation'," Field Cannot be empty")
                        handleError('grossFloorAreaUsedForFoodPreparation'," Unit Cannot be empty")
                    }

                }
                break;
            case('grossFloorAreaHotelConferenceSpace'):
                if(value !== ''){
                    form.grossFloorAreaHotelConferenceSpace_date === '' ? handleError('grossFloorAreaHotelConferenceSpace_date','Date Cannot be Empty') : handleError('grossFloorAreaHotelConferenceSpace_date',"")
                    form.grossFloorAreaHotelConferenceSpace_units === '' ? handleError('grossFloorAreaHotelConferenceSpace_units','Units Cannot be Empty') : handleError('grossFloorAreaHotelConferenceSpace_units',"")
                    value < 0 ? handleError('grossFloorAreaHotelConferenceSpace','Must be a Positive Number') : handleError('grossFloorAreaHotelConferenceSpace',"")
                }
                else{
                    handleError('grossFloorAreaHotelConferenceSpace','')
                    if(form.grossFloorAreaHotelConferenceSpace_date === '' && form.grossFloorAreaHotelConferenceSpace_units === ''){
                        handleError('grossFloorAreaHotelConferenceSpace_date','')
                        handleError('grossFloorAreaHotelConferenceSpace_units','')}
                    else if(form.grossFloorAreaHotelConferenceSpace_date === ''){
                        handleError('grossFloorAreaHotelConferenceSpace'," Field Cannot be empty")
                        handleError('grossFloorAreaHotelConferenceSpace_date'," Date Cannot be empty")
                    }
                    else if(form.grossFloorAreaHotelConferenceSpace_units === ''){
                        handleError('grossFloorAreaHotelConferenceSpace'," Field Cannot be empty")
                        handleError('grossFloorAreaHotelConferenceSpace_units'," Unit Cannot be empty")
                    }

                }
                break;
            case('grossFloorAreaHotelConferenceSpace_date'):
                if(value !== ''){
                    form.grossFloorAreaHotelConferenceSpace === '' ? handleError('grossFloorAreaHotelConferenceSpace','Field Cannot be Empty') : handleError('grossFloorAreaHotelConferenceSpace',"")
                    form.grossFloorAreaHotelConferenceSpace_units === '' ? handleError('grossFloorAreaHotelConferenceSpace_units','Units Cannot be Empty') : handleError('grossFloorAreaHotelConferenceSpace_units',"")
                    new Date(value).getTime() > getCurrentDate() ? handleError('grossFloorAreaHotelConferenceSpace_date','Invalid Date') : handleError('grossFloorAreaHotelConferenceSpace_date','')
                }
                else{
                    handleError('grossFloorAreaHotelConferenceSpace_date','')
                    if(form.grossFloorAreaHotelConferenceSpace === '' && form.grossFloorAreaHotelConferenceSpace_units === ''){
                        handleError('grossFloorAreaHotelConferenceSpace','')
                        handleError('grossFloorAreaHotelConferenceSpace_units','')}
                    else if(form.grossFloorAreaHotelConferenceSpace === ''){
                        handleError('grossFloorAreaHotelConferenceSpace'," Field Cannot be empty")
                        handleError('grossFloorAreaHotelConferenceSpace_date'," Date Cannot be empty")
                    }
                    else if(form.grossFloorAreaHotelConferenceSpace_units === ''){
                        handleError('grossFloorAreaHotelConferenceSpace_date'," Field Cannot be empty")
                        handleError('grossFloorAreaHotelConferenceSpace'," Unit Cannot be empty")
                    }

                }
                break;
            case('grossFloorAreaHotelConferenceSpace_units'):
                if(value !== ''){
                    form.grossFloorAreaHotelConferenceSpace === '' ? handleError('grossFloorAreaHotelConferenceSpace','Field Cannot be Empty') : handleError('grossFloorAreaHotelConferenceSpace',"")
                    form.grossFloorAreaHotelConferenceSpace_date === '' ? handleError('grossFloorAreaHotelConferenceSpace_date','Date Cannot be Empty') : handleError('grossFloorAreaHotelConferenceSpace_date',"")
                }
                else{
                    handleError('grossFloorAreaHotelConferenceSpace_units','')
                    if(form.grossFloorAreaHotelConferenceSpace === '' && form.grossFloorAreaHotelConferenceSpace_date === ''){
                        handleError('grossFloorAreaHotelConferenceSpace','')
                        handleError('grossFloorAreaHotelConferenceSpace_date','')}
                    else if(form.grossFloorAreaHotelConferenceSpace === ''){
                        handleError('grossFloorAreaHotelConferenceSpace'," Field Cannot be empty")
                        handleError('grossFloorAreaHotelConferenceSpace_units'," Date Cannot be empty")
                    }
                    else if(form.grossFloorAreaHotelConferenceSpace === ''){
                        handleError('grossFloorAreaHotelConferenceSpace_date'," Field Cannot be empty")
                        handleError('grossFloorAreaHotelConferenceSpace'," Unit Cannot be empty")
                    }

                }
                break;
            default:
                break;
        }
    }

    return(
        <div>
            <h1>Hospital (General Medical & Surgical) Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.totalGrossFloorArea }  value={form.totalGrossFloorArea} placeholder='GFA' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Total Gross Floor Area:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                        </ div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.totalGrossFloorArea_date } value={form.totalGrossFloorArea_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Full Service Spa Floor Area" type="number" errorText={errors.fullServiceSpaFloorArea }  value={form.fullServiceSpaFloorArea} placeholder='Spa Floor Area' onIonInput={e => (handleFormChange(e.detail.value!,'fullServiceSpaFloorArea'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.fullServiceSpaFloorArea_units === '' ? "valid":'invalid'} value={form.fullServiceSpaFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'fullServiceSpaFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.fullServiceSpaFloorArea_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.fullServiceSpaFloorArea_date } value={form.fullServiceSpaFloorArea_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'fullServiceSpaFloorArea_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Gym Center Floor Area" type="number" errorText={errors.gymCenterFloorArea }  value={form.gymCenterFloorArea} placeholder='Gym Center Floor Area' onIonInput={e => (handleFormChange(e.detail.value!,'gymCenterFloorArea'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.gymCenterFloorArea_units === '' ? "valid":'invalid'} value={form.gymCenterFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'gymCenterFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.gymCenterFloorArea_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.gymCenterFloorArea_date } value={form.gymCenterFloorArea_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'gymCenterFloorArea_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Hours Per Day Guest Spend on Site" labelPlacement="stacked" className={errors.hoursPerDayGuestsOnsite === '' ? "valid":'invalid'}  value={form.hoursPerDayGuestsOnsite} interface='popover' placeholder='Hours Guest Spend on Site' onIonChange={e => (handleFormChange(e.detail.value!,'hoursPerDayGuestsOnsite'))}>
                            <IonSelectOption>Less Than 15</IonSelectOption>
                            <IonSelectOption>15 To 19</IonSelectOption>
                            <IonSelectOption>More Than 20</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.hoursPerDayGuestsOnsite}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.hoursPerDayGuestsOnsite_date }  value={form.hoursPerDayGuestsOnsite_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'hoursPerDayGuestsOnsite_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" labelPlacement="stacked" errorText={errors.numberOfCommercialRefrigerationUnits }  value={form.numberOfCommercialRefrigerationUnits} placeholder='# Commercial Refrigeration Units' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfCommercialRefrigerationUnits'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Number of Commercial Refrigeration Units</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.numberOfCommercialRefrigerationUnits_date }  value={form.numberOfCommercialRefrigerationUnits_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfCommercialRefrigerationUnits_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Number of Guest Meals Served Per Year" labelPlacement="stacked" type="number" errorText={errors.numberOfGuestMealsServedPerYear }  value={form.numberOfGuestMealsServedPerYear} placeholder='# Guest Meals/Year Served' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfGuestMealsServedPerYear'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.numberOfGuestMealsServedPerYear_date }  value={form.numberOfGuestMealsServedPerYear_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfGuestMealsServedPerYear_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.numberOfHotelRooms }  value={form.numberOfHotelRooms} placeholder='# Hotel Rooms' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfHotelRooms'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Number of Hotel Rooms</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.numberOfHotelRooms_date }  value={form.numberOfHotelRooms_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfHotelRooms_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Laundry Type" className={errors.laundryFacility === '' ? "valid":'invalid'}  value={form.laundryFacility} interface='popover' placeholder='Laundry Type' onIonChange={e => (handleFormChange(e.detail.value!,'laundryFacility'))}>
                            <IonSelectOption>Linens only</IonSelectOption>
                            <IonSelectOption>Terry only</IonSelectOption>
                            <IonSelectOption>Both linens and terry</IonSelectOption>
                            <IonSelectOption>No laundry facility</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.laundryFacility}</div>
                    </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.laundryFacility_date }  value={form.laundryFacility_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'laundryFacility_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect className={errors.percentCooled === '' ? "valid":'invalid'}  value={form.percentCooled} interface='popover' placeholder='Percent Cooled' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Percent Cooled</div>
                        </IonSelect>
                        <div className="error-detail">{errors.percentCooled}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.percentCooled_date }  value={form.percentCooled_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'percentCooled_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect className={errors.percentHeated === '' ? "valid":'invalid'}  value={form.percentHeated} interface='popover' placeholder='Percent Heated' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Percent Heated</div>
                        </IonSelect>
                        <div className="error-detail">{errors.percentHeated}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.percentHeated_date }  value={form.percentHeated_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'percentHeated_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect className={errors.cookingFacilities === '' ? "valid":'invalid'}  value={form.cookingFacilities} interface='popover' placeholder='Cooking Facility?' onIonChange={e => (handleFormChange(e.detail.value!,'cookingFacilities'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Cooking Facility?</div>
                        </IonSelect>
                        <div className="error-detail">{errors.cookingFacilities}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.cookingFacilities_date }  value={form.cookingFacilities_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'cookingFacilities_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Amount of Laundry Processed Annually" labelPlacement="stacked" type="number" errorText={errors.amountOfLaundryProcessedAnnually }  value={form.amountOfLaundryProcessedAnnually} placeholder='Annual Processed Laundry' onIonInput={e => (handleFormChange(e.detail.value!,'amountOfLaundryProcessedAnnually'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.amountOfLaundryProcessedAnnually_units === '' ? "valid":'invalid'} value={form.amountOfLaundryProcessedAnnually_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'amountOfLaundryProcessedAnnually_units'))}>
                            <IonSelectOption>pounds</IonSelectOption>
                            <IonSelectOption>Kilogram</IonSelectOption>
                            <IonSelectOption>short tons</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.amountOfLaundryProcessedAnnually_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.amountOfLaundryProcessedAnnually_date } value={form.amountOfLaundryProcessedAnnually_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'amountOfLaundryProcessedAnnually_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.numberOfWorkers }  value={form.numberOfWorkers} placeholder='# Workers' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfWorkers'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Number of Workers</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.numberOfWorkers_date }  value={form.numberOfWorkers_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfWorkers_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Gross Floor Area Used For Food Preparation" labelPlacement="stacked" type="number" errorText={errors.grossFloorAreaUsedForFoodPreparation }  value={form.grossFloorAreaUsedForFoodPreparation} placeholder='Floor Area For Food Prep' onIonInput={e => (handleFormChange(e.detail.value!,'grossFloorAreaUsedForFoodPreparation'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.grossFloorAreaUsedForFoodPreparation_units === '' ? "valid":'invalid'} value={form.grossFloorAreaUsedForFoodPreparation_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'grossFloorAreaUsedForFoodPreparation_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.grossFloorAreaUsedForFoodPreparation_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.grossFloorAreaUsedForFoodPreparation_date === '' ? "valid":'invalid'} value={form.grossFloorAreaUsedForFoodPreparation_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'grossFloorAreaUsedForFoodPreparation_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Gross Floor Area Used For Hotel Conference Space" labelPlacement="stacked" type="number" errorText={errors.grossFloorAreaHotelConferenceSpace === '' ? "valid":'invalid'}  value={form.grossFloorAreaHotelConferenceSpace} placeholder='Hotel Conference Space Area' onIonInput={e => (handleFormChange(e.detail.value!,'grossFloorAreaHotelConferenceSpace'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.grossFloorAreaHotelConferenceSpace_units === '' ? "valid":'invalid'} value={form.grossFloorAreaHotelConferenceSpace_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'grossFloorAreaHotelConferenceSpace_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.grossFloorAreaHotelConferenceSpace_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.grossFloorAreaHotelConferenceSpace_date === '' ? "valid":'invalid'} value={form.grossFloorAreaHotelConferenceSpace_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'grossFloorAreaHotelConferenceSpace_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default Hotel