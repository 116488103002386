import React from "react";
import AE2AccountForm from "../../forms/AE2AccountForm";
import { IonCol, IonRow } from "@ionic/react";
import { Redirect } from "react-router";
import usePermissionGroups from "../../../utils/usePermissionGroups";

const NewAE2Account = () => {
  const {hasPermission} = usePermissionGroups()

  if(!hasPermission(['createAE2CustomerAccount'])){
    return <Redirect to="/403"/>
  }

  return (
    <IonRow className="ion-justify-content-center">
      <IonCol sizeMd="5">
        <h1 style={{ textAlign: "center" }}>Create a new AE2 account</h1>
        <AE2AccountForm />
      </IonCol>
    </IonRow>
  );
};

export default NewAE2Account;
