import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useHistory } from "react-router";
import { API_URL } from "../../actions/settings";
import NotificationPopUp from "../extras/NotificationPopUp";
import "./BaselineTargetForm.css";
import useForm from "../../utils/useForm";
import { MdWarning } from "react-icons/md";
import AE2Input from "../elements/AE2Input";

async function setBaseline(token: any, id: any, data: any) {
  return fetch(`${API_URL}/energystar/property/${id}/baseline-target`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}
interface Props {
  id: any;
  close: any;
}

const startingForm = {
  month: "",
  year: "",
  metric: "",
  target: 0,
  autoset: false,
};
const startingErrors = {
  month: "",
  year: "",
  metric: "Required",
  target: "",
};

const BaselineTargetForm: React.FC<Props> = ({ id, close }) => {
  const validate = (name: string, val: any) => {
    switch (name) {
      case "month":
        if (!form.autoset && val === "") {
          return { month: "Select a Month" };
        } else {
          return { month: "" };
        }
      case "year":
        if (!form.autoset && val === "") {
          return { year: "Year cannot be empty" };
        } else if (!form.autoset && val > getCurrentDate().getFullYear) {
          return { year: "Invalid Year" };
        } else if (!form.autoset && val < 1900) {
          return { year: "Invalid Year" };
        } else {
          return { year: "" };
        }
      case "metric":
        if (val === "") {
          return { metric: "Select A Metric" };
        } else if (val === "No Target") {
          handleFormChange(0, "target");
          form.target = "";
          return { metric: "" };
        } else {
          return { metric: "" };
        }
      case "target":
        if (val === "" && form.metric !== "No Target") {
          return { target: "Target Cannot Be Empty" };
        }
        if (val < 0) {
          return { target: "Target Must Be a Positive Number" };
        } else {
          return { target: "" };
        }
      case "autoset":
        if (val) {
          return {
            month: "",
            year: "",
          };
        } else {
          return {
            month: "Required",
            year: "Required",
          };
        }
      default:
        break;
    }
  };

  const { form, handleFormChange, errors, resetForm } = useForm(
    startingForm,
    startingErrors,
    validate
  );
  const history = useHistory();

  const handleSubmit = async () => {
    if (form.metric === "No Target") {
      form.target = "";
    }
    const token = localStorage.getItem("token") || "";
    const res = await setBaseline(`Token ${token.slice(1, -1)}`, id, form);
    if (res.status === "success") {
      NotificationPopUp("success", res.detail);
      resetForm();
      close();
      history.push(`/energystar/properties/${id}`);
    } else {
      NotificationPopUp(
        "error",
        res.detail || res.details || res.ERROR,
        res.error || "Could Not Set Baseline and Target"
      );
    }
  };

  const getCurrentDate = () => {
    return new Date();
  };

  return (
    <IonContent className="baseline-content">
      <form>
        <IonGrid>
          <IonRow
            style={{
              color: "Orange",
              fontSize: "1.2em",
              marginBottom: "1.5em",
            }}
            className="ion-justify-content-center"
          >
            <MdWarning />
            <span>
              {" "}
              Baseline and Target will be set in AE2 and Energy Star.{" "}
            </span>
          </IonRow>
          <IonRow>
            <IonHeader>Baseline:</IonHeader>
          </IonRow>
          <IonRow>
            <IonCol size="6">
              <IonItem lines="none">
                <IonSelect
                  className={errors.month === "" ? "valid" : "ion-invalid ion-touched"}
                  label="Month"
                  labelPlacement="floating"
                  aria-label="month"
                  disabled={form.autoset}
                  value={form.month}
                  interface="popover"
                  placeholder="Month"
                  onIonChange={(e: any) =>
                    handleFormChange(e.detail.value!, "month")
                  }
                >
                  <IonSelectOption value="1">1-January</IonSelectOption>
                  <IonSelectOption value="2">2-February</IonSelectOption>
                  <IonSelectOption value="3">3-March</IonSelectOption>
                  <IonSelectOption value="4">4-April</IonSelectOption>
                  <IonSelectOption value="5">5-May</IonSelectOption>
                  <IonSelectOption value="6">6-June</IonSelectOption>
                  <IonSelectOption value="7">7-July</IonSelectOption>
                  <IonSelectOption value="8">8-August</IonSelectOption>
                  <IonSelectOption value="9">9-September</IonSelectOption>
                  <IonSelectOption value="10">10-October</IonSelectOption>
                  <IonSelectOption value="11">11-November</IonSelectOption>
                  <IonSelectOption value="12">12-December</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol size="6">
              <IonItem lines="none">
                <AE2Input
                  label='Year'
                  labelPlacement='floating'
                  aria-labelledby="year"
                  type="number"
                  disabled={form.autoset}
                  placeholder="Year"
                  value={form.year}
                  onIonInput={(e: any) =>
                    handleFormChange(e.detail.value!, "year")
                  }
                  errorText={errors.year}
                ></AE2Input>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonItem>
                <IonCheckbox
                  checked={form.autoset}
                  labelPlacement="start"
                  mode="ios"
                  onIonChange={(e: any) =>
                    handleFormChange(e.detail.checked, "autoset")
                  }
                >
                  Auto Generate Baseline Date:
                </IonCheckbox>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonHeader>Target:</IonHeader>
          </IonRow>
          <IonRow>
            <IonCol size="8">
              <IonItem lines="none">
                <IonSelect
                label='Metric'
                labelPlacement='floating'
                  className={errors.metric === "" ? "valid" : "ion-invalid ion-touched"}
                  aria-label="Metric"
                  value={form.metric}
                  interface="popover"
                  placeholder="Metric"
                  onIonChange={(e: any) =>
                    handleFormChange(e.detail.value!, "metric")
                  }
                >
                  <IonSelectOption>No Target</IonSelectOption>
                  <IonSelectOption>Target ENERGY STAR Score</IonSelectOption>
                  <IonSelectOption>
                    Target % Better than Baseline
                  </IonSelectOption>
                  <IonSelectOption>Target % Better than Median</IonSelectOption>
                </IonSelect>
                <div className="error-detail">{errors.metric}</div>
              </IonItem>
            </IonCol>
            <IonCol size="4">
              <IonItem lines="none">
                <AE2Input
                  label='Target'
                  labelPlacement='floating'
                  aria-labelledby="year"
                  disabled={form.metric === "No Target" ? true : false}
                  min="0"
                  type="number"
                  placeholder="Target"
                  value={form.target}
                  onIonChange={(e: any) =>
                    handleFormChange(e.detail.value!, "target")
                  }
                  errorText={errors.target}
                ></AE2Input>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className="baseline-button">
            <IonButton onClick={handleSubmit}>Submit</IonButton>
          </IonRow>
        </IonGrid>
      </form>
    </IonContent>
  );
};

export default BaselineTargetForm;
