import {  IonRow, IonContent, IonButton } from "@ionic/react"
import React, { useState } from "react"
import Ae2Header from "../../components/AE2/Ae2Header"
import EnergyPerformanceDefault from "../../components/AE2/EnergyPerformance/EnergyPerformanceDefault"
import EnergyPerformanceMenu from "../../components/AE2/EnergyPerformance/EnergyPerformanceMenu"
import EnergyTargets from '../../components/AE2/EnergyPerformance/EnergyTargets'
import "./EnergyPerformance.css"
import CostAvoidanceDashboard from "../../components/AE2/AdminSection/CostAvoidanceDashboard"
import BillsCostAvoidanceDashboard from "../../components/AE2/EnergyPerformance/BillsCostAvoidanceDashboard"


const EnergyPerformance: React.FC= () => {
    type Choices = 'home' | 'targets' |'costAvoidance' | 'UtilityBills'

    const [selectedPage,setSelectedPage] = useState<Choices> ('home')
    let params =  new URLSearchParams(window.location.search);
    const id = params.get('id')


    function selectPage(){
        switch(selectedPage){
            case 'targets':
                return <EnergyTargets />
            case 'costAvoidance':
                return (<CostAvoidanceDashboard id={id} singleBuilding={true}/>)
            case 'UtilityBills':
                return(<BillsCostAvoidanceDashboard id ={id} singleBuilding={true}/>)
            default:
                 return <EnergyPerformanceDefault/>

        }
    }

    return (
    <IonContent className='energy-performance main-background'>
    <Ae2Header/>

    <div>
        <IonRow  class="ion-justify-content-between button-bottom-right"><h1 className="main-heading">Energy Performance Details</h1>
        <IonButton href={`/?id=${id}&sec=buildings`}> Building Page</IonButton></IonRow>
        <EnergyPerformanceMenu page={selectedPage} setPage={setSelectedPage}/>
    </div>
    {selectPage()}

    </IonContent>)
}

export default EnergyPerformance