import { IonLabel, IonItem, IonModal, IonTitle, IonButton, IonHeader} from "@ionic/react"
import { useEffect, useState } from "react"
import { API_URL } from "../../../../actions/settings"
import NotificationPopUp from "../../../extras/NotificationPopUp"
import useHandleState from "../../../../utils/useHandleState"
import IDSearchSelectInput from "../../../extras/IDSearchSelectInput"
import AE2Input from "../../../elements/AE2Input"

interface Props{
    opened:boolean,
    setShowUnitsModal:any,
    updateCategories:any,
    edit:any
}

async function addUnits(data:any,token:any) {

    return fetch(`${API_URL}/ae2_energy_target_units`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(data)

    })
      .then(data => data.json())
   }

async function editUnit(data:any,token:any) {

    return fetch(`${API_URL}/ae2_energy_target_units`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify(data)

    })
      .then(data => data.json())
   }

async function getCategories(token:any) {

    return fetch(`${API_URL}/ae2_eng_unit_categories`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      },

    })
      .then(data => data.json())
   }


const startForm = {
    'name':"",
    'category':"",
    'display_name':"",
    'description':"",
    'conversion_factor':null,
    'type_system':null,
    'prefix_factor':null
}
const errorsForm:any = {
    'name':"",
    'category':"",
    'display_name':"",
    'description':"",
    'conversion_factor':"",
    'type_system':"",
    'prefix_factor':""
}

const EnergyUnitsForm:React.FC<Props> = ({opened,setShowUnitsModal,updateCategories,edit}) =>{
    const [form, setForm] = useState<any>(startForm)
    const [errors, setErrors] = useState(errorsForm)
    const [toEdit,setToEdit] = edit

    const [Categories, handleCategories] = useHandleState([])

    useEffect(()=>{
        const token = localStorage.getItem('token')
        handleCategories(token,getCategories)
        if(toEdit){
            setErrors(errorsForm)
            setForm({
                'id':toEdit['id'],
                'name':toEdit['name'],
                'category':toEdit['category'],
                'display_name':toEdit['display_name'],
                'description':toEdit['description'],
                'conversion_factor':toEdit['conversion_factor'],
                'type_system':toEdit['type_system'],
                'prefix_factor':toEdit['prefix_factor']
            })
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[toEdit])

    const validate = (name:string, val:any) => {
        switch (name) {
            case 'category':
                if(val === null || val.length <1){
                    return {category:'Category Cannot Be Empty'}
                }
                else{
                    return {category:''}
                }
            case 'name':
                if(val === null || val.length <1){
                    return {name:'Unit Name Cannot Be Empty'}
                }
                else{
                    return {name:''}
                }
            case 'display_name':
                if(val === null || val.length <1){
                    return {display_name:'Unit Display Name Cannot Be Empty'}
                }
                else{
                    return {display_name:''}
                }

            default:
                break;
        }
    }

    const validateAll = (form:any) => {
        let validation = errorsForm
        let valid = true
        for (let item in form){

            validation = {...validation, ...validate(item,form[item])}

        }
        setErrors({...errors,...validation})

        for(let val in validation){
            if (validation[val] !== ''){
                valid = false;
            }

        }

        return valid
        }

    const handleFormChange = (value:any,name:string) => {
        setErrors({...errors, ...validate(name,value) })
        setForm((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
    }

    const handleSubmit = async (form:any) => {
        const token:any = localStorage.getItem('token')
        const valid = validateAll(form) || false
        if(valid){
        const res = await addUnits(form,`Token ${token.slice(1,-1)}`)
            if (res.status === "success"){
                setForm(startForm)
                setShowUnitsModal(false)
                updateCategories()
                NotificationPopUp('success','Unit Added')

            }
            else{
                NotificationPopUp('Error',res.detail||res.ERROR,res.error)
            }
        }
    }

    const handleSubmitEdit = async (form:any) => {
        const token:any = localStorage.getItem('token')
        const valid = validateAll(form) || false
        if(valid){
        const res = await editUnit(form,`Token ${token.slice(1,-1)}`)
            if (res.status === "success"){
                setForm(startForm)
                setShowUnitsModal(false)
                updateCategories()
                setToEdit(false)
                NotificationPopUp('success','Unit Edited')

            }
            else{
                NotificationPopUp('Error',res.detail||res.ERROR,res.error)
            }
        }
    }

    return (
        <IonModal id="unitsModal" isOpen={opened} onDidDismiss={()=>{setShowUnitsModal(false);setToEdit(false);setForm(startForm)}}>
                <IonHeader><IonTitle>{toEdit ? "Edit Unit" : "Add Units"} </IonTitle></IonHeader>
                    <form>
                        <IonItem>
                            <IonLabel position="stacked">Unit Category:</IonLabel>
                            <IDSearchSelectInput disabled={toEdit} dict={Categories} dict_key="id" value="name" handleFormChange={handleFormChange} errors={errors.category} form={form} formName="category" placeholder="Category"/>
                        </IonItem>
                        <IonItem>
                            <AE2Input label='Unit Name:' labelPlacement='stacked'  errorText={errors.name} value={form.name} placeholder="Units" onIonInput={(e)=> handleFormChange(e.detail.value!,'name')}></AE2Input>
                        </IonItem>
                        <IonItem>
                            <AE2Input label='Display Name:' labelPlacement='stacked'  errorText={errors.display_name} value={form.display_name} placeholder="Display Name" onIonInput={(e)=> handleFormChange(e.detail.value!,'display_name')}></AE2Input>
                        </IonItem>
                        <IonItem>
                            <AE2Input label='Description:' labelPlacement='stacked'  errorText={errors.description} value={form.description} placeholder="Description" onIonInput={(e)=> handleFormChange(e.detail.value!,'description')}></AE2Input>
                        </IonItem>
                        <IonItem>
                            <AE2Input label='Conversion Factor:' labelPlacement='stacked' errorText={errors.conversion_factor} type="number" value={form.conversion_factor} placeholder="Conversion Factor" onIonInput={(e)=> handleFormChange(e.detail.value!,'conversion_factor')}></AE2Input>
                        </IonItem>
                        <IonItem>
                            <AE2Input label='Type System:' labelPlacement='stacked'  errorText={errors.type_system} value={form.type_system} type="number" placeholder="Type System" onIonInput={(e)=> handleFormChange(e.detail.value!,'type_system')}></AE2Input>
                        </IonItem>
                        <IonItem>
                            <AE2Input label='Prefix Factor:' labelPlacement='stacked'errorText={errors.prefix_factor} value={form.prefix_factor} type="number" placeholder="Prefix Factor" onIonInput={(e)=> handleFormChange(e.detail.value!,'prefix_factor')}></AE2Input>
                        </IonItem>

                    </form>
                    <div className='buttons'>
                    { toEdit ?
                    <IonButton onClick={()=>handleSubmitEdit(form)}>Edit</IonButton> :
                    <IonButton onClick={()=>handleSubmit(form)} >Add</IonButton>}
                    <IonButton onClick={()=>setShowUnitsModal(false)} color='danger'>cancel</IonButton>
                    </div>
            </IonModal>
    )
}

export default EnergyUnitsForm