import { IonList, IonItem, IonLabel, IonItemSliding, IonIcon } from '@ionic/react';
import '../AE2/Ae2Navs.css'

import $ from 'jquery';
import ItemList from './ItemList';
import { businessOutline, businessSharp, documentTextOutline, documentTextSharp, peopleOutline, peopleSharp, schoolOutline, schoolSharp } from 'ionicons/icons';
import Logout from '../auth/Logout';
import usePermissionGroups from '../../utils/usePermissionGroups';
import { useAppSelector } from '../../Hooks';
import { selectUser } from '../../reducers/UserReducer';
import { isStaff } from '../extras/Functions';
import { useEffect, useState } from 'react';

const slidingButtons = [
  {
    title: "Customers",
    url:'/customers',
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
    customerAcc: false
  },
  {
    title: "Campuses",
    url:'/campuses',
    iosIcon: schoolOutline,
    mdIcon: schoolSharp,
    customerAcc: false
  },
  {
    title:'Buildings',
    url:'/buildings',
    iosIcon: businessOutline,
    mdIcon: businessSharp,
    customerAcc: true
  }
];

const buttons =[
    {
      title: "One Page Proposal",
      url:'/one_page_proposal',
      iosIcon: documentTextOutline,
      mdIcon: documentTextSharp,
      permissions:['OnePageProposalCreateEditDelete']
    }
]



const Ae2Navs: React.FC = () => {
  const user = useAppSelector(selectUser)
  const [showButtons, setShowButtons] = useState<any>([])

    const showList = (name: string) => {
      const list = $(`.${name}-list`)
      // const icon = $(`.${name}-block`)
      // icon.animate({rotate:'135deg'})
      list.slideToggle();
    }

    const {hasPermission} = usePermissionGroups()

    useEffect(()=>{
      if(user.username !== ''){
      if(isStaff(user)){
        setShowButtons(slidingButtons)
      }else{
        setShowButtons(slidingButtons.filter(b=>b.customerAcc))
      }
      }
    },[user])

    return (
      <>
      <IonList id="labels-list" className='labels-list'>
        <IonItemSliding>
          {showButtons.map((button:any,index:number) => {
            return(
            <div key={'list'+index} className={button.title+'-block'}>

              <IonItem button detail key={index} onClick={() => showList(button.title)}>
              <IonIcon slot="start" ios={button.iosIcon} md={button.mdIcon} />
              <IonLabel>{button.title}</IonLabel>
              </IonItem>

              <ItemList title={button.title}/>

            </div>)


})}

          </IonItemSliding>
          {buttons.map((button,index)=>( hasPermission(button.permissions) ?
            <div key={'button'+index} className={button.title+'-block'}>

            <IonItem button href={button.url}>
            <IonIcon slot="start" ios={button.iosIcon} md={button.mdIcon} />
            <IonLabel>{button.title}</IonLabel>
            </IonItem>

          </div> : null
          ))}
        </IonList>
        <Logout/>
        </>
    );
  };

  export default Ae2Navs;
