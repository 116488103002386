import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react';
import { useLocation } from 'react-router-dom';
import './Menu.css';
import Ae2Navs from './AE2/Ae2Navs';
import User from './User';
import { ReactComponent as AE2Icon } from '../assets/alpha.svg'
import EstarNavs from './Energystar/EstarNavs';
import { useEffect, useState } from 'react';
import AccountNavs from './AE2/AccountNavs';
import { isStaff } from './extras/Functions';
import { useAppSelector } from '../Hooks';
import { selectUser } from '../reducers/UserReducer';
// import CustomerNavs from './AE2/CustomerNavs';

interface AppPage {
  url: string;
  iconUrl: string;
  title: string;
  staff: boolean
}

interface props{
  token: any
}

const appPages: AppPage[] = [
  {
    title: 'Home',
    url: '/home',
    iconUrl:'../assets/alpha.svg',
    staff: false
  },
  {
    title: 'EnergyStar',
    url: '/energystar',
    iconUrl:'../assets/alpha.svg',
    staff:true
  },
  {
    title: 'REAPR - Analytics',
    url: '/reapr',
    iconUrl:'../assets/es.png',
    staff:true
  },
  {
    title: 'Analytics',
    url: '/analytics',
    iconUrl:'../assets/es.png',
    staff:true
  },
];

const Menu: React.FC<props> = ({token}) => {
  const location = useLocation();
  const user = useAppSelector(selectUser)

  const [inAE2, setInAE2 ] = useState(false)
  const [inEStar, setInEStar] = useState(false)
  const [inReapr, setInReapr] = useState(false)
  const [inAccount, setInAccount] = useState(false)

  useEffect(()=>{
    const pages = ['energystar','reapr','account']
    setInAE2(!pages.some(word => location.pathname.includes(word)))
    setInEStar(location.pathname.includes('energystar'))
    setInReapr(location.pathname.includes('reapr'))
    setInAccount(location.pathname.includes('account'))
  },[location.pathname])

  const handleClick = () =>{
    window.location.href = '/'
  }

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="menu-list">
          <div className='logo-container'>
            <AE2Icon className='ae2-logo' onClick={() => handleClick()}/>
            <User/>
          </div>

          {appPages.map((appPage, index) => {

            if(!appPage.staff || (appPage.staff && isStaff(user))){
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  {/* <IonIcon slot="start" src={appPage.iconUrl}/> */}
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>

            );
            }
            else{
              return null
            }
          })}
           {/* <IonMenuToggle hidden={!isStaff(user)} autoHide={false}>
                <IonItem className={location.pathname === '/analytics' ? 'selected' : ''} routerLink='/analytics' routerDirection="none" lines="none" detail={false}>
                  <IonLabel>Analytics</IonLabel>
                </IonItem>
              </IonMenuToggle> */}

        </IonList>
          {/* {Number(user.role.id) === 4 && !inAccount ?
          <CustomerNavs/> : */}
          <>
          { inAE2 ? <Ae2Navs/>:''}
          { inEStar ? isStaff(user) ? <EstarNavs/> : <Ae2Navs/> : ''}
          { inReapr ? '' : ''}
          { inAccount ? <AccountNavs/> : ''}
          </>
          {/* } */}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
