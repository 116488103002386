import React, { useState } from "react";
import { IonInput } from "@ionic/react";

type CustomInputProps = React.ComponentProps<typeof IonInput> & {
  className?: string;
};

const AE2Input: React.FC<CustomInputProps> = (props) => {
  const [touched, setTouched] = useState(false);

  return (
    <IonInput
      className={`${touched && "ion-touched"} ${
        props.errorText !== "" && "ion-invalid"
      } ${props.className ? props.className : ""}`}
      errorText={props.errorText}
      {...props}
      onIonFocus={(e) => setTouched(true)}
    />
  );
};

export default AE2Input;
