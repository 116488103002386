import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from '../Store';
interface UserState {
    value: {
        first_name:string,
        last_name:string,
        username:string,
        email:string,
        date_joined:string,
        groups:any,
        role:{
            id:string,
            name:string
        },
        customer_id:string|boolean
        reset_password:boolean

    }
}

const initialState: UserState = {
    value: {
        first_name:'',
        last_name:'',
        username:'',
        email:'',
        date_joined:'',
        groups:[],
        role:{id:'',name:""},
        customer_id:'',
        reset_password:true
    }
}

export const UserReducer = createSlice({
    name: "user",
    initialState,
    reducers:{
        setStateUser: (state, action: PayloadAction<any>) => {
            state.value =  action.payload
        }
    }
})

export const {setStateUser} = UserReducer.actions
export const selectUser = (state: RootState) => state.user.value
export const selectUserGroups = (state:RootState) => state.user.value.groups
export default UserReducer.reducer