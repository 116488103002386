import {
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "./SearchSelectInput.css";
import { isMobile } from "react-device-detect";

/* Component used to render a select input with search. This renders a list of dicts.
dict_key is the key_value that will be returned from the selected item
value is the key value that will be displayed to the user */

interface Props {
  dict: any;
  label?: string;
  dict_key: string;
  value: string;
  handleFormChange: any;
  errors: any;
  form: any;
  formName: string;
  placeholder: string;
  disabled?: boolean;
  def_value?: string;
}

const clickInput = (e: any) => {
  if (e.detail.relatedTarget !== null) {
    // e.target.scrollIntoView()
    e.target.click();
  }
};

let startVal = "";

/**
 *
 * @param dict - list of dicts
 * @param dict_key - key used to get the value for the form
 * @param value - key for value that will be displayed to the user
 * @param errors - any errors to show set to "" for no errors
 * @param form - used to save the value
 * @param handleFormChange - function to handle keyboard input
 * @param formName - name of the value used in the form
 * @param placeholder - placeholder value for input
 * @param disabled - disable input, default set to false
 * @param def_value - value for the default selection set to 'None'
 * @returns input and selections
 */
const IDSearchSelectInput: React.FC<Props> = ({
  dict,
  label,
  dict_key,
  value,
  errors,
  form,
  handleFormChange,
  formName,
  placeholder,
  disabled = false,
  def_value = "None",
}) => {
  const [input, setInput] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let editVal = dict.filter(
      (item: any) => item[dict_key] === form[formName]
    )[0];
    if (editVal && value === "campus_name") {
      editVal = dict.filter(
        (item: any) => item[dict_key] === form[formName]
      )[0];
    }
    isMobile
      ? (startVal = editVal ? editVal[dict_key] : "")
      : (startVal = editVal ? editVal[value] : "");
    setInput(startVal);
    setLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  if (!loaded) {
    return <>Loading Selections</>;
  }

  if (isMobile && value !== "campus_name") {
    return (
      <IonItem>
        <IonSelect
          style={{ "max-width": "100%" }}
          aria-labelledby={label}
          disabled={disabled}
          className={errors === "" ? "valid" : "ion-invalid ion-touched"}
          interface="popover"
          value={input}
          placeholder={placeholder}
          onIonChange={(e) => {
            handleFormChange(e.detail.value!, formName);
            setInput(e.detail.value!);
          }}
        >
          {dict.map((item: any) => {
            return (
              <IonSelectOption
                key={item[dict_key] + item[value]}
                value={item[dict_key]}
              >
                {item[value]}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </IonItem>
    );
  }

  if (value === "campus_name") {
    return (
      <>
        <IonInput
          id={`${formName}_input`}
          label={label}
          labelPlacement="floating"
          aria-label="Campus"
          aria-labelledby={label}
          disabled={disabled}
          className={errors === "" ? "valid" : "ion-invalid ion-touched"}
          onIonFocus={(e) => {
            clickInput(e);
          }}
          value={input}
          placeholder={placeholder}
          onIonInput={(e) => {
            setInput(e.detail.value!);
          }}
          errorText={errors}
        ></IonInput>
        <IonPopover
          className="select-popover"
          trigger={`${formName}_input`}
          reference="trigger"
          dismissOnSelect
          showBackdrop={false}
          backdropDismiss
          keyboardClose={true}
          size="cover"
        >
          <IonContent>
            <IonList>
              <IonItem
                key={"none-sel"}
                button
                onClick={() => {
                  handleFormChange("", formName);
                  setInput("None");
                }}
              >
                <IonLabel> None </IonLabel>
              </IonItem>
              {dict
                .filter((item: any) =>
                  item[value].toLowerCase().includes(input.toLowerCase())
                )
                .map((item: any) => {
                  return (
                    <IonItem
                      key={item[dict_key]}
                      button
                      onClick={() => {
                        handleFormChange(item[dict_key], formName);
                        setInput(item[value]);
                      }}
                    >
                      <IonLabel> {item[value]}</IonLabel>
                    </IonItem>
                  );
                })}
            </IonList>
          </IonContent>
        </IonPopover>
      </>
    );
  }
  return (
    <>
      <IonInput
        id={`${formName}_input`}
        aria-labelledby={label}
        label={label}
        labelPlacement="floating"
        disabled={disabled}
        className={errors === "" ? "valid" : "ion-invalid ion-touched"}
        onIonFocus={(e) => {
          clickInput(e);
        }}
        value={input}
        placeholder={placeholder}
        onIonInput={(e) => {
          setInput(e.detail.value!);
        }}
        errorText={errors}
      ></IonInput>
      <IonPopover
        className="select-popover"
        trigger={`${formName}_input`}
        reference="trigger"
        dismissOnSelect
        showBackdrop={false}
        backdropDismiss
        keyboardClose={false}
        size="cover"
        side="bottom"
        alignment="center"
      >
        <IonContent>
          <IonList>
            <IonItem
              key={"none-sel"}
              button
              onClick={() => {
                handleFormChange("", formName);
                setInput("");
              }}
            >
              <IonLabel> {def_value} </IonLabel>
            </IonItem>
            {dict
              .filter((item: any) =>
                item[value].toLowerCase().includes(input.toLowerCase())
              )
              .map((item: any) => {
                return (
                  <IonItem
                    key={item[dict_key]}
                    button
                    onClick={() => {
                      handleFormChange(item[dict_key], formName);
                      setInput(item[value]);
                    }}
                  >
                    <IonLabel> {item[value]}</IonLabel>
                  </IonItem>
                );
              })}
          </IonList>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default IDSearchSelectInput;
