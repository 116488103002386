import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLoading, IonModal, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { useEffect, useState } from "react";
import { API_URL } from "../../../actions/settings";
import { IonIcon } from '@ionic/react';
import { cloudDownload, informationCircle, trashBinSharp } from 'ionicons/icons';
import AE2SoftwareDownload from "../../extras/AE2SoftwareDownload";
import UploadSoftwareReleaseModal from "./UploadSoftwareReleaseModal";
import CheckRole from "../../extras/CheckRole";
import { useAppSelector } from "../../../Hooks";
import { selectUser } from "../../../reducers/UserReducer";
import NotificationPopUp from "../../extras/NotificationPopUp";
import { Redirect } from "react-router";

async function getSoftwareReleases(token:any,id:any) {

    return fetch(`${API_URL}/ae2_software_releases/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }

async function deleteSoftwareRelease(token:any,id:any) {

    return fetch(`${API_URL}/ae2_software_releases/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }


const AE2SoftwareReleases: React.FC = () => {
    const user = useAppSelector(selectUser)
    let params =  new URLSearchParams(window.location.search);
    const software_id = params.get('software_id')
    const [releases,setReleases] = useState<any>([])
    const [loaded,setLoaded] = useState(false)
    const [releaseNotes, setReleaseNotes]=useState('')
    const [modalOpened, setModalOpened]=useState(false)
    const [opened, setOpened] = useState(false)
    const [openedDeleteModal, setOpenedDeleteModal] = useState(false)
    const [toDeleteID,setToDeleteID] = useState<any>()



    useEffect(() => {
        const handleSoftwareReleases = async (token:any) => {
            const res = await getSoftwareReleases(`Token ${token.slice(1,-1)}`,software_id)
            setReleases(res.data)
            setLoaded(true)
        }

        const token = localStorage.getItem('token')
        handleSoftwareReleases(token)
    },[software_id])

    const handleReleaseNotes = (html:any) =>{
        setModalOpened(true)
        setReleaseNotes(html)
    }
    const handleDownload = async (url:any,filename:any) =>{
      await AE2SoftwareDownload(url,filename)

    }
    const handleDeleteModal = async (id:any) =>{
        setOpenedDeleteModal(true)
        setToDeleteID(id)
    }

    const handleDelete = async () =>{
      const token = localStorage.getItem('token') || ""
      const res = await deleteSoftwareRelease(`Token ${token.slice(1,-1)}`,toDeleteID)
      if(res.status==='success'){
        setReleases(releases.filter((release:any) => release.id !== toDeleteID))
        NotificationPopUp('success','Software Deleted')
            setOpenedDeleteModal(false)
            setToDeleteID('')
          return
      }
      NotificationPopUp('Error',res.detail||res.ERROR,res.error)

    }

    // role 4 - Customer
    if(CheckRole(user,[4])){
        return <Redirect to="/403"/>
    }

    return (
        <>{ !loaded ?
            <IonLoading
              cssClass='my-custom-class'
              isOpen={!loaded}
              message={'Loading...'}
              spinner='dots'
              duration={2000}
              />
            :
        <IonRow className="ion-justify-content-center">
            { releases.length === 0 ? (
                <IonRow className="ion-justify-content-center">
                <IonCol size="12">
                  <IonRow className="ion-justify-content-center">
                      <h1>No Releases Available For This Software</h1>
                  </IonRow>
                  <IonRow className="ion-justify-content-center">
                  <IonButton hidden={!CheckRole(user,['Admin'])} color="medium" onClick={()=>setOpened(true)}>Upload Release</IonButton>
                  </IonRow>
                </IonCol>
              </IonRow>
            ) : (<>


            {/* If There are Releases */}
            <IonCol size="12">
                <IonRow className="ion-justify-content-center text-center">
                    <h1 className="main-heading">{releases[0].software.name} Releases</h1>
                </IonRow>
            </IonCol>
            <IonRow className="software-releases-list">
            <IonCol size="12">
                <IonRow className="ion-justify-content-between text-center">
                    <IonButton color="medium" href="/account?tab=software">All Software</IonButton>
                    <IonButton hidden={!CheckRole(user,['Admin'])} color="medium" onClick={()=>setOpened(true)}>Upload Release</IonButton>
                </IonRow>
            </IonCol>
            <IonCol  className="release-list" size="12">
                <IonRow className="ion-justify-content-center table-header">
                    <IonCol size={CheckRole(user,['Admin']) ? "5" :"6"} sizeMd={CheckRole(user,['Admin']) ? "7" :"8"}>
                        <h3>Name:</h3>
                    </IonCol>
                    <IonCol size="3" sizeMd="2" className="text-center">
                        <h3>Release Date:</h3>
                    </IonCol>
                    <IonCol size="1">

                    </IonCol>
                    <IonCol size="1">

                    </IonCol>
                    <IonCol hidden={!CheckRole(user,['Admin'])} size="1">

                    </IonCol>
                </IonRow>
                {releases.map((release:any) => {
                    return(

                        <IonRow className='table-row' key={release.id} >
                            <IonCol size={CheckRole(user,['Admin']) ? "5" :"6"} sizeMd={CheckRole(user,['Admin']) ? "7" :"8"} className="text-center">
                                <h3>{release.name}</h3>
                            </IonCol>
                            <IonCol size="3" sizeMd="2" className="text-center">
                                <p>{release.date}</p>
                            </IonCol>
                            <IonCol size="1.5" sizeMd="1">
                                <IonItem className="list-button" lines="none">
                                    <IonIcon onClick={() => handleReleaseNotes(release.notes)} icon={informationCircle} title='Notes'></IonIcon>
                                </IonItem>
                            </IonCol>
                            <IonCol size="1.5" sizeMd="1">
                                <IonItem className="list-button" lines="none" >
                                    <IonIcon onClick={()=>handleDownload(release.software.path,release.name)} icon={cloudDownload} title='Download'></IonIcon>
                                </IonItem>
                            </IonCol>
                            <IonCol hidden={!CheckRole(user,['Admin'])} size="1.5" sizeMd="1">
                                <IonItem className="list-button" lines="none">
                                    <IonIcon onClick={()=>handleDeleteModal(release.id)} icon={trashBinSharp} title='Delete'></IonIcon>
                                </IonItem>
                            </IonCol>


                        </IonRow>

                    )
                })}
                </IonCol>
            </IonRow>
            </>)}
            <IonModal className="release-notes-modal" isOpen={modalOpened} onDidDismiss={()=>setModalOpened(false)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Release Notes</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setModalOpened(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
          <div dangerouslySetInnerHTML={{ __html: releaseNotes }} />
          </IonContent>
        </IonModal>

        <UploadSoftwareReleaseModal opened={opened} setOpened={setOpened} updateData={[releases,setReleases]}software_id={software_id}/>
        <IonModal isOpen={openedDeleteModal} onDidDismiss={()=>setOpenedDeleteModal(false)} className="confirmDelete">
                <IonGrid>
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="delete-warning">
                                Are You Sure You Want To Delete This?
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonButton color="danger" onClick={()=> handleDelete()} >Delete</IonButton>
                            <IonButton color="medium" onClick={ ()=> setOpenedDeleteModal(false)} >Cancel</IonButton>
                        </IonRow>
                </IonGrid>
            </IonModal>
        </IonRow>
        }</>
    );
  };

  export default AE2SoftwareReleases;
