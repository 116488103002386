const isStaff = (user: any) => {
  if (user.first_name !== "") {
    if (
      user.role.name === "Employee" ||
      user.role.name === "Supervisor" ||
      user.role.name === "Admin"
    ) {
      return true;
    }
    return false;
  }
  return false;
};

function formatISODateString(date: Date | string | null | undefined) {
  // Return formatted string date as mm-dd-yyyy
  if (!date) return null;
  //convert to Date object if string
  if (!(date instanceof Date)) date = new Date(date);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}

export { isStaff, formatISODateString };
