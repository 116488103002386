import { IonButton, IonCol, IonRow } from "@ionic/react"
import { useEffect, useState } from "react"
import { API_URL } from "../../../actions/settings"
import LoadingSpinner from "../../extras/LoadingSpinner"
import NotificationPopUp from "../../extras/NotificationPopUp"
import AE2Input from "../../elements/AE2Input"

interface props{
    months:any,
    noData:boolean,
    targets:any,
    handleTargets:any,
    setShowForm:any
}

async function saveChanges(token:string,id:string,noData:boolean,data:any) {
    return fetch(`${API_URL}/ae2_energy_targets/${id}`, {
        method: noData?'POST':'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        },
        body: JSON.stringify(data)

      })
        .then(data => data.json())
     }



const EnergyTargetForm: React.FC<props>= ({months,targets,noData,handleTargets,setShowForm}) => {
    const [loaded,setLoaded] = useState(false)
    // eslint-disable-next-line
    const [startForm, setStartForm] = useState<any>({})
    const [form, setForm] = useState<any>({})
    let params =  new URLSearchParams(window.location.search);
    const id = params.get('id')

    useEffect(()=>{
        const fm:any = {
            1:{},
            2:{},
            3:{},
            4:{},
            5:{},
            6:{},
            7:{},
            8:{},
            9:{},
            10:{},
            11:{},
            12:{}
        }
        for (let month of months){
            for(let target of Object.keys(targets)){
                noData ? fm[month.number][targets[target][month.number - 1].unit.id]={val:0} :
                targets[target][month.number - 1].value !== null ? fm[month.number][targets[target][month.number - 1].unit.id]={'unit':targets[target][month.number - 1].unit, 'val':targets[target][month.number - 1].value, 'id':targets[target][month.number - 1].id} : fm[month.number][targets[target][month.number - 1].unit.id] = {'unit':targets[target][month.number - 1].unit, 'val':0,  'id':targets[target][month.number - 1].id}
            }
        }
        setStartForm(fm)
        setForm(fm)
        setLoaded(true)
    },[months,targets, noData])

    const handleFormChange = (value:any,month:string,unit:any) => {

        setForm((prevState:any) => {
            return {
                ...prevState,
                [month]:{
                    ...prevState[month],
                    [unit]:{
                        ...prevState[month][unit],
                        val:value
                    }
                }

            }
        } )

    }

    const handleSubmit = async (id:any,handleTargets:any,setShowForm:any) =>{
        const token = localStorage.getItem('token') || ""
        const res = await saveChanges(`Token ${token.slice(1,-1)}`,id,noData,form)
        NotificationPopUp(res.status,res.detail,"Targets Edited")
        handleTargets()
        setShowForm(false)
    }

    const checkValue = (val:number) => {
        return val >= 0
    }

    if(!loaded){
        return <LoadingSpinner/>
    }
    return (
            <>
            {months.map((month:any)=>{
                return(
                    <IonRow className="table-row" key={month.number}>
                        <IonCol size={`${12/(Object.keys(targets).length+1)}`}>{month.string}</IonCol>
                        {Object.keys(targets).map((target:any)=>{
                            return(

                                <IonCol size={`${12/(Object.keys(targets).length+1)}`} key={targets[target][month.number-1].id} className="left-border-grey">
                                    <AE2Input value={form[month.number][targets[target][month.number-1].unit.id].val} errorText={checkValue(form[month.number][targets[target][0].unit.id].val) ? '':'Invalid'} type='number' onIonInput={e=>handleFormChange(e.detail.value!,month.number,[targets[target][month.number - 1].unit.id])} style={{maxWidth:"100%",'--background':'#3d3d3d',textAlign:'center'}}></AE2Input>
                                </IonCol>
                            )
                        })}
                    </IonRow>
                )
            })}
            <IonRow>
                <IonCol>
                    <IonButton expand="full" color="medium" onClick={()=>handleSubmit(id,handleTargets,setShowForm)}>Save</IonButton>
                </IonCol>
            </IonRow>
    </>
    )
}

export default EnergyTargetForm