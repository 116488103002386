import DataTable from 'react-data-table-component';
import React, { useState } from 'react';
import './EstarBills.css';
import { CSVLink } from 'react-csv';
import { IonButton, IonIcon, IonItem, IonRow } from '@ionic/react';
import { trashOutline, trashSharp } from "ionicons/icons"
import { API_URL } from '../../actions/settings';
import NotificationPopUp from '../extras/NotificationPopUp';
import { isStaff } from '../extras/Functions';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/UserReducer';

interface Props{
    id:any
    name:string
    data:any
}

async function deleteBill(token:any,id:any) {

    return fetch(`${API_URL}/energystar/meters/bills/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
      .then(data => data.json())
   }



const EstarBills : React.FC<Props> = ({id,name,data}) =>{

    const [bills, setBills] = useState(data)
    const user = useSelector(selectUser)

    const handleDelete = async (e:any) =>{
        if (e.target.id !== ''){
            const token = localStorage.getItem('token') || ""
            const res = await deleteBill(`Token ${token.slice(1,-1)}`,e.target.id)
            if (res.status === "success"){
                NotificationPopUp('success','Consumption Data Deleted')
                setBills(
                    (prevState:any) => prevState.filter((bill:any) => bill.id !== e.target.id)
                )

            }
            else{
                NotificationPopUp('error',res.detail,res.error||"Could Not Delete")
            }
        }
    }

    const delete_button = (id:any) => <IonItem className='del-button' onClick={handleDelete}><IonButton id={id} color='danger' size='small'><IonIcon id={id} ios={trashOutline} md={trashSharp}/></IonButton></IonItem>

    const columns = [
        {
            name: 'Start Date',
            selector: (row:any) => {
                const date = new Date(row.startdate)
                return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`
            },
            sortable: true,
            sortFunction: (rowA:any, rowB:any) => {
                const dateA = new Date (rowA.startdate)
                const dateB = new Date (rowB.startdate)
                return dateA.getTime() - dateB.getTime()
            }
        },
        {
            name: 'End Date',
            selector: (row:any) => {
                const date = new Date(row.enddate)
                return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`
            },
            sortable: true,
            sortFunction: (rowA:any, rowB:any) => {
                const dateA = new Date (rowA.enddate)
                const dateB = new Date (rowB.enddate)
                return dateA.getTime() - dateB.getTime()
            }
        },
        {
            name: ' Usage',
            selector: (row:any) => (row.usage).toLocaleString(undefined, { maximumFractionDigits: 2 }),
            sortable: false,
        },
        {
            name: 'Cost',
            cell: (row:any) =>  row.cost ? `$${(row.cost).toLocaleString(undefined, { maximumFractionDigits: 2 })}` : '',
            sortable: false,
        },
        {
            name: 'Demand',
            cell: (row:any) =>  row.demand ? `${(row.demand).toLocaleString(undefined, { maximumFractionDigits: 2 })}` : '',
            sortable: false,
        },
        {
            name: 'Demand Cost',
            cell: (row:any) =>  row.demand_cost ? `$${(row.demand_cost).toLocaleString(undefined, { maximumFractionDigits: 2 })}` : '',
            sortable: false,
        },
        {
            name: "Delete",
            cell: (row:any) => isStaff(user) ? delete_button(row.id): '',
            sortable: false,
        }
    ];
const headers = [
        { label: "Start Date", key: "startdate" },
        { label: "End Date", key: "enddate" },
        { label: "Usage", key: "usage" },
        { label: "Cost", key: "cost"},
        { label: "Demand", key: "demand"},
        { label: "Demand Cost", key: "demand_cost"}
      ];
    return (
        <>
        {isStaff(user) ?
        <IonRow className='bills-buttons' >
            <IonButton href={`/energystar/meters/${id}/bills`}>Add Consumption Data</IonButton>
            <IonButton><CSVLink className='btn' data={bills} headers={headers} separator=',' filename={`${name}_consumption`}>
                Download Full CSV
            </CSVLink></IonButton>
        </IonRow>
        : null }
        <DataTable
          title="Consumption Data"
          columns={isStaff(user) ? columns : columns.slice(0,-1)}
          data={bills}
          pagination
          theme='dark'

        />
        </>

    )
}

export default EstarBills