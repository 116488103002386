import React from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
} from "@tanstack/react-table";
import { IonIcon } from "@ionic/react";
import { caretDownCircle, caretUpCircle } from "ionicons/icons";

interface Props {
  data: any;
}

const DateCell = (date: string | null) => {
  if (!date) return null;

  try {
  const j = date.split('-')
  const d = new Date(parseInt(j[0]),parseInt(j[1]),parseInt(j[2]));
  return `${d.getMonth()}/${d.getFullYear()}`;
  }
  catch (e){
    return null
  }
};

const dateSorting = (rowA: any, rowB: any, columnId: any) =>
  new Date(rowA.getValue(columnId)) < new Date(rowB.getValue(columnId))
    ? 1
    : -1;

const DegreeDaysLookupTable: React.FC<Props> = ({ data }) => {
  const columns = [
    {
      accessorKey: "station_id",
      header: "Station",
      cell: (props: any) => (
        <div className="center-all">{props.getValue()}</div>
      ),
      size: 200,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableSorting: false,
    },
    {
      accessorKey: "zipcode",
      header: "Zip Code",
      cell: (props: any) => (
        <div className="center-all">{props.getValue()}</div>
      ),
      size: 200,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableSorting: false,
    },
    {
      accessorKey: "date",
      header: "Date",
      cell: (props: any) => (
        <div className="center-all">{DateCell(props.getValue())}</div>
      ),
      size: 200,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableSorting: true,
      sortingFns: dateSorting,
    },
    {
      accessorKey: "hdd",
      header: "HDD",
      cell: (props: any) => (
        <div className="center-all">{props.getValue()}</div>
      ),
      size: 200,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableSorting: false,
    },
    {
      accessorKey: "cdd",
      header: "CDD",
      cell: (props: any) => (
        <div className="center-all">{props.getValue()}</div>
      ),
      size: 200,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableSorting: false,
    },
  ];

  const table = useReactTable({
    data: data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  return (
    <div
      className="stories-table"
      style={{ width: table.getTotalSize(), maxWidth: "100%" }}
    >
      {table.getHeaderGroups().map((headerGroup: any) => (
        <div key={headerGroup.id} className="tr">
          {headerGroup.headers.map((header: any) => {
            return (
              <div
                key={header.id}
                className="th"
                style={{ width: header.getSize() }}
                onClick={
                  header.column.getCanSort()
                    ? header.column.getToggleSortingHandler()
                    : null
                }
              >
                {header.column.columnDef.header}
                {header.column.getIsSorted() === "desc" ? (
                  <IonIcon
                    style={{ marginLeft: "0.2em" }}
                    icon={caretDownCircle}
                    slot="icon-only"
                  />
                ) : (
                  ""
                )}
                {header.column.getIsSorted() === "asc" ? (
                  <IonIcon
                    style={{ marginLeft: "0.2em" }}
                    icon={caretUpCircle}
                    slot="icon-only"
                  />
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      ))}
      {table.getRowModel().rows.map((row: any, idx: number) => (
        <div
          key={row.id}
          className={`tr`}
          style={{
            width: table.getTotalSize(),
            backgroundColor: idx % 2 ? "#353935" : "",
          }}
        >
          {row.getVisibleCells().map((cell: any) => {
            return (
              <div
                key={cell.id}
                className="td"
                style={{ width: cell.column.getSize() }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default DegreeDaysLookupTable;
