import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import { API_URL } from "../../actions/settings";
import AE2CustomerInfo from "../../components/AE2/AE2CustomerInfo";
import LoadingSpinner from "../../components/extras/LoadingSpinner";
import useToggle from "../../utils/useToggle";

async function getCustomer(token: any, uuid: any) {
  return fetch(`${API_URL}/customers/${uuid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

const CustomerHomepage: React.FC = () => {
  const user = useAppSelector(selectUser);
  const [customer, setCustomer] = useState("");
  const [loading, loaded] = useToggle(true);

  useEffect(() => {
    const handleCustomer = async () => {
      const tkn: any = localStorage.getItem("token");
      const token = `Token ${tkn.slice(1, -1)}`;
      const res = await getCustomer(token, user.customer_id);
      setCustomer(res.data);
      loaded();
    };
    if (user.customer_id) {
      handleCustomer();
    }
  }, [user, loaded]);

  return (
    <div>
      {user.customer_id ? (
        loading ? (
          <LoadingSpinner />
        ) : (
          <AE2CustomerInfo item={customer} />
        )
      ) : (
        <div> Your Account Has Not Been Linked To A Customer Account. </div>
      )}
    </div>
  );
};

export default CustomerHomepage;
