import { IonCol, IonIcon, IonItem, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { useEffect, useState } from "react"
import {star, starOutline} from 'ionicons/icons'
import AE2Input from "../../elements/AE2Input"

interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
totalGrossFloorArea : "",
totalGrossFloorArea_units : "",
totalGrossFloorArea_date : "",
numberOfCashRegisters : "",
numberOfCashRegisters_date : "",
numberOfWalkInRefrigerationUnits : "",
numberOfWalkInRefrigerationUnits_date : "",
weeklyOperatingHours : "",
weeklyOperatingHours_date : "",
numberOfComputers : "",
numberOfComputers_date : "",
numberOfOpenClosedRefrigerationUnits : "",
numberOfOpenClosedRefrigerationUnits_date : "",
cookingFacilities : "",
cookingFacilities_date : "",
percentCooled : "",
percentCooled_date : "",
percentHeated : "",
percentHeated_date : "",
areaOfAllWalkInRefrigerationUnits : "",
areaOfAllWalkInRefrigerationUnits_units : "",
areaOfAllWalkInRefrigerationUnits_date : "",
lengthOfAllOpenClosedRefrigerationUnits : "",
lengthOfAllOpenClosedRefrigerationUnits_units : "",
lengthOfAllOpenClosedRefrigerationUnits_date : "",
numberOfWorkers : "",
numberOfWorkers_date : "",
singleStore : "",
singleStore_date : "",
exteriorEntranceToThePublic : "",
exteriorEntranceToThePublic_date : "",
}
const startErrors = {
totalGrossFloorArea : "GFA cannot be empty",
totalGrossFloorArea_units : "Units cannot be empty",
totalGrossFloorArea_date : "Date cannot be empty",
numberOfCashRegisters : "",
numberOfCashRegisters_date : "",
numberOfWalkInRefrigerationUnits : "",
numberOfWalkInRefrigerationUnits_date : "",
weeklyOperatingHours : "",
weeklyOperatingHours_date : "",
numberOfComputers : "",
numberOfComputers_date : "",
numberOfOpenClosedRefrigerationUnits : "",
numberOfOpenClosedRefrigerationUnits_date : "",
cookingFacilities : "",
cookingFacilities_date : "",
percentCooled : "",
percentCooled_date : "",
percentHeated : "",
percentHeated_date : "",
areaOfAllWalkInRefrigerationUnits : "",
areaOfAllWalkInRefrigerationUnits_units : "",
areaOfAllWalkInRefrigerationUnits_date : "",
lengthOfAllOpenClosedRefrigerationUnits : "",
lengthOfAllOpenClosedRefrigerationUnits_units : "",
lengthOfAllOpenClosedRefrigerationUnits_date : "",
numberOfWorkers : "",
numberOfWorkers_date : "",
singleStore : "",
singleStore_date : "",
exteriorEntranceToThePublic : "",
exteriorEntranceToThePublic_date : "",
}

const Retail: React.FC<Props> = ({setParentForm,formErrors}) => {

    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case ('weeklyOperatingHours'):
                if (value < 0){
                    handleError('weeklyOperatingHours','Hours must be a positive number')
                }
                else if (value > 168){
                    handleError('weeklyOperatingHours','Hours mush be less than 168')
                }
                else {
                    handleError('weeklyOperatingHours','')
                }
                if( value !== '' && form.weeklyOperatingHours_date === ''){
                    handleError('weeklyOperatingHours_date','Date cannot be empty')
                }
                if( value === '' && form.weeklyOperatingHours_date === ''){
                    handleError('weeklyOperatingHours_date','')
                }
                break;
            case ('weeklyOperatingHours_date'):

                if (form.weeklyOperatingHours !== ''){
                    if (value === ''){
                        handleError('weeklyOperatingHours_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('weeklyOperatingHours_date','Invalid Date')
                    }
                    else {
                        handleError('weeklyOperatingHours_date','')
                    }
                }
                break;
            case ('numberOfComputers'):
                if (value < 0){
                    handleError('numberOfComputers','Number of computers must be a positive number')
                }
                else {
                    handleError('numberOfComputers','')
                }
                if( value !== '' && form.numberOfComputers_date === ''){
                    handleError('numberOfComputers_date','Date cannot be empty')
                }
                if( value === '' && form.numberOfComputers_date === ''){
                    handleError('numberOfComputers_date','')
                }
                break;
            case ('numberOfComputers_date'):
                if (form.numberOfComputers !== ''){
                    if (value === ''){
                        handleError('numberOfComputers_date','Date cannot be empty')
                    }
                    else {
                        handleError('numberOfComputers_date','')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfComputers_date','Invalid Date')
                    }
                else {
                        handleError('numberOfComputers_date','')
                    }

                break;
            case ('numberOfOpenClosedRefrigerationUnits'):
                if (value < 0){
                    handleError('numberOfOpenClosedRefrigerationUnits','Must be a positive number')
                }
                else {
                    handleError('numberOfOpenClosedRefrigerationUnits','')
                }
                if( value !== '' && form.numberOfOpenClosedRefrigerationUnits_date === ''){
                    handleError('numberOfOpenClosedRefrigerationUnits_date','Date cannot be empty')
                }
                if( value === '' && form.numberOfOpenClosedRefrigerationUnits_date === ''){
                    handleError('numberOfOpenClosedRefrigerationUnits_date','')
                }
                break;
            case ('numberOfOpenClosedRefrigerationUnits_date'):
                if (form.numberOfOpenClosedRefrigerationUnits !== ''){
                    if (value === ''){
                        handleError('numberOfOpenClosedRefrigerationUnits_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfOpenClosedRefrigerationUnits_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfOpenClosedRefrigerationUnits_date','')
                    }
                }
                break;
            case ('numberOfWalkInRefrigerationUnits'):

                if (value < 0){
                    handleError('numberOfWalkInRefrigerationUnits','Must be a positive number')
                }
                else {
                    handleError('numberOfWalkInRefrigerationUnits','')
                }
                if( value !== '' && form.numberOfWalkInRefrigerationUnits_date === ''){
                    handleError('numberOfWalkInRefrigerationUnits_date','Date cannot be empty')
                }
                if( value === '' && form.numberOfWalkInRefrigerationUnits_date === ''){
                    handleError('numberOfWalkInRefrigerationUnits_date','')
                }
                break;
            case ('numberOfWalkInRefrigerationUnits_date'):
                if (form.numberOfWalkInRefrigerationUnits !== ''){
                    if (value === ''){
                        handleError('numberOfWalkInRefrigerationUnits_date','Date cannot be empty')
                    }
                    if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfWalkInRefrigerationUnits_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfWalkInRefrigerationUnits_date','')
                    }
                }
                break;
            case ('numberOfCashRegisters'):
                if (value < 0){
                    handleError('numberOfCashRegisters','Must be a positive number')
                }
                else {
                    handleError('numberOfCashRegisters','')
                }

                if( value !== '' && form.numberOfCashRegisters_date === ''){
                    handleError('numberOfCashRegisters_date','Date cannot be empty')
                }
                else if( value === '' && form.numberOfCashRegisters_date === ''){
                    handleError('numberOfCashRegisters_date','')
                }
                else {
                    handleError('numberOfCashRegisters','')
                }
                break;
            case ('numberOfCashRegisters_date'):
                if (form.numberOfCashRegisters !== ''){
                    if (value === ''){
                        handleError('numberOfCashRegisters_date','Date cannot be empty')
                    }
                    if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfCashRegisters_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfCashRegisters_date','')
                    }
                }
                break;
            case ('numberOfWorkers'):
                if (value < 0){
                    handleError('numberOfWorkers','Must be a positive number')
                }
                else {
                    handleError('numberOfWorkers','')
                }

                if( value !== '' && form.numberOfWorkers_date === ''){
                    handleError('numberOfWorkers_date','Date cannot be empty')
                }
                else if( value === '' && form.numberOfWorkers_date === ''){
                    handleError('numberOfWorkers_date','')
                }
                else {
                    handleError('numberOfWorkers','')
                }
                break;
            case ('numberOfWorkers_date'):
                if (form.numberOfWorkers !== ''){
                    if (value === ''){
                        handleError('numberOfWorkers_date','Date cannot be empty')
                    }
                    if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfWorkers_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfWorkers_date','')
                    }
                }
                break;
            case ('cookingFacilities'):
                if( value !== '' && form.cookingFacilities_date === ''){
                    handleError('cookingFacilities_date','Date cannot be empty')
                }
                else if( value === '' && form.cookingFacilities_date === ''){
                    handleError('cookingFacilities_date','')
                }
                else {
                    handleError('cookingFacilities','')
                }
                break;
            case ('cookingFacilities_date'):
                if (form.cookingFacilities !== ''){
                    if (value === ''){
                        handleError('cookingFacilities_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('cookingFacilities_date','Invalid Date')
                    }
                else {
                        handleError('cookingFacilities_date','')
                    }

                break;
            case ('exteriorEntranceToThePublic'):
                if( value !== '' && form.exteriorEntranceToThePublic_date === ''){
                    handleError('exteriorEntranceToThePublic_date','Date cannot be empty')
                }
                else if( value === '' && form.exteriorEntranceToThePublic_date === ''){
                    handleError('exteriorEntranceToThePublic_date','')
                }
                else {
                    handleError('exteriorEntranceToThePublic','')
                }
                break;
            case ('exteriorEntranceToThePublic_date'):
                if (form.exteriorEntranceToThePublic !== ''){
                    if (value === ''){
                        handleError('exteriorEntranceToThePublic_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('exteriorEntranceToThePublic_date','Invalid Date')
                    }
                else {
                        handleError('exteriorEntranceToThePublic_date','')
                    }
                break;
            case ('singleStore'):
                if( value !== '' && form.singleStore_date === ''){
                    handleError('singleStore_date','Date cannot be empty')
                }
                else if( value === '' && form.singleStore_date === ''){
                    handleError('singleStore_date','')
                }
                else {
                    handleError('singleStore','')
                }
                break;
            case ('singleStore_date'):
                if (form.singleStore !== ''){
                    if (value === ''){
                        handleError('singleStore_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('singleStore_date','Invalid Date')
                    }
                else {
                        handleError('singleStore_date','')
                    }

                break;
            case ('percentCooled'):
                if( value !== '' && form.percentCooled_date === ''){
                    handleError('percentCooled_date','Date cannot be empty')
                }
                else if( value === '' && form.percentCooled_date === ''){
                    handleError('percentCooled_date','')
                }
                else {
                    handleError('percentCooled','')
                }
                break;
            case ('percentCooled_date'):
                if (form.percentCooled !== ''){
                    if (value === ''){
                        handleError('percentCooled_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentCooled_date','Invalid Date')
                    }
                else {
                        handleError('percentCooled_date','')
                    }

                break;
            case ('percentHeated'):
                if( value !== '' && form.percentHeated_date === ''){
                    handleError('percentHeated_date','Date cannot be empty')
                }
                else if( value === '' && form.percentHeated_date === ''){
                    handleError('percentHeated_date','')
                }
                else {
                    handleError('percentHeated','')
                }
                break;
            case ('percentHeated_date'):
                if (form.percentHeated !== ''){
                    if (value === ''){
                        handleError('percentHeated_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentHeated_date','Invalid Date')
                    }
                else {
                        handleError('percentHeated_date','')
                    }

                break;
            case ('areaOfAllWalkInRefrigerationUnits'):
                if( value !== ''){
                    value < 0 ? handleError("areaOfAllWalkInRefrigerationUnits",'Must be a positive number') : handleError('areaOfAllWalkInRefrigerationUnits','')
                    form.areaOfAllWalkInRefrigerationUnits_date === '' ? handleError('areaOfAllWalkInRefrigerationUnits_date','Date cannot be empty') : handleError('areaOfAllWalkInRefrigerationUnits_date','')
                    form.areaOfAllWalkInRefrigerationUnits_units === '' ? handleError('areaOfAllWalkInRefrigerationUnits_units','Select Units') : handleError('areaOfAllWalkInRefrigerationUnits_units','')
                }
                else {
                    handleError('areaOfAllWalkInRefrigerationUnits','')
                    handleError('areaOfAllWalkInRefrigerationUnits_units','')
                    handleError('areaOfAllWalkInRefrigerationUnits_date','')
                }
                break;
            case ('areaOfAllWalkInRefrigerationUnits_units'):
                if( value !== ''){
                    form.areaOfAllWalkInRefrigerationUnits_date === '' ? handleError('areaOfAllWalkInRefrigerationUnits_date','Date cannot be empty') : handleError('areaOfAllWalkInRefrigerationUnits_date','')
                    form.areaOfAllWalkInRefrigerationUnits === '' ? handleError('areaOfAllWalkInRefrigerationUnits',' Cannot be empty') : handleError('areaOfAllWalkInRefrigerationUnits','')
                    handleError('areaOfAllWalkInRefrigerationUnits_units','')
                }
                else {
                    handleError('areaOfAllWalkInRefrigerationUnits','')
                }
                break;
            case ('areaOfAllWalkInRefrigerationUnits_date'):
                if( value !== ''){
                    new Date(value).getTime() > getCurrentDate() ? handleError('areaOfAllWalkInRefrigerationUnits_date','Invalid Date') : handleError('areaOfAllWalkInRefrigerationUnits_date','')
                    form.areaOfAllWalkInRefrigerationUnits_units === '' ? handleError('areaOfAllWalkInRefrigerationUnits_units','Select Units') : handleError('areaOfAllWalkInRefrigerationUnits_units','')
                    form.areaOfAllWalkInRefrigerationUnits === '' ? handleError('areaOfAllWalkInRefrigerationUnits',' Cannot be empty') : handleError('areaOfAllWalkInRefrigerationUnits','')
                }
                else {
                    handleError('areaOfAllWalkInRefrigerationUnits','')
                    handleError('areaOfAllWalkInRefrigerationUnits_units','')
                    handleError('areaOfAllWalkInRefrigerationUnits_date','')
                }
                break;
            case ('lengthOfAllOpenClosedRefrigerationUnits'):
                if( value !== ''){
                    value < 0 ? handleError("lengthOfAllOpenClosedRefrigerationUnits",'Must be a positive number') : handleError('lengthOfAllOpenClosedRefrigerationUnits','')
                    form.lengthOfAllOpenClosedRefrigerationUnits_date === '' ? handleError('lengthOfAllOpenClosedRefrigerationUnits_date','Date cannot be empty') : handleError('lengthOfAllOpenClosedRefrigerationUnits_date','')
                    form.lengthOfAllOpenClosedRefrigerationUnits_units === '' ? handleError('lengthOfAllOpenClosedRefrigerationUnits_units','Select Units') : handleError('lengthOfAllOpenClosedRefrigerationUnits_units','')

                }
                else {
                    handleError('lengthOfAllOpenClosedRefrigerationUnits','')
                    handleError('lengthOfAllOpenClosedRefrigerationUnits_units','')
                    handleError('lengthOfAllOpenClosedRefrigerationUnits_date','')
                }
                break;
            case ('lengthOfAllOpenClosedRefrigerationUnits_units'):
                if( value !== ''){
                    form.lengthOfAllOpenClosedRefrigerationUnits_date === '' ? handleError('lengthOfAllOpenClosedRefrigerationUnits_date','Date cannot be empty') : handleError('lengthOfAllOpenClosedRefrigerationUnits_date','')
                    form.lengthOfAllOpenClosedRefrigerationUnits === '' ? handleError('lengthOfAllOpenClosedRefrigerationUnits',' Cannot be empty') : handleError('lengthOfAllOpenClosedRefrigerationUnits','')
                    handleError('lengthOfAllOpenClosedRefrigerationUnits_units','')
                }
                else {
                    handleError('lengthOfAllOpenClosedRefrigerationUnits','')
                }
                break;
            case ('lengthOfAllOpenClosedRefrigerationUnits_date'):
                if( value !== ''){
                    new Date(value).getTime() > getCurrentDate() ? handleError('lengthOfAllOpenClosedRefrigerationUnits_date','Invalid Date') : handleError('lengthOfAllOpenClosedRefrigerationUnits_date','')
                    form.lengthOfAllOpenClosedRefrigerationUnits_units === '' ? handleError('lengthOfAllOpenClosedRefrigerationUnits_units','Select Units') : handleError('lengthOfAllOpenClosedRefrigerationUnits_units','')
                    form.lengthOfAllOpenClosedRefrigerationUnits === '' ? handleError('lengthOfAllOpenClosedRefrigerationUnits',' Cannot be empty') : handleError('lengthOfAllOpenClosedRefrigerationUnits','')
                }
                else {
                    handleError('lengthOfAllOpenClosedRefrigerationUnits','')
                    handleError('lengthOfAllOpenClosedRefrigerationUnits_units','')
                    handleError('lengthOfAllOpenClosedRefrigerationUnits_date','')
                }
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <h1>Retail Store Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.totalGrossFloorArea}  value={form.totalGrossFloorArea} placeholder='GFA' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Total Gross Floor Area:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.totalGrossFloorArea_date} value={form.totalGrossFloorArea_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <AE2Input label="Number of Workers" type="number" errorText={errors.numberOfWorkers}  value={form.numberOfWorkers} placeholder='# Workers' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfWorkers'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfWorkers_date}  value={form.numberOfWorkers_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfWorkers_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <AE2Input label="Number of Cash Register" type="number" errorText={errors.numberOfCashRegisters}  value={form.numberOfCashRegisters} placeholder='# Cash Registers' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfCashRegisters'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfCashRegisters_date}  value={form.numberOfCashRegisters_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfCashRegisters_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.numberOfWalkInRefrigerationUnits}  value={form.numberOfWalkInRefrigerationUnits} placeholder='# Walk-In Refrigeration Units' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfWalkInRefrigerationUnits'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Number of Walk-In refrigeration Units:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfWalkInRefrigerationUnits_date}  value={form.numberOfWalkInRefrigerationUnits_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfWalkInRefrigerationUnits_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.numberOfOpenClosedRefrigerationUnits}  value={form.numberOfOpenClosedRefrigerationUnits} placeholder='# Open-Closed Refrigeration Units' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfOpenClosedRefrigerationUnits'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Number Of Open-Closed Refrigeration Units</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfOpenClosedRefrigerationUnits_date}  value={form.numberOfOpenClosedRefrigerationUnits_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfOpenClosedRefrigerationUnits_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.weeklyOperatingHours}  value={form.weeklyOperatingHours} placeholder='Weekly Hours' onIonInput={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Weekly Operating Hours</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.weeklyOperatingHours_date}  value={form.weeklyOperatingHours_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <AE2Input label="Number of Computers" type="number" errorText={errors.numberOfComputers}  value={form.numberOfComputers} placeholder='# Computers' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfComputers'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfComputers_date}  value={form.numberOfComputers_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfComputers_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Does Property Have Cooking Facilities?" labelPlacement="stacked" className={errors.cookingFacilities === '' ? "valid":'invalid'}  value={form.cookingFacilities} interface='popover' placeholder='Cooking Facilities?' onIonChange={e => (handleFormChange(e.detail.value!,'cookingFacilities'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.cookingFacilities}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.cookingFacilities_date}  value={form.cookingFacilities_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'cookingFacilities_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect className={errors.singleStore === '' ? "valid":'invalid'}  value={form.singleStore} interface='popover' placeholder='Single Store?' onIonChange={e => (handleFormChange(e.detail.value!,'singleStore'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Single Store?</div>
                        </IonSelect>
                        <div className="error-detail">{errors.singleStore}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.singleStore_date}  value={form.singleStore_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'singleStore_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect className={errors.exteriorEntranceToThePublic === '' ? "valid":'invalid'}  value={form.exteriorEntranceToThePublic} interface='popover' placeholder='Exterior Public Entrance?' onIonChange={e => (handleFormChange(e.detail.value!,'exteriorEntranceToThePublic'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Is There As Exterior Entrance To The Public?</div>
                        </IonSelect>
                        <div className="error-detail">{errors.exteriorEntranceToThePublic}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.exteriorEntranceToThePublic_date}  value={form.exteriorEntranceToThePublic_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'exteriorEntranceToThePublic_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect className={errors.percentCooled === '' ? "valid":'invalid'}  value={form.percentCooled} interface='popover' placeholder='Percent Cooled' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Percent Cooled</div>
                        </IonSelect>
                        <div className="error-detail">{errors.percentCooled}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.percentCooled_date}  value={form.percentCooled_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'percentCooled_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect className={errors.percentHeated === '' ? "valid":'invalid'}  value={form.percentHeated} interface='popover' placeholder='Percent Heated' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Percent Heated</div>
                        </IonSelect>
                        <div className="error-detail">{errors.percentHeated}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.percentHeated_date}  value={form.percentHeated_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'percentHeated_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Area of All Walk-In Refrigeration Units" labelPlacement="stacked" type="number" errorText={errors.areaOfAllWalkInRefrigerationUnits}  value={form.areaOfAllWalkInRefrigerationUnits} placeholder='Walk-In Refrigeration Area' onIonInput={e => (handleFormChange(e.detail.value!,'areaOfAllWalkInRefrigerationUnits'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.areaOfAllWalkInRefrigerationUnits_units === '' ? "valid":'invalid'} value={form.areaOfAllWalkInRefrigerationUnits_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'areaOfAllWalkInRefrigerationUnits_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.areaOfAllWalkInRefrigerationUnits_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.areaOfAllWalkInRefrigerationUnits_date} value={form.areaOfAllWalkInRefrigerationUnits_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'areaOfAllWalkInRefrigerationUnits_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Length of All Open-Closed Refrigeration Units" labelPlacement="stacked" type="number" errorText={errors.lengthOfAllOpenClosedRefrigerationUnits}  value={form.lengthOfAllOpenClosedRefrigerationUnits} placeholder='Length Open-Close Refrigeration Units' onIonInput={e => (handleFormChange(e.detail.value!,'lengthOfAllOpenClosedRefrigerationUnits'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.lengthOfAllOpenClosedRefrigerationUnits_units === '' ? "valid":'invalid'} value={form.lengthOfAllOpenClosedRefrigerationUnits_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'lengthOfAllOpenClosedRefrigerationUnits_units'))}>
                            <IonSelectOption value={'Feet'}>Feet</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.lengthOfAllOpenClosedRefrigerationUnits_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.lengthOfAllOpenClosedRefrigerationUnits_date} value={form.lengthOfAllOpenClosedRefrigerationUnits_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'lengthOfAllOpenClosedRefrigerationUnits_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonItem><IonIcon slot="start" color="white" ios={starOutline} md={star} />Required for Energy Star Score</IonItem>
        </div>
    )
}

export default Retail