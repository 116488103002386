import { IonCol, IonIcon, IonItem, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { star, starOutline } from "ionicons/icons"
import { useEffect, useState } from "react"
import AE2Input from "../../elements/AE2Input"

interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
    totalGrossFloorArea : "",
    totalGrossFloorArea_units : "",
    totalGrossFloorArea_date : "",
    numberOfResidentialLivingUnits : "",
    numberOfResidentialLivingUnits_date : "",
    numberOfBedrooms : "",
    numberOfBedrooms_date : "",
    numberOfResidentialLivingUnitsMidRiseSetting : "",
    numberOfResidentialLivingUnitsMidRiseSetting_date : "",
    numberOfLaundryHookupsInAllUnits : "",
    numberOfLaundryHookupsInAllUnits_date : "",
    numberOfLaundryHookupsInCommonArea : "",
    numberOfLaundryHookupsInCommonArea_date : "",
    percentCooled : "",
    percentCooled_date : "",
    percentHeated : "",
    percentHeated_date : "",
    numberOfResidentialLivingUnitsLowRiseSetting : "",
    numberOfResidentialLivingUnitsLowRiseSetting_date : "",
    numberOfResidentialLivingUnitsHighRiseSetting : "",
    numberOfResidentialLivingUnitsHighRiseSetting_date : "",
    residentPopulation : "",
    residentPopulation_date : "",
    governmentSubsidizedHousing : "",
    governmentSubsidizedHousing_date : "",
}

const startErrors = {
    totalGrossFloorArea : "Field Required",
    totalGrossFloorArea_units : "Select Units",
    totalGrossFloorArea_date : "Date is required",
    numberOfResidentialLivingUnits : "",
    numberOfResidentialLivingUnits_date : "",
    numberOfBedrooms : "",
    numberOfBedrooms_date : "",
    numberOfResidentialLivingUnitsMidRiseSetting : "",
    numberOfResidentialLivingUnitsMidRiseSetting_date : "",
    numberOfLaundryHookupsInAllUnits : "",
    numberOfLaundryHookupsInAllUnits_date : "",
    numberOfLaundryHookupsInCommonArea : "",
    numberOfLaundryHookupsInCommonArea_date : "",
    percentCooled : "",
    percentCooled_date : "",
    percentHeated : "",
    percentHeated_date : "",
    numberOfResidentialLivingUnitsLowRiseSetting : "",
    numberOfResidentialLivingUnitsLowRiseSetting_date : "",
    numberOfResidentialLivingUnitsHighRiseSetting : "",
    numberOfResidentialLivingUnitsHighRiseSetting_date : "",
    residentPopulation : "",
    residentPopulation_date : "",
    governmentSubsidizedHousing : "",
    governmentSubsidizedHousing_date : "",
}



const MultiFamily: React.FC<Props> = ({setParentForm,formErrors}) => {
    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case('numberOfBedrooms'):
                if(value !==''){
                    form.numberOfBedrooms_date === '' ? handleError('numberOfBedrooms_date','Date cannot be empty') : handleError('numberOfBedrooms_date','')
                    value < 0 ? handleError('numberOfBedrooms','Must be a Positive number') : handleError('numberOfBedrooms','')
                }
                else{
                    handleError('numberOfBedrooms','')
                    handleError('numberOfBedrooms_date','')
                }
                break;
            case('numberOfBedrooms_date'):
                if(value !==''){
                    form.numberOfBedrooms === '' ? handleError('numberOfBedrooms','Field cannot be empty') : handleError('numberOfBedrooms','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfBedrooms_date','Invalid Date') : handleError('numberOfBedrooms_date','')
                }
                else{
                    handleError('numberOfBedrooms','')
                    handleError('numberOfBedrooms_date','')
                }
                break;
            case('numberOfResidentialLivingUnits'):
                if(value !==''){
                    form.numberOfResidentialLivingUnits_date === '' ? handleError('numberOfResidentialLivingUnits_date','Date cannot be empty') : handleError('numberOfResidentialLivingUnits_date','')
                    value < 0 ? handleError('numberOfResidentialLivingUnits','Must be a Positive number') : handleError('numberOfResidentialLivingUnits','')
                }
                else{
                    handleError('numberOfResidentialLivingUnits','')
                    handleError('numberOfResidentialLivingUnits_date','')
                }
                break;
            case('numberOfResidentialLivingUnits_date'):
                if(value !==''){
                    form.numberOfResidentialLivingUnits === '' ? handleError('numberOfResidentialLivingUnits','Field cannot be empty') : handleError('numberOfResidentialLivingUnits','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfResidentialLivingUnits_date','Invalid Date') : handleError('numberOfResidentialLivingUnits_date','')
                }
                else{
                    handleError('numberOfResidentialLivingUnits','')
                    handleError('numberOfResidentialLivingUnits_date','')
                }
                break;
            case('numberOfResidentialLivingUnitsMidRiseSetting'):
                if(value !==''){
                    form.numberOfResidentialLivingUnitsMidRiseSetting_date === '' ? handleError('numberOfResidentialLivingUnitsMidRiseSetting_date','Date cannot be empty') : handleError('numberOfResidentialLivingUnitsMidRiseSetting_date','')
                    value < 0 ? handleError('numberOfResidentialLivingUnitsMidRiseSetting','Must be a Positive number') : handleError('numberOfResidentialLivingUnitsMidRiseSetting','')
                }
                else{
                    handleError('numberOfResidentialLivingUnitsMidRiseSetting','')
                    handleError('numberOfResidentialLivingUnitsMidRiseSetting_date','')
                }
                break;
            case('numberOfResidentialLivingUnitsMidRiseSetting_date'):
                if(value !==''){
                    form.numberOfResidentialLivingUnitsMidRiseSetting === '' ? handleError('numberOfResidentialLivingUnitsMidRiseSetting','Field cannot be empty') : handleError('numberOfResidentialLivingUnitsMidRiseSetting','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfResidentialLivingUnitsMidRiseSetting_date','Invalid Date') : handleError('numberOfResidentialLivingUnitsMidRiseSetting_date','')
                }
                else{
                    handleError('numberOfResidentialLivingUnitsMidRiseSetting','')
                    handleError('numberOfResidentialLivingUnitsMidRiseSetting_date','')
                }
                break;
            case('numberOfResidentialLivingUnitsLowRiseSetting'):
                if(value !==''){
                    form.numberOfResidentialLivingUnitsLowRiseSetting_date === '' ? handleError('numberOfResidentialLivingUnitsLowRiseSetting_date','Date cannot be empty') : handleError('numberOfResidentialLivingUnitsLowRiseSetting_date','')
                    value < 0 ? handleError('numberOfResidentialLivingUnitsLowRiseSetting','Must be a Positive number') : handleError('numberOfResidentialLivingUnitsLowRiseSetting','')
                }
                else{
                    handleError('numberOfResidentialLivingUnitsLowRiseSetting','')
                    handleError('numberOfResidentialLivingUnitsLowRiseSetting_date','')
                }
                break;
            case('numberOfResidentialLivingUnitsLowRiseSetting_date'):
                if(value !==''){
                    form.numberOfResidentialLivingUnitsLowRiseSetting === '' ? handleError('numberOfResidentialLivingUnitsLowRiseSetting','Field cannot be empty') : handleError('numberOfResidentialLivingUnitsLowRiseSetting','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfResidentialLivingUnitsLowRiseSetting_date','Invalid Date') : handleError('numberOfResidentialLivingUnitsLowRiseSetting_date','')
                }
                else{
                    handleError('numberOfResidentialLivingUnitsLowRiseSetting','')
                    handleError('numberOfResidentialLivingUnitsLowRiseSetting_date','')
                }
                break;
            case('numberOfResidentialLivingUnitsHighRiseSetting'):
                if(value !==''){
                    form.numberOfResidentialLivingUnitsHighRiseSetting_date === '' ? handleError('numberOfResidentialLivingUnitsHighRiseSetting_date','Date cannot be empty') : handleError('numberOfResidentialLivingUnitsHighRiseSetting_date','')
                    value < 0 ? handleError('numberOfResidentialLivingUnitsHighRiseSetting','Must be a Positive number') : handleError('numberOfResidentialLivingUnitsHighRiseSetting','')
                }
                else{
                    handleError('numberOfResidentialLivingUnitsHighRiseSetting','')
                    handleError('numberOfResidentialLivingUnitsHighRiseSetting_date','')
                }
                break;
            case('numberOfResidentialLivingUnitsHighRiseSetting_date'):
                if(value !==''){
                    form.numberOfResidentialLivingUnitsHighRiseSetting === '' ? handleError('numberOfResidentialLivingUnitsHighRiseSetting','Field cannot be empty') : handleError('numberOfResidentialLivingUnitsHighRiseSetting','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfResidentialLivingUnitsHighRiseSetting_date','Invalid Date') : handleError('numberOfResidentialLivingUnitsHighRiseSetting_date','')
                }
                else{
                    handleError('numberOfResidentialLivingUnitsHighRiseSetting','')
                    handleError('numberOfResidentialLivingUnitsHighRiseSetting_date','')
                }
                break;
            case('numberOfLaundryHookupsInAllUnits'):
                if(value !==''){
                    form.numberOfLaundryHookupsInAllUnits_date === '' ? handleError('numberOfLaundryHookupsInAllUnits_date','Date cannot be empty') : handleError('numberOfLaundryHookupsInAllUnits_date','')
                    value < 0 ? handleError('numberOfLaundryHookupsInAllUnits','Must be a Positive number') : handleError('numberOfLaundryHookupsInAllUnits','')
                }
                else{
                    handleError('numberOfLaundryHookupsInAllUnits','')
                    handleError('numberOfLaundryHookupsInAllUnits_date','')
                }
                break;
            case('numberOfLaundryHookupsInAllUnits_date'):
                if(value !==''){
                    form.numberOfLaundryHookupsInAllUnits === '' ? handleError('numberOfLaundryHookupsInAllUnits','Field cannot be empty') : handleError('numberOfLaundryHookupsInAllUnits','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfLaundryHookupsInAllUnits_date','Invalid Date') : handleError('numberOfLaundryHookupsInAllUnits_date','')
                }
                else{
                    handleError('numberOfLaundryHookupsInAllUnits','')
                    handleError('numberOfLaundryHookupsInAllUnits_date','')
                }
                break;
            case('numberOfLaundryHookupsInCommonArea'):
                if(value !==''){
                    form.numberOfLaundryHookupsInCommonArea_date === '' ? handleError('numberOfLaundryHookupsInCommonArea_date','Date cannot be empty') : handleError('numberOfLaundryHookupsInCommonArea_date','')
                    value < 0 ? handleError('numberOfLaundryHookupsInCommonArea','Must be a Positive number') : handleError('numberOfLaundryHookupsInCommonArea','')
                }
                else{
                    handleError('numberOfLaundryHookupsInCommonArea','')
                    handleError('numberOfLaundryHookupsInCommonArea_date','')
                }
                break;
            case('numberOfLaundryHookupsInCommonArea_date'):
                if(value !==''){
                    form.numberOfLaundryHookupsInCommonArea === '' ? handleError('numberOfLaundryHookupsInCommonArea','Field cannot be empty') : handleError('numberOfLaundryHookupsInCommonArea','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('numberOfLaundryHookupsInCommonArea_date','Invalid Date') : handleError('numberOfLaundryHookupsInCommonArea_date','')
                }
                else{
                    handleError('numberOfLaundryHookupsInCommonArea','')
                    handleError('numberOfLaundryHookupsInCommonArea_date','')
                }
                break;
            case ('percentCooled'):
                if( value !== '' && form.percentCooled_date === ''){
                    handleError('percentCooled_date','Date cannot be empty')
                }
                else if( value === '' && form.percentCooled_date === ''){
                    handleError('percentCooled_date','')
                }
                else {
                    handleError('percentCooled','')
                }
                break;
            case ('percentCooled_date'):
                if (form.percentCooled !== ''){
                    if (value === ''){
                        handleError('percentCooled_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentCooled_date','Invalid Date')
                    }
                else {
                        handleError('percentCooled_date','')
                    }
                break;
            case ('percentHeated'):
                if( value !== '' && form.percentHeated_date === ''){
                    handleError('percentHeated_date','Date cannot be empty')
                }
                else if( value === '' && form.percentHeated_date === ''){
                    handleError('percentHeated_date','')
                }
                else {
                    handleError('percentHeated','')
                }
                break;
            case ('percentHeated_date'):
                if (form.percentHeated !== ''){
                    if (value === ''){
                        handleError('percentHeated_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentHeated_date','Invalid Date')
                    }
                else {
                        handleError('percentHeated_date','')
                    }
                break;
            case ('residentPopulation'):
                if( value !== '' && form.residentPopulation_date === ''){
                    handleError('residentPopulation_date','Date cannot be empty')
                }
                else if( value === '' && form.residentPopulation_date === ''){
                    handleError('residentPopulation_date','')
                }
                else {
                    handleError('residentPopulation','')
                }
                break;
            case ('residentPopulation_date'):
                if (form.residentPopulation !== ''){
                    if (value === ''){
                        handleError('residentPopulation_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('residentPopulation_date','Invalid Date')
                    }
                else {
                        handleError('residentPopulation_date','')
                    }
                break;
            case ('governmentSubsidizedHousing'):
                if( value !== '' && form.governmentSubsidizedHousing_date === ''){
                    handleError('governmentSubsidizedHousing_date','Date cannot be empty')
                }
                else if( value === '' && form.governmentSubsidizedHousing_date === ''){
                    handleError('governmentSubsidizedHousing_date','')
                }
                else {
                    handleError('governmentSubsidizedHousing','')
                }
                break;
            case ('governmentSubsidizedHousing_date'):
                if (form.governmentSubsidizedHousing !== ''){
                    if (value === ''){
                        handleError('governmentSubsidizedHousing_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('governmentSubsidizedHousing_date','Invalid Date')
                    }
                else {
                        handleError('governmentSubsidizedHousing_date','')
                    }
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <h1>Multi-Family Housing Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.totalGrossFloorArea}  value={form.totalGrossFloorArea} placeholder='GFA' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Total Gross Floor Area:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.totalGrossFloorArea_date} value={form.totalGrossFloorArea_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.numberOfResidentialLivingUnits}  value={form.numberOfResidentialLivingUnits} placeholder='# Residential Living Units' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfResidentialLivingUnits'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Number of Residential Living Units</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfResidentialLivingUnits_date}  value={form.numberOfResidentialLivingUnits_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfResidentialLivingUnits_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input labelPlacement="stacked" type="number" errorText={errors.numberOfResidentialLivingUnitsLowRiseSetting}  value={form.numberOfResidentialLivingUnitsLowRiseSetting} placeholder='# Low Rise Residential Living Units' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfResidentialLivingUnitsLowRiseSetting'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Number of Residential Living Units Low Rise Setting. (1 to 4 Stories)</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfResidentialLivingUnitsLowRiseSetting_date}  value={form.numberOfResidentialLivingUnitsLowRiseSetting_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfResidentialLivingUnitsLowRiseSetting_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input labelPlacement="stacked" type="number" errorText={errors.numberOfResidentialLivingUnitsMidRiseSetting}  value={form.numberOfResidentialLivingUnitsMidRiseSetting} placeholder='# Mid Rise Living Units' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfResidentialLivingUnitsMidRiseSetting'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Number of Residential Living Units Mid Size Rise Setting (5 to 9 Stories)</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfResidentialLivingUnitsMidRiseSetting_date}  value={form.numberOfResidentialLivingUnitsMidRiseSetting_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfResidentialLivingUnitsMidRiseSetting_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input labelPlacement="stacked" type="number" errorText={errors.numberOfResidentialLivingUnitsHighRiseSetting}  value={form.numberOfResidentialLivingUnitsHighRiseSetting} placeholder='# High Rise Living Units' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfResidentialLivingUnitsHighRiseSetting'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Number of Residential Living Units High Rise Setting (10+ Stories)</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfResidentialLivingUnitsHighRiseSetting_date}  value={form.numberOfResidentialLivingUnitsHighRiseSetting_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfResidentialLivingUnitsHighRiseSetting_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Percent Cooled" className={errors.percentCooled === '' ? "valid":'invalid'}  value={form.percentCooled} interface='popover' placeholder='Percent Cooled' onIonChange={e => (handleFormChange(e.detail.value!,'percentCooled'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentCooled}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.percentCooled_date}  value={form.percentCooled_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'percentCooled_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Percent Heated" className={errors.percentHeated === '' ? "valid":'invalid'}  value={form.percentHeated} interface='popover' placeholder='Percent Heated' onIonChange={e => (handleFormChange(e.detail.value!,'percentHeated'))}>
                            <IonSelectOption>0</IonSelectOption>
                            <IonSelectOption>10</IonSelectOption>
                            <IonSelectOption>20</IonSelectOption>
                            <IonSelectOption>30</IonSelectOption>
                            <IonSelectOption>40</IonSelectOption>
                            <IonSelectOption>50</IonSelectOption>
                            <IonSelectOption>60</IonSelectOption>
                            <IonSelectOption>70</IonSelectOption>
                            <IonSelectOption>80</IonSelectOption>
                            <IonSelectOption>90</IonSelectOption>
                            <IonSelectOption>100</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.percentHeated}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.percentHeated_date}  value={form.percentHeated_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'percentHeated_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Number of Laundry Hookups In All Units" labelPlacement="stacked" type="number" errorText={errors.numberOfLaundryHookupsInAllUnits}  value={form.numberOfLaundryHookupsInAllUnits} placeholder='# Laundry Hookups' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfLaundryHookupsInAllUnits'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfLaundryHookupsInAllUnits_date}  value={form.numberOfLaundryHookupsInAllUnits_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfLaundryHookupsInAllUnits_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label='Number of Bedrooms' type="number" errorText={errors.numberOfBedrooms}  value={form.numberOfBedrooms} placeholder='# Bedrooms' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfBedrooms'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfBedrooms_date}  value={form.numberOfBedrooms_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfBedrooms_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label='Number of Laundry In Common Area' labelPlacement="stacked" type="number" errorText={errors.numberOfLaundryHookupsInCommonArea}  value={form.numberOfLaundryHookupsInCommonArea} placeholder='# Laundry Hookups In Common Area' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfLaundryHookupsInCommonArea'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.numberOfLaundryHookupsInCommonArea_date}  value={form.numberOfLaundryHookupsInCommonArea_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfLaundryHookupsInCommonArea_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label='Type of Resident Population' className={errors.residentPopulation === '' ? "valid":'invalid'}  value={form.residentPopulation} interface='popover' placeholder='Population Type' onIonChange={e => (handleFormChange(e.detail.value!,'residentPopulation'))}>
                            <IonSelectOption>No specific resident population</IonSelectOption>
                            <IonSelectOption>Dedicated Student</IonSelectOption>
                            <IonSelectOption>Dedicated Military</IonSelectOption>
                            <IonSelectOption>Dedicated Senior/Independent Living</IonSelectOption>
                            <IonSelectOption>Dedicated Special Accessibility Needs</IonSelectOption>
                            <IonSelectOption>Other dedicated housing</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.residentPopulation}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.residentPopulation_date}  value={form.residentPopulation_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'residentPopulation_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeXl="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Subsidized by Local, State or Federal Government" labelPlacement="stacked" className={errors.governmentSubsidizedHousing === '' ? "valid":'invalid'}  value={form.governmentSubsidizedHousing} interface='popover' placeholder='Subsidized?' onIonChange={e => (handleFormChange(e.detail.value!,'governmentSubsidizedHousing'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.governmentSubsidizedHousing}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="4" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.governmentSubsidizedHousing_date}  value={form.governmentSubsidizedHousing_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'governmentSubsidizedHousing_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default MultiFamily
