import { IonCol, IonIcon, IonItem, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { useEffect, useState } from "react"
import {star, starOutline} from 'ionicons/icons'
import AE2Input from "../../elements/AE2Input"

interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
totalGrossFloorArea : "",
totalGrossFloorArea_units : "",
totalGrossFloorArea_date : "",
percentOfficeCooled : "",
percentOfficeCooled_date : "",
percentOfficeHeated : "",
percentOfficeHeated_date : "",
weeklyOperatingHours : "",
weeklyOperatingHours_date : "",
numberOfComputers : "",
numberOfComputers_date : "",
numberOfWorkers : "",
numberOfWorkers_date : ""

}
const startErrors = {
totalGrossFloorArea : "GFA cannot be empty",
totalGrossFloorArea_units : "Units cannot be empty",
totalGrossFloorArea_date : "Date cannot be empty",
percentOfficeCooled : "",
percentOfficeCooled_date : "",
percentOfficeHeated : "",
percentOfficeHeated_date : "",
weeklyOperatingHours : "",
weeklyOperatingHours_date : "",
numberOfComputers : "",
numberOfComputers_date : "",
numberOfWorkers : "",
numberOfWorkers_date : ""
}

const BankBranch: React.FC<Props> = ({setParentForm,formErrors}) => {

    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case ('weeklyOperatingHours'):
                console.log('error')
                if (value < 0){
                    handleError('weeklyOperatingHours','Hours must be a positive number')
                }
                else if (value > 168){
                    handleError('weeklyOperatingHours','Hours mush be less than 168')
                }
                else {
                    handleError('weeklyOperatingHours','')
                }
                if( value !== '' && form.weeklyOperatingHours_date === ''){
                    handleError('weeklyOperatingHours_date','Date cannot be empty')
                }
                if( value === '' && form.weeklyOperatingHours_date === ''){
                    handleError('weeklyOperatingHours_date','')
                }
                break;
            case ('weeklyOperatingHours_date'):

                if (form.weeklyOperatingHours !== ''){
                    if (value === ''){
                        handleError('weeklyOperatingHours_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('weeklyOperatingHours_date','Invalid Date')
                    }
                    else {
                        handleError('weeklyOperatingHours_date','')
                    }
                }
                break;
            case ('numberOfComputers'):
                console.log('error')
                if (value < 0){
                    handleError('numberOfComputers','Number of computers must be a positive number')
                }
                else {
                    handleError('numberOfComputers','')
                }
                if( value !== '' && form.numberOfComputers_date === ''){
                    handleError('numberOfComputers_date','Date cannot be empty')
                }
                if( value === '' && form.numberOfComputers_date === ''){
                    handleError('numberOfComputers_date','')
                }
                break;
            case ('numberOfComputers_date'):
                if (form.numberOfComputers !== ''){
                    if (value === ''){
                        handleError('numberOfComputers_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfComputers_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfComputers_date','')
                    }
                }
                break;
            case ('numberOfWorkers'):
                if (value < 0){
                    handleError('numberOfWorkers','Number of computers must be a positive number')
                }
                else {
                    handleError('numberOfWorkers','')
                }
                if( value !== '' && form.numberOfWorkers_date === ''){
                    handleError('numberOfWorkers_date','Date cannot be empty')
                }
                if( value === '' && form.numberOfWorkers_date === ''){
                    handleError('numberOfWorkers_date','')
                }
                break;
            case ('numberOfWorkers_date'):
                if (form.numberOfWorkers !== ''){
                    if (value === ''){
                        handleError('numberOfWorkers_date','Date cannot be empty')
                    }
                    else if (new Date(value).getTime() > getCurrentDate()){
                        handleError('numberOfWorkers_date','Invalid Date')
                    }
                    else {
                        handleError('numberOfWorkers_date','')
                    }
                }
                break;
            case ('percentOfficeHeated'):

                if( value !== '' && form.percentOfficeHeated_date === ''){
                    handleError('percentOfficeHeated_date','Date cannot be empty')
                }
                else if( value === '' && form.percentOfficeHeated_date === ''){
                    handleError('percentOfficeHeated_date','')
                }
                else {
                    handleError('percentOfficeHeated','')
                }
                break;
            case ('percentOfficeHeated_date'):
                if (form.percentOfficeHeated !== ''){
                    if (value === ''){
                        handleError('percentOfficeHeated_date','Date cannot be empty')
                    }
                    if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentOfficeHeated_date','Invalid Date')
                    }
                    else {
                        handleError('percentOfficeHeated_date','')
                    }
                }
                break;
            case ('percentOfficeCooled'):

                if( value !== '' && form.percentOfficeCooled_date === ''){
                    handleError('percentOfficeCooled_date','Date cannot be empty')
                }
                else if( value === '' && form.percentOfficeCooled_date === ''){
                    handleError('percentOfficeCooled_date','')
                }
                else {
                    handleError('percentOfficeCooled','')
                }
                break;
            case ('percentOfficeCooled_date'):
                if (form.percentOfficeCooled !== ''){
                    if (value === ''){
                        handleError('percentOfficeCooled_date','Date cannot be empty')
                    }
                    if (new Date(value).getTime() > getCurrentDate()){
                        handleError('percentOfficeCooled_date','Invalid Date')
                    }
                    else {
                        handleError('percentOfficeCooled_date','')
                    }
                }
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <h1>Bank Branch Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input labelPlacement="stacked" type="number" errorText={errors.totalGrossFloorArea} value={form.totalGrossFloorArea} placeholder='GFA' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Total Gross Floor Area:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <div style={{width:'100%'}}>
                        <IonSelect labelPlacement="stacked" className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Units:</div>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input  labelPlacement='stacked' type="date" errorText={errors.totalGrossFloorArea_date} value={form.totalGrossFloorArea_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}>
                        <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect labelPlacement='stacked' className={errors.percentOfficeCooled === '' ? "valid":'invalid'}  value={form.percentOfficeCooled} interface='popover' placeholder='% Cooled' onIonChange={e => (handleFormChange(e.detail.value!,'percentOfficeCooled'))}>
                            <IonSelectOption>50% or more</IonSelectOption>
                            <IonSelectOption>Less than 50%</IonSelectOption>
                            <IonSelectOption>Not Cooled</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Percent Office Cooled:</div>
                        </IonSelect>
                        <div className="error-detail">{errors.percentOfficeCooled}</div>
                    </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input labelPlacement="stacked" type="date" errorText={errors.percentOfficeCooled_date}  value={form.percentOfficeCooled_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'percentOfficeCooled_date'))}>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect labelPlacement="stacked" className={errors.percentOfficeHeated === '' ? "valid":'invalid'}  value={form.percentOfficeHeated} interface='popover' placeholder='% Heated' onIonChange={e => (handleFormChange(e.detail.value!,'percentOfficeHeated'))}>
                            <IonSelectOption>50% or more</IonSelectOption>
                            <IonSelectOption>Less than 50%</IonSelectOption>
                            <IonSelectOption>Not Heated</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Percent Office Heated</div>
                        </IonSelect>
                        <div className="error-detail">{errors.percentOfficeHeated}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input labelPlacement="stacked" type="date" errorText={errors.percentOfficeHeated_date} value={form.percentOfficeHeated_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'percentOfficeHeated_date'))}>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input labelPlacement="stacked" type="number" errorText={errors.weeklyOperatingHours}  value={form.weeklyOperatingHours} placeholder='Hours' onIonInput={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours'))}>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Weekly Operating Hours:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input labelPlacement="stacked" type="date" errorText={errors.weeklyOperatingHours_date} value={form.weeklyOperatingHours_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'weeklyOperatingHours_date'))}>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input labelPlacement="stacked" type="number" errorText={errors.numberOfComputers} value={form.numberOfComputers} placeholder='Computers' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfComputers'))}>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Number Of Computers</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input labelPlacement="stacked" type="date" errorText={errors.numberOfComputers_date}  value={form.numberOfComputers_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfComputers_date'))}>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input labelPlacement="stacked" type="number" errorText={errors.numberOfWorkers}  value={form.numberOfWorkers} placeholder='Computers' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfWorkers'))}>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Number Of Workers:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input labelPlacement="stacked" type="date" errorText={errors.numberOfWorkers_date}  value={form.numberOfWorkers_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'numberOfWorkers_date'))}>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Current as of:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonItem><IonIcon slot="start" color="white" ios={starOutline} md={star} />Required for Energy Star Score</IonItem>
        </div>
    )
}

export default BankBranch