import React, { useEffect, useState } from "react";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import {
  API_URL,
  GOOGLE_MAPS_MAP_ID,
  REACT_APP_GOOGLE_MAPS_KEY,
} from "../../actions/settings";
import { setKey, setLanguage, setRegion } from "react-geocode";
import GoogleMapMarker from "./GoogleMapMarker";
import LoadingDots from "./LoadingDots";
import { MdLocationPin, MdCircle, MdOutlineStar, MdStars } from "react-icons/md";
import { IonCol, IonRow } from "@ionic/react";
// eslint-disable-next-line
import SingleTimePopup from "./SingleTimePopup";

interface Props {}

async function getBuildings(token: any) {
  return fetch(`${API_URL}/map_buildings`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

const getItems = async (
  token: any,
  setBuildings: any,
  setFilteredBuildings: any
) => {
  const res = await getBuildings(`Token ${token.slice(1, -1)}`);
  if (res.status === "success") {
    setBuildings(res.data);
    setFilteredBuildings(res.data);
  } else {
    console.warn(res);
    setBuildings([]);
  }
};

const GoogleCustomersMap: React.FC<Props> = () => {
  //set react-geocode settings
  setKey(REACT_APP_GOOGLE_MAPS_KEY || "");
  setLanguage("en");
  setRegion("us");
  // end react-geocode settings

  const [buildings, setBuildings] = useState([]);
  const [filteredBuildings, setFilteredBuildings] = useState([]);
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    setTimeout(() => {
      const token = localStorage.getItem("token");
      getItems(token, setBuildings, setFilteredBuildings);
    }, 3000);
  }, []);

  const filterBuildings = (filterType: string) => {
    if (filterType === filter) {
      filterType = "all";
      setFilter("all");
    } else {
      setFilter(filterType);
    }
    switch (filterType) {
      case "all":
        setFilteredBuildings(buildings);
        break;
      case "Building":
        setFilteredBuildings(
          buildings.filter(
            (b: any) => !b.campus_optimizer && !b.building_incentive
          )
        );
        break;
      case "Campus-Optimizer":
        setFilteredBuildings(buildings.filter((b: any) => b.campus_optimizer));
        break;
      case "No-Campus-Optimizer-Incentives-Available":
        setFilteredBuildings(
          buildings.filter(
            (b: any) => !b.campus_optimizer && b.building_incentive
          )
        );
        break;
      case "FOA":
        setFilteredBuildings(buildings.filter((b: any) => b.foa && !b.kpi));
        break;
      case "KPI":
        setFilteredBuildings(buildings.filter((b: any) => b.kpi && !b.foa));
        break;
      case "KPIFOA":
        setFilteredBuildings(buildings.filter((b: any) => b.kpi && b.foa));
        break;
      case "ESTARLOW":
        setFilteredBuildings(
          buildings.filter(
            (b: any) => b.estar_score != null && b.estar_score < 75
          )
        );
        break;
      case "ESTARABOVE":
        setFilteredBuildings(buildings.filter((b: any) => b.estar_score >= 75));
        break;
      default:
        return null;
    }
  };

  return (
    <APIProvider
      apiKey={REACT_APP_GOOGLE_MAPS_KEY || ""}
      key="map"
      version="beta"
    >
      <Map
        id="customersMap"
        style={{ width: "60vw", height: "60vh" }}
        defaultCenter={{ lat: 41.029988, lng: -89.057113 }}
        defaultZoom={7}
        gestureHandling={"greedy"}
        disableDefaultUI={true}
        mapId={GOOGLE_MAPS_MAP_ID}
      >
        {buildings.length <= 0 ? (
          <div
            style={{
              position: "absolute",
              width: "100%",
              border: "black solid 3px",
              top: "0px",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              verticalAlign: "center",
              backgroundColor: "rgba(255,255,255,0.7)",
            }}
          >
            <LoadingDots style={{ position: "absolute", bottom: "50%" }} />
          </div>
        ) : null}

        <div
          style={{
            position: "absolute",
            border: "black solid 1px",
            bottom: "20px",
            display: "flex",
            justifyContent: "start",
            verticalAlign: "text-bottom",
            margin: "10px",
            borderRadius: "1em",
          }}
        >
          <div className="map-pin-details">
            {/* <div className="map-popup-wrapper">
              <SingleTimePopup id="map-popup" style={{ index: "100" }}>
                <div>
                  <span style={{ color: "black" }}>NEW FEATURE:</span> Filter
                  map buildings by clicking on the icon below.
                </div>
              </SingleTimePopup>
            </div> */}
            <div
              className="map-pin-detail"
              onClick={() => filterBuildings("Building")}
            >
              <MdLocationPin className="red-pin" /> <span>AE2 Building</span>
            </div>
            <div
              className="map-pin-detail"
              onClick={() => filterBuildings("Campus-Optimizer")}
            >
              <MdLocationPin className="green-pin" />{" "}
              <span>AE2 Building + Campus Optimizer</span>
            </div>
            <div
              className="map-pin-detail"
              onClick={() =>
                filterBuildings("No-Campus-Optimizer-Incentives-Available")
              }
            >
              <MdLocationPin className="blue-pin" />{" "}
              <span>AE2 Building No Campus Optimizer + Incentives </span>
            </div>
            <IonRow>
              <IonCol>
                <div
                  className="map-pin-detail"
                  onClick={() => filterBuildings("KPI")}
                >
                  <MdCircle className="green-glyph" /> <span>KPI </span>
                </div>
              </IonCol>
              <IonCol
                className="map-pin-detail"
                onClick={() => filterBuildings("FOA")}
              >
                <MdCircle className="orange-glyph" /> <span>FOA </span>
              </IonCol>
              <IonCol
                className="map-pin-detail"
                onClick={() => filterBuildings("KPIFOA")}
              >
                <MdCircle className="blue-glyph" /> <span>KPI + FOA </span>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div
                  className="map-pin-detail"
                  onClick={() => filterBuildings("ESTARLOW")}
                >
                  {/* <MdHorizontalRule className="white-outline" /> */}
                  <MdOutlineStar className="estar-icon-map"/>
                  <span> - Energy Score &lt; 75 </span>
                </div>
              </IonCol>
              <IonCol>
                <div
                  className="map-pin-detail"
                  onClick={() => filterBuildings("ESTARABOVE")}
                >
                  {/* <MdHorizontalRule className="black-outline" /> */}
                  <MdStars className="estar-icon-map" />
                  <span> - Energy Score &gt;= 75 </span>
                </div>
              </IonCol>
            </IonRow>
          </div>
        </div>
        {filteredBuildings.map((building: any) => {
          const Addr = `${building.building_address}, ${building.building_city}, ${building.building_state} ${building.building_zipcode}`;
          return (
            <GoogleMapMarker
              building={building}
              Address={Addr}
              key={building.uuid}
            />
          );
        })}
      </Map>
    </APIProvider>
  );
};

export default GoogleCustomersMap;
