import {
  IonButton,
  IonCol,
  IonIcon,
  IonItem,
  IonRow,
} from "@ionic/react";
import { arrowForwardCircle } from "ionicons/icons";
import React, { useState } from "react";
import { API_URL } from "../../actions/settings";
import NotificationPopUp from "../extras/NotificationPopUp";
import { UnexpectedError } from "../../pages/Errors/Errors";
import AE2Input from "../elements/AE2Input";

interface Props {
  setTab: any;
  setOTPEmail: any;
}

async function requestOTP(email: any) {
  return fetch(`${API_URL}/request_email_otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(email),
  })
    .then((res) => {
      if (res.status === 200) {
        return "ok";
      } else if (res.status === 404) {
        return res.json();
      } else {
        throw new UnexpectedError();
      }
    })
    .catch((error) => {
      NotificationPopUp(
        "error",
        error.message,
        "Could Not Reset Password",
        1200
      );
      return null;
    });
}

const EmailUsernameForm: React.FC<Props> = ({ setOTPEmail, setTab }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    setError("");
    const res = await requestOTP({ email: email });
    if (res === "ok") {
      setOTPEmail(email);
      setEmail("");
      setTab("OTP");
      NotificationPopUp("success", "OTP sent to your email");
    } else {
      setError(res.error || res);
    }
  };

  return (
    <>
      <IonRow className="ion-justify-content-center">
        <IonCol>
          <h3> Enter your account username\email.</h3>
          <h5 className="error" hidden={!error}>
            {error}
          </h5>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonItem style={{ width: "100%" }}>
          <AE2Input
            type="email"
            label="EMAIL"
            onIonInput={(e: any) => setEmail(e.detail.value!)}
          />
          <IonButton onClick={() => handleSubmit()}>
            <IonIcon icon={arrowForwardCircle} />
          </IonButton>
        </IonItem>
        <IonRow
          style={{ width: "100%" }}
          className="ion-justify-content-center"
        >
          <a style={{ color: "white" }} href="/login">
            Login
          </a>
        </IonRow>
      </IonRow>
    </>
  );
};

export default EmailUsernameForm;
