import React, { useEffect, useState } from "react";
import { IonButton, IonRow, IonCol, IonGrid } from "@ionic/react";
import EnergyStarTasks from "./EnergyStarTasks";
import { API_URL } from "../../actions/settings";
import { Building } from "../../interfaces/Building";
import EnergyStarDataRequestForm from "./EnergyStarDataRequestForm";
import ConsumptionDataUploadTemplate from "../../pages/Energystar/ConsumptionDataUploadTemplate";
import DegreeDaysLookup from "./DegreeDaysLookup";

// interface Props {}

async function getBuildings(token: any) {
  return fetch(`${API_URL}/energystar/customers/buildings`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

const EnergyStarData: React.FC = () => {
  const [tab, setTab] = useState("data");
  const [buildings, setBuildings] = useState<Building[] | null>(null);

  useEffect(() => {
    const handleBuildings = async () => {
      const tkn: any = localStorage.getItem("token");
      const token = `Token ${tkn.slice(1, -1)}`;
      const res = await getBuildings(token);
      setBuildings(res.data);
    };
    handleBuildings();
    let params = new URLSearchParams(window.location.search);
    const tab = params.get("t");
    if (tab) setTab(tab);
  }, []);

  return (
    <IonGrid style={{ height: "100%" }}>
      <IonRow style={{ height: "100%" }}>
        <IonCol size="12" sizeMd="1.5">
          <div>
            <IonButton
              color="tertiary"
              expand="block"
              onClick={() => setTab("data")}
            >
              {" "}
              Energy Star Data{" "}
            </IonButton>
          </div>
          <div>
            <IonButton
              color="tertiary"
              expand="block"
              onClick={() => setTab("calculations")}
            >
              {" "}
              AE2 Calculations{" "}
            </IonButton>
          </div>
          <div>
            <IonButton
              color="tertiary"
              expand="block"
              onClick={() => setTab("degree_days_lookup")}
            >
              {" "}
              Lookup Degree Days{" "}
            </IonButton>
          </div>
          <div>
            <IonButton
              color="tertiary"
              expand="block"
              onClick={() => setTab("bills")}
            >
              {" "}
              Upload Bills{" "}
            </IonButton>
          </div>
          <div>
            <IonButton
              color="tertiary"
              expand="block"
              onClick={() => setTab("results")}
            >
              {" "}
              Results{" "}
            </IonButton>
          </div>
        </IonCol>
        <IonCol size="12" sizeMd="10.5">
          {tab === "data" || tab === "calculations" ? (
            <div>
              <EnergyStarDataRequestForm tab={tab} buildings={buildings} />
            </div>
          ) : null}
          {tab === "bills" ? <ConsumptionDataUploadTemplate /> : null}
          {tab === "degree_days_lookup" ? <DegreeDaysLookup /> : null}

          {tab === "results" ? (
            <div>
              <EnergyStarTasks />
            </div>
          ) : null}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default EnergyStarData;
