import { IonCol, IonIcon, IonItem, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { star, starOutline } from "ionicons/icons"
import { useEffect, useState } from "react"
import AE2Input from "../../elements/AE2Input"

interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
totalGrossFloorArea : "",
totalGrossFloorArea_units : "",
totalGrossFloorArea_date : "",
plantDesignFlowRate : "",
plantDesignFlowRate_units : "",
plantDesignFlowRate_date : "",
averageInfluentBiologicalOxygenDemand : "",
averageInfluentBiologicalOxygenDemand_date : "",
averageEffluentBiologicalOxygenDemand : "",
averageEffluentBiologicalOxygenDemand_date : "",
fixedFilmTrickleFiltrationProcess : "",
fixedFilmTrickleFiltrationProcess_date : "",
nutrientRemoval : "",
nutrientRemoval_date : "",

}
const startErrors = {
totalGrossFloorArea : "GFA cannot be empty",
totalGrossFloorArea_units : "Units cannot be empty",
totalGrossFloorArea_date : "Date cannot be empty",
plantDesignFlowRate : "",
plantDesignFlowRate_units : "",
plantDesignFlowRate_date : "",
averageInfluentBiologicalOxygenDemand : "",
averageInfluentBiologicalOxygenDemand_date : "",
averageEffluentBiologicalOxygenDemand : "",
averageEffluentBiologicalOxygenDemand_date : "",
fixedFilmTrickleFiltrationProcess : "",
fixedFilmTrickleFiltrationProcess_date : "",
nutrientRemoval : "",
nutrientRemoval_date : "",
}

const WasteWaterTreatment: React.FC<Props> = ({setParentForm,formErrors}) => {

    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])

    const validate = (name:string, value:any) => {
        switch(name){
            case ('totalGrossFloorArea'):
                if (value === ''){
                    handleError('totalGrossFloorArea','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('totalGrossFloorArea','GFA must be a positive number')
                }
                else {
                    handleError('totalGrossFloorArea','')
                }
                break;
            case ('totalGrossFloorArea_units'):
                if (value === ''){
                    handleError('totalGrossFloorArea_units','Select gfa units')
                }
                else {
                    handleError('totalGrossFloorArea_units','')
                }
                break;
            case ('totalGrossFloorArea_date'):
                if (value === ''){
                    handleError('totalGrossFloorArea_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('totalGrossFloorArea_date','Invalid Date')
                }
                else {
                    handleError('totalGrossFloorArea_date','')
                }
                break;
            case('averageEffluentBiologicalOxygenDemand'):
                if(value !==''){
                    form.averageEffluentBiologicalOxygenDemand_date === '' ? handleError('averageEffluentBiologicalOxygenDemand_date','Date cannot be empty') : handleError('averageEffluentBiologicalOxygenDemand_date','')
                    value < 0 ? handleError('averageEffluentBiologicalOxygenDemand','Must be a Positive number') : handleError('averageEffluentBiologicalOxygenDemand','')
                }
                else{
                    handleError('averageEffluentBiologicalOxygenDemand','')
                    handleError('averageEffluentBiologicalOxygenDemand_date','')
                }
                break;
            case('averageEffluentBiologicalOxygenDemand_date'):
                if(value !==''){
                    form.averageEffluentBiologicalOxygenDemand === '' ? handleError('averageEffluentBiologicalOxygenDemand','Field cannot be empty') : handleError('averageEffluentBiologicalOxygenDemand','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('averageEffluentBiologicalOxygenDemand_date','Invalid Date') : handleError('averageEffluentBiologicalOxygenDemand_date','')
                }
                else{
                    handleError('averageEffluentBiologicalOxygenDemand','')
                    handleError('averageEffluentBiologicalOxygenDemand_date','')
                }
                break;
            case('averageInfluentBiologicalOxygenDemand'):
                if(value !==''){
                    form.averageInfluentBiologicalOxygenDemand_date === '' ? handleError('averageInfluentBiologicalOxygenDemand_date','Date cannot be empty') : handleError('averageInfluentBiologicalOxygenDemand_date','')
                    value < 0 ? handleError('averageInfluentBiologicalOxygenDemand','Must be a Positive number') : handleError('averageInfluentBiologicalOxygenDemand','')
                }
                else{
                    handleError('averageInfluentBiologicalOxygenDemand','')
                    handleError('averageInfluentBiologicalOxygenDemand_date','')
                }
                break;
            case('averageInfluentBiologicalOxygenDemand_date'):
                if(value !==''){
                    form.averageInfluentBiologicalOxygenDemand === '' ? handleError('averageInfluentBiologicalOxygenDemand','Field cannot be empty') : handleError('averageInfluentBiologicalOxygenDemand','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('averageInfluentBiologicalOxygenDemand_date','Invalid Date') : handleError('averageInfluentBiologicalOxygenDemand_date','')
                }
                else{
                    handleError('averageInfluentBiologicalOxygenDemand','')
                    handleError('averageInfluentBiologicalOxygenDemand_date','')
                }
                break;
            case ('nutrientRemoval'):
                if( value !== '' && form.nutrientRemoval_date === ''){
                    handleError('nutrientRemoval_date','Date cannot be empty')
                }
                else if( value === '' && form.nutrientRemoval_date === ''){
                    handleError('nutrientRemoval_date','')
                }
                else {
                    handleError('nutrientRemoval','')
                }
                break;
            case ('nutrientRemoval_date'):
                if (form.nutrientRemoval !== ''){
                    if (value === ''){
                        handleError('nutrientRemoval_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('nutrientRemoval_date','Invalid Date')
                    }
                else {
                        handleError('nutrientRemoval_date','')
                    }
                break;
            case ('fixedFilmTrickleFiltrationProcess'):
                if( value !== '' && form.fixedFilmTrickleFiltrationProcess_date === ''){
                    handleError('fixedFilmTrickleFiltrationProcess_date','Date cannot be empty')
                }
                else if( value === '' && form.fixedFilmTrickleFiltrationProcess_date === ''){
                    handleError('fixedFilmTrickleFiltrationProcess_date','')
                }
                else {
                    handleError('fixedFilmTrickleFiltrationProcess','')
                }
                break;
            case ('fixedFilmTrickleFiltrationProcess_date'):
                if (form.fixedFilmTrickleFiltrationProcess !== ''){
                    if (value === ''){
                        handleError('fixedFilmTrickleFiltrationProcess_date','Date cannot be empty')
                    }
                }
                if (new Date(value).getTime() > getCurrentDate()){
                        handleError('fixedFilmTrickleFiltrationProcess_date','Invalid Date')
                    }
                else {
                        handleError('fixedFilmTrickleFiltrationProcess_date','')
                    }
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <h1>Waste Water Treatment Plant Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.totalGrossFloorArea}  value={form.totalGrossFloorArea} placeholder='GFA' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea'))}>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Total Gross Floor Area:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.totalGrossFloorArea_units === '' ? "valid":'invalid'} value={form.totalGrossFloorArea_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.totalGrossFloorArea_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.totalGrossFloorArea_date} value={form.totalGrossFloorArea_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'totalGrossFloorArea_date'))}>
                        </AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.plantDesignFlowRate}  value={form.plantDesignFlowRate} placeholder='Plant Design Flow Rate' onIonInput={e => (handleFormChange(e.detail.value!,'plantDesignFlowRate'))}>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Plant Design Flow Rate:</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.plantDesignFlowRate_units === '' ? "valid":'invalid'} value={form.plantDesignFlowRate_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'plantDesignFlowRate_units'))}>
                            <IonSelectOption>Million Gallons per Day</IonSelectOption>
                            <IonSelectOption>Cubic Meters per Day</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.plantDesignFlowRate_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of" type="date" errorText={errors.plantDesignFlowRate_date} value={form.plantDesignFlowRate_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'plantDesignFlowRate_date'))}>
                        </AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input labelPlacement="stacked" type="number" errorText={errors.averageInfluentBiologicalOxygenDemand}  value={form.averageInfluentBiologicalOxygenDemand} placeholder='Average Influent Biological Oxygen Demand' onIonInput={e => (handleFormChange(e.detail.value!,'averageInfluentBiologicalOxygenDemand'))}>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Average Influent Biological Oxygen Demand</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.averageInfluentBiologicalOxygenDemand_date}  value={form.averageInfluentBiologicalOxygenDemand_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'averageInfluentBiologicalOxygenDemand_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input type="number" errorText={errors.averageEffluentBiologicalOxygenDemand}  value={form.averageEffluentBiologicalOxygenDemand} placeholder='Average Effluent Biological Oxygen Demand' onIonInput={e => (handleFormChange(e.detail.value!,'averageEffluentBiologicalOxygenDemand'))}>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Average Effluent Biological Oxygen Demand</div>
                        </AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.averageEffluentBiologicalOxygenDemand_date}  value={form.averageEffluentBiologicalOxygenDemand_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'averageEffluentBiologicalOxygenDemand_date'))}>
                        </AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect labelPlacement="stacked" className={errors.fixedFilmTrickleFiltrationProcess === '' ? "valid":'invalid'}  value={form.fixedFilmTrickleFiltrationProcess} interface='popover' placeholder='Fixed Film Trickle Filtration Process?' onIonChange={e => (handleFormChange(e.detail.value!,'fixedFilmTrickleFiltrationProcess'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Fixed Film Trickle Filtration Process</div>
                        </IonSelect>
                        <div className="error-detail">{errors.fixedFilmTrickleFiltrationProcess}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.fixedFilmTrickleFiltrationProcess_date}  value={form.fixedFilmTrickleFiltrationProcess_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'fixedFilmTrickleFiltrationProcess_date'))}>
                        </AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect labelPlacement="stacked" className={errors.nutrientRemoval === '' ? "valid":'invalid'}  value={form.nutrientRemoval} interface='popover' placeholder='Nutrient Removal System' onIonChange={e => (handleFormChange(e.detail.value!,'nutrientRemoval'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                            <div slot="label"><IonIcon slot="start" ios={starOutline} md={star} /> Nutrient Removal System?</div>
                        </IonSelect>
                        <div className="error-detail">{errors.nutrientRemoval}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4" >
                    <IonItem lines="none">
                        <AE2Input label="Current as of:"  type="date" errorText={errors.nutrientRemoval_date}  value={form.nutrientRemoval_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'nutrientRemoval_date'))}>
                        </AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonItem><IonIcon slot="start" color="white" ios={starOutline} md={star} />Required for Energy Star Score</IonItem>
        </div>
    )
}

export default WasteWaterTreatment