import { IonButton, IonCol, IonLoading, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { API_URL } from "../../../actions/settings";
import { displayValue } from "../../extras/Calculations";
import './AE2Software.css'
import { Redirect } from "react-router";
import CheckRole from "../../extras/CheckRole";
import { useAppSelector } from "../../../Hooks";
import { selectUser } from "../../../reducers/UserReducer";

async function getDocs(token:any,id:any) {

  return fetch(`${API_URL}/ae2_software_docs/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }

const AE2SoftwareDocsList: React.FC = () => {
    const [loaded, setLoaded] = useState(false)
    const [docs, setDocs] = useState<any>([])
    let params =  new URLSearchParams(window.location.search);
    const id = params.get('software_id') || ''
    const user = useAppSelector(selectUser)

    useEffect(()=>{
      const handleDocs = async () =>{
        const token = localStorage.getItem('token') || ""
        const res = await getDocs(`Token ${token.slice(1,-1)}`, id)
        setDocs(res.data)
        setLoaded(true)
      }

      handleDocs()
    },[id])

      // role 4 - Customer
    if(CheckRole(user,[4])){
      return <Redirect to="/403"/>
  }


    return (

      <>{ !loaded ?
        <IonLoading
          cssClass='my-custom-class'
          isOpen={!loaded}
          message={'Loading...'}
          spinner='bubbles'
          duration={3000}
          />
        :
        docs.length === 0 ?
        <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <IonRow className="ion-justify-content-center">
              <h1 className="main-heading">No Documentation Available For This Software</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton href={`/account?tab=software_docs&software_id=${id}&CE=create`} >Add Documentation</IonButton>
          </IonRow>
        </IonCol>
      </IonRow>
        :
        <IonRow className="ion-justify-content-center">
          <IonCol size="8">
            <IonRow className="ion-justify-content-center">
              <h1 className="main-heading" style={{textAlign:'center'}}>{docs[0].software.name} Documentation</h1>
            </IonRow>
            <IonRow>
              <ul className="docs-list">
                {docs.map((doc:any)=>{
                  return(
                    <li key={doc.id}><a href={`/account?tab=software_docs&software_id=${doc.software.id}&doc_id=${doc.id}`}>{doc.title} --- {displayValue(doc.date,'date')}</a></li>
                  )
                })}
              </ul>
            </IonRow>
          </IonCol>
        </IonRow>
      }</>
    );
  };

  export default AE2SoftwareDocsList;
