import { IonButton, IonButtons, IonCol, IonContent, IonHeader, IonLoading, IonModal, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { useEffect, useState } from "react";
import { API_URL } from "../../../actions/settings";
import { useAppSelector } from "../../../Hooks";
import { selectUser } from "../../../reducers/UserReducer";
import AE2SoftwareDownload from "../../extras/AE2SoftwareDownload";
import CheckRole from "../../extras/CheckRole";
import './AE2Software.css'
import EditReleaseNotesModal from "./EditReleaseNotesModal";
import UploadSoftwareModal from "./UploadSoftwareModal";
import { Redirect } from "react-router";

async function getSoftware(token:any) {

  return fetch(`${API_URL}/ae2_software`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }

const AE2Software: React.FC = () => {
    const user = useAppSelector(selectUser)
    const [software,setSoftware] = useState([])
    const [releaseNotes, setReleaseNotes]=useState('')
    const [modalOpened, setModalOpened]=useState(false)
    const [editModalOpened, setEditModalOpened]=useState(false)
    const [selectedRelease, setSelectedRelease]=useState(false)
    const [uploadModal, setUploadModal]=useState(false)
    const [loaded, setLoaded] = useState(false)

    const handleSoftware = async () => {
      const token = localStorage.getItem('token') || ""
      const res = await getSoftware(`Token ${token.slice(1,-1)}`)
      setSoftware(res.data)
      setLoaded(true)
    }

    useEffect(() => {
      const handleSoftware = async () => {
        const token = localStorage.getItem('token') || ""
        const res = await getSoftware(`Token ${token.slice(1,-1)}`)
        setSoftware(res.data)
        setLoaded(true)
      }
        handleSoftware()
    },[])

    const handleReleaseNotes = (release:any) =>{
        setModalOpened(true)
        setSelectedRelease(release)
        setReleaseNotes(release.notes)
    }
    const handleURL = async (url:any,filename:any) =>{
      await AE2SoftwareDownload(url,filename)

    }


    // role 4 - Customer
    if(CheckRole(user,[4])){
      return <Redirect to="/403"/>
  }


    return (
      <>{ !loaded ?
        <IonLoading
          cssClass='my-custom-class'
          isOpen={!loaded}
          message={'Loading...'}
          spinner='bubbles'
          />
        :
        <IonRow className="ion-justify-content-center">
        <IonCol size="12">
            <IonRow className="ion-justify-content-center">
                <h1 className="main-heading">AE2 Software</h1>
            </IonRow>

        </IonCol>

        <IonCol size="12" sizeMd="8" >
          <IonRow hidden={!CheckRole(user,['Admin'])} className="ion-justify-content-end">
              <IonButton color="medium" onClick={()=>setUploadModal(true)} >Upload Software</IonButton>
          </IonRow>
        </IonCol>
        <IonCol size="12" sizeMd="8">
        <IonRow className="software-list ion-justify-content-center">

        {software.map((item:any) => {
            return(
                <IonCol sizeMd="8" key={item.id}>
                    <h2>{item.name}:</h2>
                    <ul className="software-info">
                        <li><a href={`/account?tab=software_docs&software_id=${item.id}`}>View Latest Documentation</a></li>
                        <li className="download-link" onClick={()=>handleURL(item.path,item.release.name)}>{item.release.name}</li>
                        <li>Date: {item.release.date}</li>
                        <li className="notes-link" onClick={() => handleReleaseNotes(item.release)}>Latest Release Notes</li>
                        <li><a href={`/account?tab=software&software_id=${item.id}`}>View All Releases</a></li>
                    </ul>
                </IonCol>
            )
        })}
        </IonRow>
        </IonCol>

        <IonModal className="release-notes-modal" isOpen={modalOpened} onDidDismiss={()=>setModalOpened(false)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Release Notes</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => {setModalOpened(false);setEditModalOpened(true)}}>Edit</IonButton>
                <IonButton onClick={() => setModalOpened(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
          <div dangerouslySetInnerHTML={{ __html: releaseNotes }} />
          </IonContent>
        </IonModal>
        <EditReleaseNotesModal setOpened={setEditModalOpened} release={selectedRelease} opened={editModalOpened} updateData={handleSoftware}/>
        <UploadSoftwareModal opened={uploadModal} setOpened={setUploadModal} updateData={handleSoftware}/>

        </IonRow>
      }</>
    );
  };

  export default AE2Software;
