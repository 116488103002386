import { IonCol, IonButton, IonItem,IonIcon, IonItemGroup } from "@ionic/react";
import useToggle from "../../../utils/useToggle"
import { pencilSharp, trashBin } from "ionicons/icons";

interface Props{
    unit:any,
    setToEdit:any,
    setShowCategoryModal:any,
    setShowUnitsModal:any,
    handleDelete:any

}

const CategoryItem:React.FC<Props> = ({unit,setToEdit,setShowCategoryModal,setShowUnitsModal,handleDelete}) =>{
    const [opened,toggleOpened] = useToggle()

    return (
        <IonItemGroup className="category-item" onClick={()=>toggleOpened()} >
                <IonItem className="category-item">
                {unit.name}
                    <div slot="end">
                        <IonButton color="medium" slot="end" onClick={() => {setToEdit(unit); setShowCategoryModal(true)}}><IonIcon src={pencilSharp}></IonIcon></IonButton>
                        <IonButton color="danger" slot="end" onClick={() => handleDelete(unit.id)}><IonIcon src={trashBin}></IonIcon></IonButton>
                    </div>
                </IonItem>
                <IonItem style={{paddingLeft:'1em'}}  hidden={!opened}>
                    <IonCol>
                    {unit.units.map((u:any)=>{
                        return <li key={'unit'+u.id+unit.id}> <IonItem >
                            {u.name}
                            <div slot="end">
                                <IonButton color="medium" slot="end" onClick={() => {setToEdit(u); setShowUnitsModal(true)}}><IonIcon src={pencilSharp}></IonIcon></IonButton>
                                <IonButton color="danger" slot="end" onClick={() => handleDelete(u.id,unit,true)} ><IonIcon src={trashBin}></IonIcon></IonButton>
                            </div>
                        </IonItem></li>
                    })}
                    </IonCol>
                </IonItem>
        </IonItemGroup>
    )
}

export default CategoryItem