import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonList, IonModal, IonRow } from "@ionic/react"
import { useEffect, useState } from "react"
import { API_URL } from "../../../actions/settings"
import './SuccessStories.css'
import { trashBin} from "ionicons/icons"
import NotificationPopUp from "../../extras/NotificationPopUp"
import SuccessKeywordForm from "./forms/SuccessKeywordForm"
import SuccessStoryForm from "./forms/SuccessStoryForm"
import SuccessStoriesTable from "./SuccessStoriesTable"
import { useAppSelector } from "../../../Hooks"
import { selectUser, selectUserGroups } from "../../../reducers/UserReducer"
import CheckRole from "../../extras/CheckRole"
import { Redirect } from "react-router"

async function getKeywords(token:any) {

  return fetch(`${API_URL}/success_keyword`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }

async function getStories(token:any) {

  return fetch(`${API_URL}/success_story`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }
async function deleteKeyword(id:any,token:any) {

  return fetch(`${API_URL}/success_keyword/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }

async function deleteStory(id:any,token:any) {

  return fetch(`${API_URL}/success_story/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }



const SuccessStories: React.FC = () => {

    const user = useAppSelector(selectUser)
    const user_groups = useAppSelector(selectUserGroups)
    const [keywords,setKeywords] = useState([])
    const [stories,setStories] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showStoryModal, setShowStoryModal] = useState(false)
    const [opened,setOpened] = useState(false)
    const [toDeleteID,setToDeleteID] = useState<any>()
    const [deleteType, setDeleteType] = useState('')
    const [techModalOpened,setTechModalOpened] = useState(false)

    useEffect(() => {
        const token = localStorage.getItem('token')
        handleKeywords(token)
        handleStories(token)

    },[])

    const handleKeywords = async (token:string|null) => {
        const areas = await getKeywords(`Token ${token!.slice(1,-1)}`)
        setKeywords(areas.data)
    }

    const handleStories = async (token:string|null) => {
        const stories = await getStories(`Token ${token!.slice(1,-1)}`)
        setStories(stories.data)
    }

    const handleDelete = async (id:any) =>{
        setOpened(true)
        setToDeleteID(id)
    }

    const confirmTechDelete = async () =>{
        const token = localStorage.getItem('token')
        const res = await deleteKeyword(toDeleteID,`Token ${token!.slice(1,-1)}`)


        if (res.status === "success"){
            const filtered = keywords.filter((tech:any) => tech.id !== toDeleteID)
            setKeywords(filtered)

            setToDeleteID('')
            setOpened(false)
            NotificationPopUp('Success','Technology Deleted')
        }

        else{
            setToDeleteID('')
            setOpened(false)
            NotificationPopUp('error',res.detail||res.ERROR,res.error)
        }
    }
    const confirmStoryDelete = async () =>{
        const token = localStorage.getItem('token')
        const res = await deleteStory(toDeleteID,`Token ${token!.slice(1,-1)}`)


        if (res.status === "success"){
            const filtered = stories.filter((story:any) => story.id !== toDeleteID)
            setStories(filtered)

            setToDeleteID('')
            setOpened(false)
            NotificationPopUp('Success','Story Deleted')
        }

        else{
            setToDeleteID('')
            setOpened(false)
            NotificationPopUp('error',res.detail||res.ERROR,res.error)
        }
    }

    // role 4 - Customer
    if(CheckRole(user,[4])){
        return <Redirect to="/403"/>
    }

    return (
        <IonGrid>
            <SuccessKeywordForm opened={showModal} setShowModal={setShowModal} setData={setKeywords}/>
            <SuccessStoryForm opened={showStoryModal} setShowModal={setShowStoryModal} setData={(setStories)}/>
            <IonModal isOpen={techModalOpened} onDidDismiss={()=>setTechModalOpened(false)} className="techModal">
            <IonRow className="ion-justify-content-center utility-settings">
            <IonCol >
                <IonRow style={{justifyContent:'space-between'}} >
                    <h2  className="admin-utility">Keywords</h2>
                    <div><IonButton  size='small' color='danger' onClick={()=>setTechModalOpened(false)}>X</IonButton></div>
                </IonRow>
                <IonList>
                    {keywords.map((keyword:any)=>{
                        return(
                            <IonItem key={keyword.id}>{keyword.keyword} {keyword.abbreviation ? `(${keyword.abbreviation})` : null} <IonButton color="danger" slot="end" onClick={() => {handleDelete(keyword.id);setDeleteType('tech')}}><IonIcon src={trashBin}></IonIcon></IonButton></IonItem>
                        )
                    })}
                </IonList>
                <IonButton expand="full" color="primary" id="addTechnology" onClick={()=>setShowModal(true)}>Add New Keyword</IonButton>
            </IonCol>
        </IonRow>
        </IonModal>
        <div>
            <IonRow style={{justifyContent:'space-between'}}>
                <h2>Success Stories</h2>
                <div hidden={!user_groups.includes('SuccessStoriesEditDelete')}>
                <IonRow>
                <IonButton size='small' expand="block" color="primary" id="addStory" onClick={()=>setShowStoryModal(true)}>New Story</IonButton>
                <IonButton size='small' expand="block" color="primary" id="addStory" onClick={()=>setTechModalOpened(true)}>Keywords</IonButton>
                </IonRow>
                </div>
            </IonRow>
            <IonRow style={{justifyContent:"center", overflowX:'auto'}}>
                <SuccessStoriesTable stories={stories} setStories={setStories}/>
            </IonRow>
        </div>
        <IonModal isOpen={opened} onDidDismiss={()=>setOpened(false)} className="confirmDelete">
                <IonGrid>
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="delete-warning">
                                Are You Sure You Want To Delete This?
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonButton color="danger" onClick={()=> {if(deleteType==='tech'){confirmTechDelete()}if(deleteType==='story'){confirmStoryDelete()}}} >Delete</IonButton>
                            <IonButton color="medium" onClick={ ()=> setOpened(false)} >Cancel</IonButton>
                        </IonRow>
                </IonGrid>
            </IonModal>
        </IonGrid>
    )
}

export default SuccessStories