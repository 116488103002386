import { IonButton, IonItem, IonLabel } from "@ionic/react";
import { useState } from "react";
import { API_URL } from "../../actions/settings";

interface Props {
  progress: any;
  setModal: any;
  setResultsOpened: any;
}

async function uploadCSV(token: any, file: any) {
  return fetch(`${API_URL}/energystar/consumption_data`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: token,
    },
    body: file,
  }).then((data) => data.json());
}

const BulkUploadConsumption: React.FC<Props> = ({
  progress,
  setModal,
  setResultsOpened,
}) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState<any>(false);
  const [uploading, setUploading] = useState(false);

  const handleSelectFile = (e: any) => {
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      // const fileExtension = inputFile?.type.split("/")[1];
      // if (fileExtension !== 'csv'){
      //     setError('Select a CSV File.')
      //     return;
      // }
      setFile(inputFile);
    }
  };

  const handleSubmit = async () => {
    if (!file) {
      setError("No File Selected");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);

    const token = localStorage.getItem("token") || "";
    const res = await uploadCSV(`Token ${token.slice(1, -1)}`, formData);
    progress(res.task);
    setUploading(false);
    setResultsOpened(true);
    setError(false);
    setModal(false);
  };

  return (
    <div style={{ height: "200px" }}>
      <IonItem style={{ textAlign: "center", padding: "1em" }}>
        <span>
          {" "}
          Use the downloadable template to upload new bills to existing meters.
          Double Check all data before uploading it. <br />{" "}
          <b> There is no un-do function! </b>
        </span>
      </IonItem>
      {/* <div style={{color:'white'}}>
                    <a style={{color:'white'}} href='https://ae2-staging-files.s3.us-east-2.amazonaws.com/static/files/Uploading+Consumption+Data+to+AE2.pdf'
                    download="Uploading Consumption Data to AE2.pdf" target="_blank" rel="noreferrer">VIEW DOCUMENTATION</a>
                </div> */}
      {/* <div className="center-all">
                <IonButton color='secondary' style={{paddingBottom:'10em'}} href='/energystar/consumption_data_template'>Create Template</IonButton>
                </div> */}
      <form className="center-all" style={{ width: "100%" }}>
        <IonItem style={{ "--background": "transparent", color: "white" }}>
          <IonLabel>File:</IonLabel>
          <input
            type="file"
            name="csvFile"
            accept=".xlsx"
            onChange={(e: any) => handleSelectFile(e)}
          ></input>
        </IonItem>
      </form>
      {error ? (
        <IonItem className="invalid">
          <h5>{error}</h5>
        </IonItem>
      ) : (
        ""
      )}
      <div style={{ width: "100%", textAlign: "center" }}>
        <IonButton
          disabled={!file}
          onClick={() => {
            handleSubmit();
            setUploading(true);
          }}
        >
          {uploading ? "Uploading" : "Upload"}
        </IonButton>
      </div>
    </div>
  );
};

export default BulkUploadConsumption;
