import { IonButton, IonCol, IonModal, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import {
  setTaskState,
  selectTask,
  initialState,
} from "../../reducers/TaskReducer";
import { API_URL } from "../../actions/settings";
import NotificationPopUp from "../extras/NotificationPopUp";
import ProgressBar from "../extras/ProgrssBar";
import ConsumptionResult from "./results/ConsumptionResult";
import CSVConsumptionResult from "./results/CSVConsumptionResult";
import MonthlyUtilityResults from "./results/MonthlyUtilityResults";
import YearlyUtilityResult from "./results/YearlyUtilityResult";
import ConsumptionDataTemplateErrors from "./results/ConsumptionDataTemplateErrors";
import CorrelationsResults from "./results/CorrelationsResults";
import CostAvoidanceResults from "./results/CostAvoidanceResults";
import CertificationResults from "./results/CertificationResults";
import BaselineandTargetResults from "./results/BaselineandTargetResults";

async function taskProgress(token: any, id: string) {
  return fetch(`${API_URL}/task_status/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

interface Props {
  task_id: any;
  opened: boolean;
  setOpened: any;
  requestType: string;
  reset: boolean;
}

const EnergyStarTaskProgressModal: React.FC<Props> = ({
  task_id,
  opened,
  setOpened,
  requestType,
  reset,
}) => {
  const task = useAppSelector(selectTask);
  const dispatch = useAppDispatch();
  const [results, setResults] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkTaskProgress(task_id);
  }, [task_id]); // eslint-disable-line
  // useEffect(() => {
  //   if (reset) {
  //     setResults([]);
  //   }
  // }, [reset]);

  const checkTaskProgress = async (task_id: string) => {
    const tkn: any = localStorage.getItem("token");
    const token = `Token ${tkn.slice(1, -1)}`;
    const task_res = await taskProgress(token, task_id);
    console.log(task_res)
    if (task_res.state === "SUCCESS" || task_res.state === "PENDING" || task_res.state === "STARTED") {
      dispatch(
        setTaskState({
          task_id: task_id,
          status: task_res.state,
          result: {
            progress: 0,
          },
        })
      );

      const interval = setInterval(async () => {
        const task_res = await taskProgress(token, task_id);
        dispatch(
          setTaskState({
            task_id: task_id,
            status: task_res.state,
            result: task_res.details,
          })
        );

        if (task_res.state === "SUCCESS") {
          clearInterval(interval);
          setResults(task_res.details);
          setLoading(false);
          // setRefresh(!refresh)
        }
        if (task_res.state === "FAILURE") {
          if ("Template_Errors" in task_res.details) {
            setResults(task_res.details);
          } else {
            NotificationPopUp(
              "Error",
              task_res.details,
              "Could not complete request",
              10
            );
          }
          dispatch(setTaskState(initialState));
          clearInterval(interval);
          // setRefresh(!refresh)
          setLoading(false);
        }
      }, 2000);
    }
    if (task_res.state === "FAILURE") {
      if ("Template_Errors" in task_res.details) {
        setResults(task_res.details);
        // setRefresh(!refresh)
      } else {
        NotificationPopUp(
          "Error",
          task_res.details,
          "Could not complete request",
          10
        );
      }
      dispatch(setTaskState(initialState));
      setLoading(false);
    }
  };


  return (
    <IonModal
      isOpen={opened}
      onDidDismiss={() => {
        setOpened(false);
        setResults([]);
      }}
      className="modal-background"
    >
      <IonRow>
        <IonCol size="12">
          <IonRow className="ion-justify-content-between">
            <h2 className="vertical-center" style={{ paddingTop: "5px" }}>
              Results
            </h2>
            <div>
              <IonButton
                color="danger"
                fill="solid"
                onClick={() => {
                  setOpened(false);
                }}
              >
                X
              </IonButton>
            </div>
          </IonRow>

          <div className="results" style={{ overflowY: "scroll" }}>
            {task.result ? (
              <ProgressBar
                bgcolor="#06A930"
                completed={
                  task.status === "SUCCESS"
                    ? "100"
                    : "progress" in task.result
                    ? task.result.progress
                    : "0"
                }
              />
            ) : null}
            <div style={{ height: "400px" }}>
              {loading ? null : results.length >= 0 ? (
                requestType === "consumption_data" ? (
                  <ConsumptionResult data={results} />
                ) : requestType === "upload" ? (
                  <CSVConsumptionResult data={results} />
                ): requestType === "baseline_and_target" ? (
                  <BaselineandTargetResults data={results} />
                ) : requestType === "get_monthly_use" ? (
                  <MonthlyUtilityResults data={results} />
                ) : requestType === "properties_data" ? (
                  <YearlyUtilityResult data={results} />
                ) : requestType === "get_certification" ? (
                  <CertificationResults data={results} />
                ) : requestType === "degreedayscorrelations" ? (
                  <CorrelationsResults  data={results}/>
                  // <YearlyUtilityResult data={results} />
                ) : requestType === "cumulative_savings" ? (
                  <CostAvoidanceResults data={results}/>
                  // <YearlyUtilityResult data={results} />
                ) : (
                  <div>No Results</div>
                )
              ) : "Template_Errors" in results ? (
                <ConsumptionDataTemplateErrors
                  errors={results["Template_Errors"]}
                />
              ) : (
                "No Results"
              )}
            </div>
          </div>
        </IonCol>
        {/* <IonCol  size='12' sizeMd='4' style={{height:"500px"}}>
                        <EnergyStarTasks refresh={refresh}/>
                </IonCol> */}
      </IonRow>
    </IonModal>
  );
};

export default EnergyStarTaskProgressModal;
