import React, { useEffect } from 'react'
import { API_URL } from '../../../actions/settings';
import { useAppSelector } from '../../../Hooks';
import { selectUser } from '../../../reducers/UserReducer';
import QuicksightEmbed from '../../extras/QuicksightEmbed';
import { isStaff } from '../../extras/Functions';
import { Redirect } from 'react-router';
import { IonContent, IonRow } from '@ionic/react';

interface Props {
    id?:string |null,
    singleBuilding?:Boolean
  }

  async function getDashUrl(id: any, token: string) {
    return fetch(`${API_URL}/buildings/getDashUrl/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());
  }

const BillsCostAvoidanceDashboard: React.FC<Props> = ({id,singleBuilding}) => {
    const user = useAppSelector(selectUser);

    useEffect(() => {

      const handleDash = async (dashID: string,sheetID:string) => {
        const dashboard = new QuicksightEmbed();
        await dashboard.init();
        const token = localStorage.getItem("token") || "";
        const res = await getDashUrl(dashID, `Token ${token.slice(1, -1)}`);
        if(singleBuilding){
          dashboard.changeSettings({
            url: res[0],
            container: `#dashboard`,
            exportDash: false,
            undoRedo: false,
            reset: false,
            sheetID:sheetID
          });
          dashboard.setParameters({
            BuildingUuid:id,
          })
        }
        else{
          dashboard.changeSettings({
            url: res[0],
            container: `#dashboard`,
            exportDash: true,
            undoRedo: true,
            reset: true,
            sheetID:sheetID
          });
        }
        await dashboard.embedDash();
      };


      if (isStaff(user) && !singleBuilding) {
        handleDash("0b2f7990-b564-4ba9-b6d3-faee4000ed32","0b2f7990-b564-4ba9-b6d3-faee4000ed32_02bd0cd5-14cc-4129-9bbf-4adfa8eb689d");
      }
      if (singleBuilding){
        handleDash("0b2f7990-b564-4ba9-b6d3-faee4000ed32","0b2f7990-b564-4ba9-b6d3-faee4000ed32_02bd0cd5-14cc-4129-9bbf-4adfa8eb689d")
      }
    }, [id,user,singleBuilding]);

    if (user.first_name !== "" && !isStaff(user) && !singleBuilding) {
      return <Redirect to="/403" />;
    }

    return (
      <>
        <IonRow hidden={singleBuilding?true:false} className="ion-justify-content-center">
          <span className="main-heading">Cost Avoidance Dashboard</span>
        </IonRow>
        <IonContent id="dashboard"></IonContent>
      </>
    );
}

export default BillsCostAvoidanceDashboard