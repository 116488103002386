import { IonButton } from '@ionic/react';
import React from 'react'
import { useHistory } from 'react-router';


const Logout = () => {
    const history = useHistory()

    const handleLogout = () => {
      localStorage.clear();
      history.push("/login");
      window.location.reload()
    };
  return (
  <div className='logout-button'>
        <IonButton  expand='block' color='medium' onClick={handleLogout}>Logout</IonButton>
        <div>Copyright © 2019 - 2024</div>
        <div>Alpha Controls & Services, LLC All Rights Reserved.</div>
        <a href="/terms_and_conditions" target="_blank">Terms & Conditions</a> - <a href="/privacy" target="_blank"> Privacy Policy </a>
        </div>)
}

export default Logout