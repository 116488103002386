import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonModal, IonRow, IonToggle } from "@ionic/react"
import { useState } from "react"
import {createOutline, trashBin} from "ionicons/icons"
import './UtilityServiceTable.css'
import { API_URL } from "../../actions/settings"
import CheckRole from "../extras/CheckRole"
import { useAppSelector } from "../../Hooks"
import { selectUser } from "../../reducers/UserReducer"
import NotificationPopUp from "../extras/NotificationPopUp"
import { isStaff } from "../extras/Functions"

interface Params {
    utility_services:[]
}

async function deleteBuildingUtility(id:any, token:string) {
    return fetch(`${API_URL}/buildings/utility_service/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':token
        }

      })
        .then(data => data.json())
    }

const UtilityServiceTable:React.FC<Params> = ({utility_services}) =>{
    const [edit, setEdit] = useState(false)
    const [opened, setOpened] = useState(false)
    const [toDelete, setToDelete] = useState('')
    const [services, setServices] = useState(utility_services)
    const user = useAppSelector(selectUser)

    const handleDelete = (id:any) =>{
        setOpened(true)
        setToDelete(id)
    }

    const confirmDelete = async () =>{
        const token:any = localStorage.getItem('token')
        const res = await deleteBuildingUtility(toDelete,`Token ${token.slice(1,-1)}`)
        if (res.status === "success"){
            const filtered:any = services.filter((service:any) => service.id !== toDelete )
            setServices(filtered)
            setToDelete('')
            setOpened(false)
            NotificationPopUp('Success',"Utility Service Deleted")
        }

        else{
            setToDelete('')
            setOpened(false)
            NotificationPopUp('Error',res.detail||res.ERROR, res.error)
        }
    }

    if(isStaff(user) && utility_services.length === 0){
        return(
            <IonRow>
                &nbsp; &nbsp; &nbsp; &nbsp; No Utility Providers Have Been Added Yet.
            </IonRow>
        )
    }
    return(
        isStaff(user) ?
        <div className="utility-service-table">
            <IonRow className="ion-justify-content-end">
                <IonItem hidden={!CheckRole(user,['Admin','Supervisor'])} className="edit-toggle" style={{'--background':'transparent', 'float':'right'}}>
                    <IonToggle labelPlacement="start" checked={edit} color='secondary' style={{'color':'white'}} onIonChange={()=>setEdit(!edit)}>EDIT</IonToggle>
                </IonItem>
            </IonRow>
            <IonRow>
                <IonCol className="header">Name:</IonCol>
                <IonCol className="header">Provider:</IonCol>
                <IonCol className="header">Type:</IonCol>
                <IonCol className="header">Account Number:</IonCol>
                <IonCol className="header">Account Group Number:</IonCol>
                <IonCol className="header">PODID:</IonCol>
                <IonCol className="header">Meter Number:</IonCol>
                <IonCol className="header">Third Party Provider:</IonCol>
                <IonCol className="header">Third Party Account Number:</IonCol>
                <IonCol hidden={!edit} className="header">Edit / Delete</IonCol>
            </IonRow>
            {services.map((service:any)=>{
                return(
                    <IonRow key={service.id}>
                        <IonCol className="col"><span>{service?.name}</span></IonCol>
                        <IonCol className="col">
                            <span>{service.utility_provider?.name}</span>
                        </IonCol>
                        <IonCol className="col"><span>{service.utility_service_type?.name}</span></IonCol>
                        <IonCol className="col">
                        <span>{service.account_number}</span>
                        </IonCol>
                        <IonCol className="col"><span>{service.account_group_number}</span></IonCol>
                        <IonCol className="col"><span>{service.podid}</span></IonCol>
                        <IonCol className="col"><span>{service.meter_number}</span></IonCol>
                        <IonCol className="col">
                            <span>{service.third_party_provider?.name}</span>
                        </IonCol>
                        <IonCol className="col">
                        <span>{service.third_party_account_number}</span>
                        </IonCol>
                        <IonCol hidden={!edit} className="col">
                            <IonButton color="medium" size='small' href={`/building/utility_service/${service.id}/edit`} disabled={!edit} style={{'paddingRight':'5px'}}><IonIcon slot="icon-only" src={createOutline}></IonIcon></IonButton>
                            <IonButton color="danger" size='small' onClick={() => handleDelete(service.id)}><IonIcon  slot="icon-only" src={trashBin}></IonIcon></IonButton>
                        </IonCol>
                    </IonRow>
                )
            })}
            <IonModal isOpen={opened} onDidDismiss={()=>setOpened(false)} className="confirmDelete">
                <IonGrid>
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="delete-warning">
                                <span>WARNING:</span>  Deleting a Utility Service removes all associations between the building and any historical billing data.
                                The only way this can be “Undone” is by creating a new Utility Service with the same information.
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonButton color="danger" onClick={()=> confirmDelete()} >Delete</IonButton>
                            <IonButton color="medium" onClick={ ()=> setOpened(false)} >Cancel</IonButton>
                        </IonRow>
                </IonGrid>
            </IonModal>
        </div> :
        null
    )
}

export default UtilityServiceTable