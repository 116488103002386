import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
} from "@ionic/react";
import React from "react";

interface Props {
  data: [meterRow];
}

interface ConsumptionData {
  consumptionid: string;
  cost: string | null | undefined;
  demand: string | null | undefined;
  demand_cost: string | null | undefined;
  enddate: string | null | undefined;
  estimatedValue: boolean | string | null;
  in_energy_star: boolean | null;
  meterid: string;
  startdate: string | null | undefined;
  usage: string | null | undefined;
}

interface ConsumptionResultDict {
  data: ConsumptionData;
  date: string;
  status: string;
}

type ConsumptionRow = [ConsumptionResultDict];

interface meterRow {
  meter: string;
  consumption: ConsumptionRow;
}

const ConsumptionResult: React.FC<Props> = ({ data }) => {
  return (
    <>
      {data.map((meter: meterRow, idx: Number) => {
        return (
          <div key={`${meter.meter}-${idx}`}>
            <h3>{meter.meter} Results</h3>
            <IonAccordionGroup>
              {meter.consumption.map(
                (row: ConsumptionResultDict, idx: Number) => {
                  return (
                    <IonAccordion key={`${idx}`}>
                      <IonItem slot="header">
                        <IonLabel className={`${row.status.toLowerCase()}`}>
                          <span>{row.status}</span>
                          <div>Ending: {row.data.enddate}</div>
                        </IonLabel>
                      </IonItem>
                      <div slot="content" style={{ color: "black" }}>
                        <pre>{JSON.stringify(row.data, null, 2)}</pre>
                      </div>
                    </IonAccordion>
                  );
                }
              )}
            </IonAccordionGroup>
          </div>
        );
      })}
    </>
  );
};

export default ConsumptionResult;
