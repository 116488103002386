import { IonCol, IonItem, IonRow, IonSelect, IonSelectOption } from "@ionic/react"
import { useEffect, useState } from "react"
import AE2Input from "../../elements/AE2Input"


interface Props{
    setParentForm:Function
    formErrors:[errors:any,setErrors:Function]
}

const startingForm = {
supplementalHeating : "",
supplementalHeating_date : "",
openFootage : "",
openFootage_units : "",
openFootage_date : "",
completelyEnclosedFootage : "",
completelyEnclosedFootage_units : "",
completelyEnclosedFootage_date : "",
partiallyEnclosedFootage : "",
partiallyEnclosedFootage_units : "",
partiallyEnclosedFootage_date : "",
}


const startErrors = {
    supplementalHeating : "",
    supplementalHeating_date : "",
    openFootage : "",
    openFootage_units : "",
    openFootage_date : "",
    completelyEnclosedFootage : "",
    completelyEnclosedFootage_units : "",
    completelyEnclosedFootage_date : "",
    partiallyEnclosedFootage : "",
    partiallyEnclosedFootage_units : "",
    partiallyEnclosedFootage_date : "",

}

const Parking: React.FC<Props> = ({setParentForm,formErrors}) => {
    const [form, setForm] = useState(startingForm)
    const [errors, setErrors] = formErrors
    const handleFormChange = (value:any,name:string) => {
        validate(name,value)
        setForm((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        } )
        setParentForm({...form, [name]:value},'form')

    }

    const handleError = (name:string,value:any) => {
        setErrors((prevState:any) => {
            return {
                ...prevState,
                [name]:value
            }
        } )

    }

    const getCurrentDate = () => {
        return new Date().getTime();
      };


    useEffect(()=>{
        setErrors((prevState:any) => {
            return {
                ...prevState,
                ...startErrors
            }
        } )
    }, [setErrors])
    const validate = (name:string, value:any) => {
        switch(name){
            case ('openFootage'):
                if (value === ''){
                    handleError('openFootage','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('openFootage','GFA must be a positive number')
                }
                else {
                    handleError('openFootage','')
                }
                break;
            case ('openFootage_units'):
                if (value === ''){
                    handleError('openFootage_units','Select gfa units')
                }
                else {
                    handleError('openFootage_units','')
                }
                break;
            case ('openFootage_date'):
                if (value === ''){
                    handleError('openFootage_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('openFootage_date','Invalid Date')
                }
                else {
                    handleError('openFootage_date','')
                }
                break;
            case ('completelyEnclosedFootage'):
                if (value === ''){
                    handleError('completelyEnclosedFootage','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('completelyEnclosedFootage','GFA must be a positive number')
                }
                else {
                    handleError('completelyEnclosedFootage','')
                }
                break;
            case ('completelyEnclosedFootage_units'):
                if (value === ''){
                    handleError('completelyEnclosedFootage_units','Select gfa units')
                }
                else {
                    handleError('completelyEnclosedFootage_units','')
                }
                break;
            case ('completelyEnclosedFootage_date'):
                if (value === ''){
                    handleError('completelyEnclosedFootage_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('completelyEnclosedFootage_date','Invalid Date')
                }
                else {
                    handleError('completelyEnclosedFootage_date','')
                }
                break;
            case ('partiallyEnclosedFootage'):
                if (value === ''){
                    handleError('partiallyEnclosedFootage','Gross Floor Are Cannot be Empty')
                }
                else if (value < 0){
                    handleError('partiallyEnclosedFootage','GFA must be a positive number')
                }
                else {
                    handleError('partiallyEnclosedFootage','')
                }
                break;
            case ('partiallyEnclosedFootage_units'):
                if (value === ''){
                    handleError('partiallyEnclosedFootage_units','Select gfa units')
                }
                else {
                    handleError('partiallyEnclosedFootage_units','')
                }
                break;
            case ('partiallyEnclosedFootage_date'):
                if (value === ''){
                    handleError('partiallyEnclosedFootage_date','Date cannot be empty')
                }
                else if (new Date(value).getTime() > getCurrentDate()){
                    handleError('partiallyEnclosedFootage_date','Invalid Date')
                }
                else {
                    handleError('partiallyEnclosedFootage_date','')
                }
                break;
            case('supplementalHeating'):
                if(value !==''){
                    form.supplementalHeating_date === '' ? handleError('supplementalHeating_date','Date cannot be empty') : handleError('supplementalHeating_date','')

                }
                else{
                    handleError('supplementalHeating','')
                    handleError('supplementalHeating_date','')
                }
                break;
            case('supplementalHeating_date'):
                if(value !==''){
                    form.supplementalHeating === '' ? handleError('supplementalHeating','Field cannot be empty') : handleError('supplementalHeating','')
                    new Date(value).getTime() > getCurrentDate() ? handleError('supplementalHeating_date','Invalid Date') : handleError('supplementalHeating_date','')
                }
                else{
                    handleError('supplementalHeating','')
                    handleError('supplementalHeating_date','')
                }
                break;
            default:
                break;
        }
    }

    return(
        <div>
            <h1>Parking Form</h1>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Does Parking Lot have Supplemental Heating System?" labelPlacement="stacked" className={errors.supplementalHeating === '' ? "valid":'invalid'}  value={form.supplementalHeating} interface='popover' placeholder='Supplemental Heating System?' onIonChange={e => (handleFormChange(e.detail.value!,'supplementalHeating'))}>
                            <IonSelectOption>Yes</IonSelectOption>
                            <IonSelectOption>No</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.supplementalHeating}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.supplementalHeating_date}  value={form.supplementalHeating_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'supplementalHeating_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Total Area of Open Parking lots/areas" labelPlacement="stacked" type="number" errorText={errors.openFootage}  value={form.openFootage} placeholder='Are Open Parking' onIonInput={e => (handleFormChange(e.detail.value!,'openFootage'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.openFootage_units === '' ? "valid":'invalid'} value={form.openFootage_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'openFootage_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.openFootage_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.openFootage_date} value={form.openFootage_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'openFootage_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Total Area of Enclosed Parking Lots/Areas" labelPlacement="stacked" type="number" errorText={errors.completelyEnclosedFootage}  value={form.completelyEnclosedFootage} placeholder='Area Completely Enclosed Parking' onIonInput={e => (handleFormChange(e.detail.value!,'completelyEnclosedFootage'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.completelyEnclosedFootage_units === '' ? "valid":'invalid'} value={form.completelyEnclosedFootage_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'completelyEnclosedFootage_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.completelyEnclosedFootage_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.completelyEnclosedFootage_date} value={form.completelyEnclosedFootage_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'completelyEnclosedFootage_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Total Area of Partially Enclosed Parking Lots/Areas" labelPlacement="stacked" type="number" errorText={errors.partiallyEnclosedFootage}  value={form.partiallyEnclosedFootage} placeholder='Area Partially Enclosed Parking' onIonInput={e => (handleFormChange(e.detail.value!,'partiallyEnclosedFootage'))}></AE2Input>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                    <div style={{width:'100%'}}>
                        <IonSelect label="Units" className={errors.partiallyEnclosedFootage_units === '' ? "valid":'invalid'} value={form.partiallyEnclosedFootage_units} interface='popover' placeholder='Units' onIonChange={e => (handleFormChange(e.detail.value!,'partiallyEnclosedFootage_units'))}>
                            <IonSelectOption value={'Square Feet'}>Square Feet</IonSelectOption>
                            <IonSelectOption value={'Square Meters'}>Square Meters</IonSelectOption>
                        </IonSelect>
                        <div className="error-detail">{errors.partiallyEnclosedFootage_units}</div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol sizeLg="4">
                    <IonItem lines="none">
                        <AE2Input label="Current as of:" type="date" errorText={errors.partiallyEnclosedFootage_date} value={form.partiallyEnclosedFootage_date} placeholder='Date' onIonInput={e => (handleFormChange(e.detail.value!,'partiallyEnclosedFootage_date'))}></AE2Input>
                    </IonItem>
                </IonCol>
            </IonRow>

        </div>
    )
}

export default Parking